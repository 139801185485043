Ext.define('WindowReportLsWizard', {
    extend: 'Ext.mixin.Observable',
    constructor: function (config) {
        this.cid = Ext.id();
        this.config = config || {};
        Ext.apply(this, config);
        this.callParent(this, config);
        this.initDialog();
    },
    show: function () {
        this.dialog.show();
        this.dialog.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.closeOp
            }
        });
        this.initData();
        this.startpanelfn();
        this.dialog.center();
    },
    initDialog: function () {
        this.pwVName = Ext.create('PanelWizardViewNameChoose');
        this.pwKolumny = Ext.create('PanelWizardKolumnyChoose');
        this.pwFilter = Ext.create('PanelWizardFilterChoose');
        this.pwGroup = Ext.create('PanelWizardGroupChoose');
        this.pwSort = Ext.create('PanelWizardSortChoose');
        this.pwSView = Ext.create('PanelWizardViewColGroupChoose');
        this.itemsPanels = [this.pwVName, this.pwKolumny, this.pwFilter, this.pwGroup, this.pwSort, this.pwSView];
        this.itemsPanelsIndex = 0;
        this.dialog = new Ext.Window({
            closable: false,
            collapsible: false,
            plain: true,
            modal: true,
            resizable: false,
            stateId: null,
            stateful: false,
            autoWidth: false,
            autoHeight: true,
            width: 900,
            height: 550,
            layout: 'fit',
            items: [{
                xtype: 'panel',
                layout: 'hbox',
                layoutConfig: {
                    align: "stretch"
                },
                frame: false,
                border: false,
                autoWidth: false,
                autoHeight: false,
                height: 500,
                items: [{
                    xtype: 'panel',
                    flex: 1,
                    layout: 'fit',
                    height: 500,
                    frame: true,
                    border: true,
                    autoWidth: false,
                    autoHeight: false,
                    items: [{
                        width: 200,
                        xtype: 'dataview',
                        id: this.cid + "rplLsView",
                        singleSelect: true,
                        store: new Ext.data.JsonStore({
                            id: "id",
                            root: '',
                            fields: [{
                                name: 'id',
                                mapping: 'id'
                            }, {
                                name: 'nazwa',
                                mapping: 'nazwa'
                            }]
                        }),
                        tpl: '<tpl for="."><div class="x-rlist-view-kol-item">{' + "nazwa" + '}</div></tpl>',
                        scrollable: true,
                        itemSelector: ".x-rlist-view-kol-item",
                        listeners: {
                            select: this.selectPanelRplViewClick,
                            scope: this
                        }
                    }]
                }, {
                    width: 700,
                    xtype: 'panel',
                    frame: false,
                    border: true,
                    autoWidth: false,
                    autoHeight: false,
                    layout: "card",
                    scrollable: true,
                    id: this.cid + "mainpanel",
                    layoutConfig: {
                        deferredRender: true
                    },
                    activeItem: null,
                    items: this.itemsPanels
                }]
            }],
            title: Lang.raport.capitalise() + (this.repFirmaNazwa != null ? " (" + this.repFirmaNazwa + ")" : ""),
            buttons: [{
                id: this.cid + "prevpanelfn",
                text: Lang.poprzedni,
                handler: this.prevpanelfn,
                scope: this
            }, {
                id: this.cid + "nextpanelfn",
                text: Lang.nastepny,
                handler: this.nextpanelfn,
                scope: this
            }, {
                id: this.cid + "closefnSuccess",
                text: Lang.zastosuj,
                handler: this.closefnSuccess,
                scope: this
            }, {
                id: this.cid + "closefn",
                text: Lang.zamknij,
                handler: this.closefn,
                scope: this
            }]
        });
        this.mainpanel = Ext.getCmp(this.cid + "mainpanel");
    },
    closefn: function () {
        this.repDef = this.oldrepDef;
        this.repNazwa = this.oldrepNazwa;
        this.fireEvent("wclose");
        this.dialog.close();
    },
    closefnSuccess: function () {
        var msg = this.mainpanel.getLayout().activeItem.validateShow();
        try {
            this.reParseReportValidate();
        } catch (e) {
            msg = e;
        }
        if (msg == null) {
            this.fireEvent("wclosesuccess");
            this.dialog.close();
        } else {
            Ext.MessageBox.alert(Lang.error, msg);
        }
    },
    startpanelfn: function () {
        this.itemsPanelsIndex = 0;
        this.mainpanel.getLayout().setActiveItem(this.itemsPanels[this.itemsPanelsIndex].id);
        this.mainpanel.getLayout().activeItem.callAfterShow();
        Ext.getCmp(this.cid + "prevpanelfn").setDisabled(true);
        this.markelementRplview();
    },
    prevpanelfn: function () {
        if (this.itemsPanelsIndex - 1 >= 0) {
            this.mainpanel.getLayout().activeItem.validateShowLess();
            this.itemsPanelsIndex = this.itemsPanelsIndex - 1;
            this.mainpanel.getLayout().setActiveItem(this.itemsPanels[this.itemsPanelsIndex].id);
            this.mainpanel.getLayout().activeItem.callAfterShow();
            Ext.getCmp(this.cid + "prevpanelfn").setDisabled(!(this.itemsPanelsIndex - 1 >= 0));
            Ext.getCmp(this.cid + "nextpanelfn").setDisabled(false);
        } else {
            Ext.getCmp(this.cid + "nextpanelfn").setDisabled(false);
            Ext.getCmp(this.cid + "prevpanelfn").setDisabled(true);
        }
        this.markelementRplview();
    },
    nextpanelfn: function () {
        var lennn = this.itemsPanels.length;
        if ((this.itemsPanelsIndex + 1) < lennn) {
            var msg = this.mainpanel.getLayout().activeItem.validateShow();
            if (msg == null) {
                this.itemsPanelsIndex = this.itemsPanelsIndex + 1;
                this.mainpanel.getLayout().setActiveItem(this.itemsPanels[this.itemsPanelsIndex].id);
                this.mainpanel.getLayout().activeItem.callAfterShow();
                Ext.getCmp(this.cid + "nextpanelfn").setDisabled(!((this.itemsPanelsIndex + 1) < lennn));
                Ext.getCmp(this.cid + "prevpanelfn").setDisabled(false);
            } else {
                Ext.MessageBox.alert(Lang.error, msg);
            }
        } else {
            Ext.getCmp(this.cid + "nextpanelfn").setDisabled(true);
            Ext.getCmp(this.cid + "prevpanelfn").setDisabled(false);
        }
        this.markelementRplview();
    },
    markelementRplview: function () {
        var rplLsView = Ext.getCmp(this.cid + "rplLsView");
        for (var i = 0; i < rplLsView.store.getCount(); i++) {
            var r = rplLsView.store.getAt(i);
            var n = rplLsView.getNode(r);
            var en = Ext.get(n);
            var id = r.get("id");
            if (i <= this.itemsPanelsIndex) {
                en.removeCls("elemnt-locked");
            } else {
                en.addCls("elemnt-locked");
            }
        }
        rplLsView.select(this.itemsPanelsIndex);
    },
    selectPanelRplViewClick: function (c, r, i, eOptions) {
        var rplLsView = Ext.getCmp(this.cid + "rplLsView");
        var id = r.get("id");
        var itemsPanelsIndex2 = rplLsView.store.indexOf(r);
        if (itemsPanelsIndex2 < this.itemsPanelsIndex) {
            if (this.itemsPanelsIndex - 1 >= 0) {
                this.mainpanel.getLayout().activeItem.validateShowLess();
                this.itemsPanelsIndex = itemsPanelsIndex2;
                this.mainpanel.getLayout().setActiveItem(this.itemsPanels[this.itemsPanelsIndex].id);
                this.mainpanel.getLayout().activeItem.callAfterShow();
                Ext.getCmp(this.cid + "prevpanelfn").setDisabled(!(itemsPanelsIndex2 - 1 >= 0));
                Ext.getCmp(this.cid + "nextpanelfn").setDisabled(false);
            } else {
                Ext.getCmp(this.cid + "nextpanelfn").setDisabled(false);
                Ext.getCmp(this.cid + "prevpanelfn").setDisabled(true);
            }
            this.markelementRplview();
        } else {
            this.markelementRplview();
        }
    },
    initData: function () {
        this.repDef = this.repDef || {};
        this.repDef.columnsInReport = this.repDef.columnsInReport || [];
        this.repDef.columnsInReportView = this.repDef.columnsInReportView || [];
        this.repDef.columnsInReportGroup = this.repDef.columnsInReportGroup || [];
        this.repDef.columnsInReportSort = this.repDef.columnsInReportSort || [];
        this.repDef.columnsInReportSortOrder = this.repDef.columnsInReportSortOrder || [];
        if (this.repDef.groupCollapse == undefined) {
            this.repDef.groupCollapse = Const.REPORT_LIST_EXPAND_ALL;
        }
        this.repDef.typeCzas = this.repDef.typeCzas || null;
        this.repDef.fromDate = this.repDef.fromDate || null;
        this.repDef.toDate = this.repDef.toDate || null;
        this.repDef.typyTankowan = this.repDef.typyTankowan || [];
        this.repDef.czasExpand = this.repDef.czasExpand || Const.REPORT_LIST_CZAS_EXPAND_DZIENNY;
        this.repDef.filters = this.repDef.filters || {};
        this.oldrepDef = Ext.decode(Ext.encode(this.repDef));
        this.oldrepNazwa = Ext.decode(Ext.encode(this.repNazwa));
        this.pwVName.setData({
            mainCtrl: this
        });
        this.pwKolumny.setData({
            mainCtrl: this
        });
        this.pwFilter.setData({
            mainCtrl: this
        });
        this.pwGroup.setData({
            mainCtrl: this
        });
        this.pwSort.setData({
            mainCtrl: this
        });
        this.pwSView.setData({
            mainCtrl: this
        });
        var rplLsView = Ext.getCmp(this.cid + "rplLsView");
        var kolRpl = [];
        Ext.each(this.itemsPanels, function (v) {
            kolRpl.push({
                id: v.title,
                nazwa: v.title
            });
        });
        rplLsView.store.loadData(kolRpl);
    },
    getReportDef: function () {
        return this.repDef;
    },
    getReportNazwa: function () {
        return this.repNazwa;
    },
    getReportId: function () {
        return this.repId;
    },
    reParseReportValidate: function () {
        this.pwVName.reParseReportValidate();
        this.pwKolumny.reParseReportValidate();
        this.pwFilter.reParseReportValidate();
        this.pwGroup.reParseReportValidate();
        this.pwSort.reParseReportValidate();
        this.pwSView.reParseReportValidate();
    },
    getKoolumnNazwaLangMap: function () {
        return {
            pojazdTRW: Lang.pojazd,
            pojazdTRWRok: Lang.pojazd_rok_produkcji,
            pojazdTRWIE: Lang.pojazd_ident_euro,
            pojazdTRWEIID: Lang.pojazd_eiid,
            odbiorcaEIID: Lang.odbiorca_eiid,
            plynEIID: Lang.plyn_eiid,
            dostawca: Lang.dostawca,
            zdopis: Lang.opiszdarzenia,
            odbiorca: Lang.odbiorca,
            operator: Lang.operator,
            sterownik: Lang.sterownik,
            rozszerzeniester: Lang.rozszerzenie,
            zbiornik: Lang.zbiornik,
            plyn: Lang.plyn,
            firma: Lang.firma,
            typ_tankowania: Lang.typ_tankowania,
            czas: Lang.czas,
            czas2: Lang.czas,
            ile: Lang.ile,
            ilebezkompensacji: Lang.ilosc_nieskompensowana,
            licznik: Lang.licznik,
            dystans: Lang.dystans,
            czaspracy: Lang.czaspracy,
            zuzycie100: Lang.zuzycie100,
            zuzycie1h: Lang.zuzycie1h,
            motogodziny: Lang.motogodziny,
            normazuzycie100: Lang.norma_zuzycia100,
            normazuzycie1h: Lang.norma_zuzycia1h,
            grupa_pojazd: Lang.pojazd + ' (' + Lang.grupa.capitalise() + ')',
            grupa_odbiorca: Lang.odbiorca + ' (' + Lang.grupa.capitalise() + ')',
            kosztcalosc: Lang.koszt,
            kosztlitr: Lang.cena_jednostkowa,
            pojazdTRWOpis: Lang.pojazd_opis,
            odbiorcaOpis: Lang.odbiorca_opis,
            przyrost_bak: Lang.przyrost_bak,
            roznica_bak: Lang.roznica_bak,
            pomiar_przyrost_zbiornik_zaladunek: Lang.pomiar_przyrost_zbiornik_zaladunek,
            pomiar_ubytek_zbiornik_tank: Lang.pomiar_ubytek_zbiornik_tank,
            stan_zbiornika: Lang.stanzbiornika
        };
    }
});
