export default function Login() {
    window._HandleStandardExceptionAlert = Const.DEBUG;
    window._HandleStandardExceptionReloadPage = false; //!Const.DEBUG;
    window._HandleStandardExceptionAlertOnError = Const.DEBUG;
    window._HandleStandardExceptionReloadPageOnError = false; //!Const.DEBUG;
    window.WindowExdMobileDEVICEV = Ext.os.is.Phone && (Config.mobileuidisabled != 1);

    var ExtonReadyMainExecutedFirst = false;

    Ext.onReady(function () {
        if (ExtonReadyMainExecutedFirst) {
            return;
        }
        ExtonReadyMainExecutedFirst = true;
        Ext.tip.QuickTipManager.init();
        var loginpagep0 = Ext.get("loginpage-panel");
        var loginpagep = Ext.get("loginpage-panels");
        var loginpagepc = Ext.get("panel-login-container");
        let handleLoginPageResize = function () {
            var panelFormContainer = Ext.getCmp('login-panel-form-container');
            var wheight;
            var wwidth;
            if (Ext.os.is.Phone) {
                wheight = Ext.getBody().getViewSize().height;
                wwidth = Ext.getBody().getViewSize().width;
                loginpagepc.setWidth(wwidth);
                loginpagepc.setHeight(wheight);
                loginpagep0.center();
                loginpagep.center();
            } else {
                wheight = Ext.dom.Element.getViewportHeight();
                wwidth = Ext.dom.Element.getViewportWidth();
                loginpagepc.setWidth(wwidth < 900 ? 900 : wwidth - 1);
                loginpagepc.setHeight(wheight < 600 ? 600 : wheight - 1);
            }
            if (!Const.CUSTOMMODFLAG.match(/external_(.)*/)) {
                if (wheight < 768) {
                    loginpagep0.addCls("loginpage-panel2");
                } else {
                    loginpagep0.removeCls("loginpage-panel2");
                }
            }
            if (panelFormContainer)
                panelFormContainer.center();
        };
        Ext.on('resize', handleLoginPageResize);
        handleLoginPageResize();
        sendAjaxDataJsonRpcL("rest/systemreq/isSignUser", null, null, function (data) {
            if (data.status) {
                data = data.data;
                if (data) {
                    window.location = _HandleRedirectDispatcherURL;
                } else {
                    Ext.get('loadpage-loading-mask').fadeOut({
                        remove: true,
                        duration: 900,
                        scope: this,
                        callback: function () {
                            Ext.get('loginpage-panel').setVisible(true);
                            var itemsFF = [];
                            itemsFF.push({
                                id: "flogin",
                                xtype: 'textfield',
                                fieldLabel: Lang.login,
                                value: Config.defaultlogin || "",
                                inputAttrTpl: 'autocapitalize="off" autocorrect="off"'
                            });
                            itemsFF.push({
                                id: "fpass",
                                xtype: 'textfield',
                                inputType: "password",
                                fieldLabel: Lang.haslo,
                                value: Config.defaultpass || ""
                            });

                            itemsFF.push({
                                id: 'frememberme',
                                xtype: 'checkboxexd',
                                boxLabel: Lang.pamietaj_mnie
                            });
                            var fp = new Ext.form.Panel({
                                anchor: 0,
                                frame: false,
                                border: false,
                                cls: "form-login",
                                items: itemsFF,
                                buttons: [{
                                    text: Lang.ok,
                                    clickEvent: "click",
                                    cls: 'btn btn-outline-secondary',
                                    handler: submitbtn
                                }]
                            });
                            var pn = new Ext.panel.Panel({
                                frame: true,
                                id: 'login-panel-form-container',
                                cls: "panel-login",
                                bodyStyle: "padding:10px;",
                                width: 300,
                                items: [fp, {
                                    id: 'identpanel',
                                    xtype: 'loginidentpanel',
                                    frame: false,
                                    border: false,
                                    hidden: Ext.os.is.Phone && (Config.mobileuidisabled != 1)
                                }],
                                renderTo: "loginpage-panels"
                            });
                            this.identPanel = Ext.getCmp('identpanel');
                            this.identPanel.init();
                            this.identPanel.on('cardrecognized', function (data) {
                                if (data != null) {
                                    Ext.getCmp('flogin').setValue(data.login);
                                }
                                Ext.defer(function () {
                                    window.location = _HandleRedirectDispatcherURL;
                                }, 1000);
                            }, this);
                            var map = new Ext.util.KeyMap({
                                target: Ext.getBody(),
                                key: Ext.event.Event.ENTER,
                                fn: function () {
                                    submitbtn();
                                }
                            });
                            pn.center();
                        }
                    });
                }
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    });

    var submitbtn = function () {
        var isRememberMe = Ext.getCmp('frememberme').getValue();
        sendAjaxDataJsonRpcL("rest/systemreq/performSign", {
            _CancelAutoRedirect: true,
            login: Ext.getCmp("flogin").getValue(),
            pass: Ext.getCmp("fpass").getValue(),
            rememberme: isRememberMe
        }, null, function (data) {
            if (data.status && data.data != null) {
                this.identPanel.setCommunicationState("ikonIdentLogin", Lang.weryfikacja_poprawna);
                window.location = _HandleRedirectDispatcherURL;
            } else {
                Ext.MessageBox.alert(Lang.error, data.error || Lang.niepoprawna_nazwa_uzytkownika_lub_haslo);
            }
        });
    };

    function setCookie(name, value, days) {
        var expires = "";
        if (days) {
            var date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    function getCookie(name) {
        var nameEQ = name + "=";
        var ca = document.cookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }

    function eraseCookie(name) {
        document.cookie = name + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
    }

    return <></>
}