Ext.define('ViewGPSPojazdyhistory', {
    extend: 'Ext.panel.Panel',
    grid: null,
    windowObject: null,
    id: "gpspojazdyhistory",
    taskContextMenu: null,
    rowContext: null,
    rebuildGrid: false,
    showState: false,
    statics: {
        accessPropertyViewName: "access.view.gpspojazdyhistory"
    },
    cat: "p",
    text: Lang.gps_zdarzenia,
    buttonIconCls: "iconGpsZdarzenie",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.buildGrid();
        this.items = [{
            region: "north",
            xtype: 'toolbar',
            items: [{
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconRefresh",
                text: Lang.odswiez.capitalise(),
                scope: this,
                handler: this.refreshDatastore
            }, {
                xtype: "box",
                id: this.id + "kontekstfirmybox",
                cls: "kontekstfirmy-box"
            }, {
                id: this.id + "kontekstfirmy",
                xtype: 'label',
                style: 'display:block;margin-right:5px',
                cls: "kontekstfirmy-text"
            }, {
                xtype: 'tbseparator',
                hidden: (!Const.BASICFLAG && !Const.STANDARDFLAG) || Const.LICENCE.SERVER_LIMITOFF_KDPANDID
            }, {
                xtype: 'label',
                hidden: (!Const.BASICFLAG && !Const.STANDARDFLAG) || Const.LICENCE.SERVER_LIMITOFF_KDPANDID,
                id: this.id + 'idcount-text'
            }]
        }, {
            region: "center",
            xtype: 'panel',
            frame: false,
            border: false,
            layout: 'fit',
            items: [this.grid]
        }];
        this.callParent(arguments);
    },
    showFirst: function (eventMain) {
        eventMain.on("firmaChangeContext", this.changeContext, this);
    },
    remove: function () {
        this.callParent(arguments);
    },
    show: function () {
        this.showState = true;
        this.callParent(arguments);
        this.changeContextRebuild(null, null);
        if (this.grid && this.grid.rendered)
            this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        this.refreshDatastore();
    },
    hide: function () {
        this.showState = false;
        this.callParent(arguments);
    },
    onDestroy: function () {
        this.taskContextMenu.destroy();
        this.callParent(arguments);
    },
    changeContextRebuild: function (eAppFIRMACTX, eAppFIRMACTXold) {
        var kontekstfirmy = Ext.getCmp(this.id + "kontekstfirmy");
        var kontekstfirmybox = Ext.getCmp(this.id + "kontekstfirmybox");
        if (AppFIRMACTX != null && !Const.AGGREGATEDCORE) {
            kontekstfirmybox.update("<div class='appSetColourRedSplit'></div>");
            kontekstfirmy.setText(Lang.pracujesz_w_kontekscie + AppFIRMACTX.nazwa);
        } else {
            kontekstfirmybox.update("");
            kontekstfirmy.setText("");
        }
        if (this.rebuildGrid) {
            this.rebuildGrid = false;
            var onwer = this.grid.ownerCt;
            Ext.suspendLayouts();
            onwer.remove(this.grid);
            this.grid.destroy();
            this.buildGrid();
            onwer.add(this.grid);
            Ext.resumeLayouts(true);
            if (this.grid && this.grid.rendered)
                this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        }
    },
    changeContext: function (eAppFIRMACTX, eAppFIRMACTXold) {
        this.rebuildGrid = true;
        if (this.showState) {
            this.changeContextRebuild(null, null);
            this.refreshDatastore();
        }
    },
    scrollToView: function (id) {
        this.grid.selectRowScroll("id", id);
    },
    buildGrid: function (datals) {
        var store = new Ext.data.Store({
            autoLoad: false,
            remoteSort: true,
            remoteFilter: true,
            model: Ext.define(null, {
                extend: 'Ext.data.Model',
                idProperty: 'externalid',
                fields: [{
                    name: 'externalid',
                    mapping: 'externalid'
                }, {
                    name: 'externalid',
                    mapping: 'externalid'
                }, {
                    name: 'czas',
                    mapping: 'czas',
                    type: 'date'
                }, {
                    name: 'gpspojazd',
                    mapping: 'gpspojazd'
                }, {
                    name: 'stanzbiornika',
                    mapping: 'stanzbiornika'
                }, {
                    name: 'gpsloc',
                    mapping: 'gpsloc'
                }, {
                    name: 'gpsadres',
                    mapping: 'gpsadres'
                }, {
                    name: 'important',
                    mapping: 'important'
                }]
            }),
            proxy: {
                type: 'ajax',
                url: 'rest/gpspojazdyhistory/getList',
                filterParam: "filters",
                actionMethods: {
                    read: 'POST'
                },
                reader: {
                    type: 'json',
                    rootProperty: 'data',
                    totalProperty: 'total',
                    id: 'externalid'
                },
                listeners: {
                    scope: this,
                    exception: handlGridLoadExceptionJsonRpcL
                }
            },
            listeners: {
                scope: this,
                load: handlGridLoadStoreCheckJsonRpcL
            }, sorters: {
                property: 'czas',
                direction: 'DESC'
            }
        })
        var cols = [{
            header: Lang.id_zewnetrzny,
            width: 145,
            sortable: false,
            dataIndex: 'externalid',
            filter: {
                type: 'numeric'
            },
            filterable: true,
            hidden: true
        }, {
            header: Lang.czas,
            flex: 2,
            sortable: false,
            dataIndex: 'czas',
            renderer: Ext.util.Format.dateRenderer(),
            filter: {
                type: 'date'
            },
            filterable: true
        }, {
            header: Lang.pojazd_gps,
            flex: 2,
            sortable: false,
            dataIndex: 'gpspojazd',
            filter: {
                type: 'string'
            },
            filterable: true
        }, {
            header: Lang.stanzbiornika,
            flex: 1,
            sortable: false,
            dataIndex: 'stanzbiornika',
            renderer: function (v) {
                if (v == null)
                    return null;
                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            },
            filter: {
                type: 'numeric'
            },
            filterable: true
        }, {
            header: Lang.gps_location,
            flex: 2,
            sortable: false,
            dataIndex: 'gpsloc'
        }, {
            header: Lang.gps_adres,
            flex: 2,
            sortable: false,
            dataIndex: 'gpsadres',
            filter: {
                type: 'string'
            },
            filterable: true
        }, {
            header: Lang.kluczowy,
            flex: 2,
            sortable: false,
            dataIndex: 'important',
            renderer: renderBool2,
            filter: {
                type: 'boolean'
            },
            filterable: true
        }];
        store.on("load", function (store) {
            Ext.defer(function () {
                var count = store.getTotalCount();
                var pageSize = store.getPageSize();
                var pageCount = Math.ceil(count / pageSize);
                var currentPage = store.currentPage;
                if (currentPage > pageCount && pageCount > 0) {
                    --store.currentPage;
                }
            }, 1000, this);
        }, this);
        this.grid = new Ext.ux.grid.GridPanelExd({
            plugins: {
                gridfilters: true
            },
            store: store,
            columns: cols,
            frame: false,
            border: false,
            stateId: "grid-" + this.id + (AppFIRMACTX == null ? "-all" : "-"),
            stateful: true,
            stripeRows: true,
            enableHdMenu: true,
            selModel: {
                selType: 'rowmodel',
                mode: 'single',
                allowDeselect: false
            },
            bbar: {
                xtype: 'pagingtoolbarexd',
                displayInfo: true,
                pagingStore: store
            }
        });
    },
    refreshDatastore: function () {
        this.grid.store.load();
    }
});
