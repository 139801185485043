Ext.define('WindowPlyn', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.plyn.capitalise(),
    width: 400,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'panel',
            layout: 'column',
            scrollable: WindowExdMobileDEVICEV,
            items: [{
                columnWidth: 1,
                xtype: 'form',
                id: this.cid + 'formpanel',
                bodyStyle: 'padding:10px 10px 10px 10px',
                defaults: {
                    anchor: '0',
                    labelWidth: 150,
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                items: [{
                    id: this.cid + "nazwa",
                    name: "nazwa",
                    xtype: 'textfield',
                    fieldLabel: Lang.nazwa,
                    allowBlank: false,
                    maxLength: 16,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    id: this.cid + "eiid",
                    name: "eiid",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.eiid,
                    allowBlank: true,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
                }, {
                    id: this.cid + "gestoscmin",
                    name: "gestoscmin",
                    xtype: 'numberfieldexd',
                    fieldLabel: Lang.gestosc_minimalna + "[kg/m^3]",
                    allowBlank: true,
                    allowNegative: false,
                    decimalPrecision: 3,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    minValue: 0.000,
                    maxValue: 9999999,
                    cls: "x-item-disabled-sec",
                    hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
                }, {
                    id: this.cid + "gestoscmax",
                    name: "gestoscmax",
                    xtype: 'numberfieldexd',
                    fieldLabel: Lang.gestosc_maksymalna + "[kg/m^3]",
                    allowBlank: true,
                    allowNegative: false,
                    decimalPrecision: 3,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    minValue: 0.000,
                    maxValue: 9999999,
                    cls: "x-item-disabled-sec",
                    hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
                }, {
                    xtype: 'radiogroupexd',
                    id: this.cid + "fuel",
                    name: "fuel",
                    fieldLabel: Lang.jest_paliwem,
                    columns: 2,
                    vertical: false,
                    items: [{
                        boxLabel: Lang.tak,
                        inputValue: true
                    }, {
                        boxLabel: Lang.nie,
                        inputValue: false,
                        value: true
                    }],
                    hidden: Const.OPTIMALFLAG || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
                }, {
                    xtype: 'radiogroupexd',
                    id: this.cid + "mustsetlicznikstate",
                    name: "mustsetlicznikstate",
                    fieldLabel: Lang.wymagalnosc_stanu_licznika,
                    columns: 2,
                    vertical: false,
                    items: [{
                        boxLabel: Lang.tak,
                        inputValue: true
                    }, {
                        boxLabel: Lang.nie,
                        inputValue: false,
                        value: true
                    }],
                    hidden: Const.SKPFLAG || Const.STARTERFLAG
                }, {
                    xtype: 'radiogroupexd',
                    id: this.cid + "environmental",
                    name: "environmental",
                    fieldLabel: Lang.srodowiskowy,
                    columns: 2,
                    vertical: false,
                    items: [{
                        boxLabel: Lang.tak,
                        inputValue: true
                    }, {
                        boxLabel: Lang.nie,
                        inputValue: false,
                        value: true
                    }],
                    hidden: !Const.ENABLENEFPROCESSING
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid) {
        if (!validPrivilegesOperatorComplex("access.manage.plyny")) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.obiektid = obiektid;
        this.obiekt = null;
        sendAjaxDataJsonRpcL("rest/plyny/getObjectForEdit", {
            id: this.obiektid
        }, this, function (data) {
            if (data.status) {
                data = data.data;
                this.obiekt = data;
                this.show();
                this.center();
                this.setKeyMap({
                    'ESC': {
                        scope: this,
                        handler: this.closeOp
                    }
                });
                Ext.getCmp(this.cid + "nazwa").setValue(null);
                Ext.getCmp(this.cid + "nazwa").clearInvalid();
                Ext.getCmp(this.cid + "eiid").setValue(null);
                Ext.getCmp(this.cid + "eiid").clearInvalid();
                Ext.getCmp(this.cid + "mustsetlicznikstate").setValue2(false);
                Ext.getCmp(this.cid + "fuel").setValue2(false);
                Ext.getCmp(this.cid + "environmental").setValue2(false);
                Ext.getCmp(this.cid + "gestoscmin").setValue(0);
                Ext.getCmp(this.cid + "gestoscmax").setValue(0);
                if (this.obiekt != null) {
                    Ext.getCmp(this.cid + "nazwa").setValue(this.obiekt.nazwa);
                    Ext.getCmp(this.cid + "eiid").setValue(this.obiekt.eiid);
                    Ext.getCmp(this.cid + "mustsetlicznikstate").setValue2(this.obiekt.mustsetlicznikstate);
                    Ext.getCmp(this.cid + "fuel").setValue2(this.obiekt.fuel);
                    Ext.getCmp(this.cid + "environmental").setValue2(this.obiekt.environmental);
                    Ext.getCmp(this.cid + "gestoscmin").setValue(this.obiekt.gestoscmin);
                    Ext.getCmp(this.cid + "gestoscmax").setValue(this.obiekt.gestoscmax);
                }
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    saveOp: function () {
        var canWrite = false;
        if (Ext.getCmp(this.cid + "nazwa").isValid()) {
            canWrite = true;
        }
        if (Ext.getCmp(this.cid + "gestoscmin") > Ext.getCmp(this.cid + "gestoscmax")) {
            Ext.MessageBox.alert(Lang.error, Lang.gestosc_min_nie_moze_byc_wieksza_od_gestosc_max);
        }
        if (canWrite) {
            sendAjaxDataJsonRpcL("rest/plyny/saveObject", {
                id: this.obiektid,
                nazwa: Ext.getCmp(this.cid + "nazwa").getValue(),
                eiid: Ext.getCmp(this.cid + "eiid").getValue(),
                mustsetlicznikstate: Ext.getCmp(this.cid + "mustsetlicznikstate").getValue2(),
                fuel: Ext.getCmp(this.cid + "fuel").getValue2(),
                optlockversion: this.obiekt == null ? null : this.obiekt.optlockversion,
                environmental: Ext.getCmp(this.cid + "environmental").getValue2(),
                gestoscmin: Ext.getCmp(this.cid + "gestoscmin").getValue(),
                gestoscmax: Ext.getCmp(this.cid + "gestoscmax").getValue()
            }, this, function (data) {
                if (data.status) {
                    data = data.data;
                    this.obiektid = data.id;
                    this.close();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
                if (this.callbackF)
                    this.callbackF();
            });
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    closeOp: function () {
        this.close();
    }
});
