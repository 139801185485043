Ext.define('WindowFirma', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.firma.capitalise(),
    width: 800,
    scrollable: true,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    layout: 'fit',
    scrollable: true,
    limitPanelCp: undefined,
    checkIdentChange: function () {
        var cc = Ext.getCmp(this.cid + 'ident_tryb');
        var cch = Ext.getCmp(this.cid + 'ident_pojqq');
        cch.setDisabled(cc.getValue() != 1);
    },
    initComponent: function () {
        this.cid = Ext.id();
        this.limitPanelCp = Ext.create('LimitPanelCp', {
            hidden: Const.SKPFLAG,
            height: 448,
            header: false
        });
        this.items = [{
            xtype: 'tabpanel',
            autoWidth: true,
            border: false,
            deferredRender: false,
            activeTab: 0,
            items: [{
                id: this.cid + "formpanel1",
                title: Lang.ogolne.capitalise(),
                xtype: 'form',
                frame: true,
                border: false,
                autoWidth: true,
                scrollable: true,
                autoHeight: true,
                labelWidth: 150,
                bodyStyle: 'padding:10px 10px 10px 10px',
                defaults: {
                    anchor: "0",
                    labelWidth: 150,
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                items: [{
                    id: this.cid + "nazwa",
                    name: "nazwa",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.nazwa,
                    allowBlank: false,
                    maxLength: 200,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    disabled: isValidOperatorFirmaLimit()
                }, {
                    id: this.cid + "eiid",
                    name: "eiid",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.eiid,
                    allowBlank: true,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    disabled: isValidOperatorFirmaLimit()
                }, {
                    id: this.cid + 'ident_tryb',
                    xtype: 'comboexd',
                    store: [[Const.IDENT_PODWOJNA, Lang.podwojna], [Const.IDENT_POJEDYNCZA, Lang.pojedyncza]],
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    editable: false,
                    fieldLabel: Lang.tryb_identyfikacji,
                    listeners: {
                        scope: this,
                        select: this.checkIdentChange
                    },
                    hidden: Const.SKPFLAG
                }, {
                    id: this.cid + "numberofdigits",
                    name: "numberofdigits",
                    xtype: 'numberfield',
                    fieldLabel: Lang.ilosc_znakow_w_kodzie_recznym,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    value: 8,
                    maxValue: 8,
                    minValue: 4
                }, {
                    xtype: 'radiogroupexd',
                    id: this.cid + "ident_pojqq",
                    name: "ident_pojqq",
                    fieldLabel: Lang.pytanie_o_nazwe,
                    vertical: false,
                    items: [{
                        boxLabel: Lang.tak,
                        inputValue: true
                    }, {
                        boxLabel: Lang.nie,
                        inputValue: false,
                        value: true
                    }],
                    hidden: Const.SKPFLAG
                }, {
                    id: this.cid + 'ident_operator',
                    xtype: 'comboexd',
                    store: [[Const.IDENT_ELEKTRONICZNA, Lang.elektroniczna], [Const.IDENT_ELEKTRONICZNAPIN, Lang.elektroniczna_pin], [Const.IDENT_KODRECZNY, Lang.kodem_recznym]],
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    editable: false,
                    fieldLabel: Lang.identyfikacja_operatora,
                    hidden: Const.SKPFLAG
                }, {
                    id: this.cid + 'ident_odbiorca',
                    xtype: 'comboexd',
                    store: [[Const.IDENT_ELEKTRONICZNA, Lang.elektroniczna], [Const.IDENT_ELEKTRONICZNAPIN, Lang.elektroniczna_pin], [Const.IDENT_KODRECZNY, Lang.kodem_recznym]],
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    editable: false,
                    fieldLabel: Lang.identyfikacja_odbiorcy,
                    hidden: Const.SKPFLAG
                }, {
                    id: this.cid + 'ident_pojazd',
                    xtype: 'comboexd',
                    store: [[Const.IDENT_ELEKTRONICZNA, Lang.elektroniczna], [Const.IDENT_ELEKTRONICZNAPIN, Lang.elektroniczna_pin], [Const.IDENT_KODRECZNY, Lang.kodem_recznym]],
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    editable: false,
                    fieldLabel: Lang.identyfikacja_pojazdu,
                    hidden: Const.SKPFLAG
                }, {
                    xtype: 'radiogroupexd',
                    id: this.cid + "tankowanieoff",
                    name: "tankowanieoff",
                    baseCls: 'x-radio-column',
                    fieldLabel: Lang.tankowanie,
                    vertical: false,
                    items: [{
                        boxLabel: Lang.wlaczone.capitalise(),
                        inputValue: false
                    }, {
                        boxLabel: Lang.wylaczone.capitalise(),
                        inputValue: true,
                        value: true
                    }],
                    hidden: Const.SKPFLAG
                }, {
                    xtype: 'radiogroupexd',
                    id: this.cid + "obslugaoff",
                    name: "obslugaoff",
                    baseCls: 'x-radio-column',
                    fieldLabel: Lang.obsluga,
                    vertical: false,
                    items: [{
                        boxLabel: Lang.wlaczone.capitalise(),
                        inputValue: false
                    }, {
                        boxLabel: Lang.wylaczone.capitalise(),
                        inputValue: true,
                        value: true
                    }],
                    hidden: Const.SKPFLAG
                }, {
                    labelStyle: "margin-top:10px",
                    labelWidth: 150,
                    style: "margin-top:10px",
                    anchor: "0",
                    id: this.cid + "opis",
                    name: "opis",
                    isFormField: true,
                    fieldLabel: Lang.opis,
                    xtype: 'textareaexd'
                }, {
                    id: this.cid + "deactivateall",
                    name: "deactivateall",
                    xtype: "checkboxexd",
                    fieldLabel: Lang.dezaktywacja_wszysktich_pojazdow_i_odbiorcow,
                    hidden: true,
                    labelWidth: 250
                }]
            }, {
                id: this.cid + "formpanel2",
                title: Lang.zaawansowane,
                xtype: 'form',
                frame: true,
                border: false,
                autoWidth: true,
                scrollable: true,
                autoHeight: true,
                height: 452,
                labelWidth: 150,
                bodyStyle: 'padding:10px 10px 10px 10px',
                defaults: {
                    anchor: "0",
                    labelWidth: 150,
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                items: [{
                    xtype: 'radiogroupexd',
                    id: this.cid + "askforvolumetank",
                    name: "askforvolumetank",
                    fieldLabel: Lang.pytanie_o_ilosc_dozatankowania,
                    vertical: false,
                    hidden: !Const.OPTIMALFLAG,
                    items: [{
                        boxLabel: Lang.tak,
                        inputValue: true
                    }, {
                        boxLabel: Lang.nie,
                        inputValue: false,
                        value: true
                    }]
                }, {
                    xtype: 'radiogroupexd',
                    id: this.cid + "mustsetlicznikstate",
                    name: "mustsetlicznikstate",
                    fieldLabel: Lang.wymagalnosc_stanu_licznika,
                    vertical: false,
                    items: [{
                        boxLabel: Lang.tak,
                        inputValue: true
                    }, {
                        boxLabel: Lang.nie,
                        inputValue: false,
                        value: true
                    }],
                    listeners: {
                        scope: this,
                        change: function (radiogroup, newValue) {
                            var mustsetlicznikstatecontrol = Ext.getCmp(this.cid + "mustsetlicznikstatecontrol");
                            if (!newValue) {
                                mustsetlicznikstatecontrol.setValue(false);
                                mustsetlicznikstatecontrol.fireEvent("change", mustsetlicznikstatecontrol, mustsetlicznikstatecontrol.getValue2());
                            }
                            mustsetlicznikstatecontrol.setDisabled(!newValue);
                            var errorlicznikcondition = Ext.getCmp(this.cid + "errorlicznikcondition");
                            if (!newValue) {
                                errorlicznikcondition.setValue(false);
                            }
                            errorlicznikcondition.setDisabled(!newValue);
                        }
                    },
                    hidden: Const.SKPFLAG
                }, {
                    xtype: 'radiogroupexd',
                    id: this.cid + "mustsetlicznikstatecontrol",
                    name: "mustsetlicznikstatecontrol",
                    fieldLabel: Lang.kontrola_stanu_licznika,
                    vertical: false,
                    items: [{
                        boxLabel: Lang.tak,
                        inputValue: true
                    }, {
                        boxLabel: Lang.nie,
                        inputValue: false,
                        value: true
                    }],
                    listeners: {
                        scope: this,
                        change: function (radiogroup, newValue) {
                            var controlmileagediff = Ext.getCmp(this.cid + "controlmileagediff");
                            if (!newValue) {
                                controlmileagediff.setValue(false);
                                controlmileagediff.fireEvent("change", controlmileagediff, controlmileagediff.getValue2());
                            }
                            controlmileagediff.setDisabled(!newValue);
                        }
                    },
                    hidden: Const.SKPFLAG
                }, {
                    id: this.cid + "errorlicznikcondition",
                    name: "errorlicznikcondition",
                    xtype: 'radiogroupexd',
                    fieldLabel: Lang.warunek_blednego_stanu_licznika,
                    columns: 2,
                    vertical: false,
                    hidden: Const.BASICFLAG || Const.OPTIMALFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                    items: [{
                        boxLabel: Lang.blokada.capitalise(),
                        inputValue: true,
                        value: true
                    }, {
                        boxLabel: Lang.informacja.capitalise(),
                        inputValue: false
                    }]
                }, {
                    xtype: 'radiogroupexd',
                    id: this.cid + "controlmileagediff",
                    name: "controlmileagediff",
                    fieldLabel: Lang.kontrola_roznicy_przebiegu,
                    columns: 2,
                    vertical: false,
                    hidden: Const.BASICFLAG || Const.OPTIMALFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                    items: [{
                        boxLabel: Lang.tak.capitalise(),
                        inputValue: true,
                        value: true
                    }, {
                        boxLabel: Lang.nie.capitalise(),
                        inputValue: false
                    }],
                    listeners: {
                        change: function (radiogroup, newValue) {
                            Ext.getCmp(this.cid + "maxmileagediffkm").setDisabled(!newValue);
                            Ext.getCmp(this.cid + "maxmileagediffrg").setDisabled(!newValue);
                            Ext.getCmp(this.cid + "maxmileagediffkm").allowBlank = !newValue;
                            Ext.getCmp(this.cid + "maxmileagediffrg").allowBlank = !newValue;
                        },
                        scope: this
                    }
                }, {
                    id: this.cid + "maxmileagediffkm",
                    name: "maxmileagediffkm",
                    xtype: 'numberfieldexd',
                    fieldLabel: Lang.maksymalna_roznica_przebiegu_km,
                    allowBlank: false,
                    disabled: true,
                    decimalPrecision: 2,
                    minValue: 0,
                    cls: "x-item-disabled-sec",
                    hidden: Const.BASICFLAG || Const.OPTIMALFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
                }, {
                    id: this.cid + "maxmileagediffrg",
                    name: "maxmileagediffrg",
                    xtype: 'numberfieldexd',
                    fieldLabel: Lang.maksymalna_roznica_przebiegu_rg,
                    allowBlank: false,
                    disabled: true,
                    decimalPrecision: 2,
                    minValue: 0,
                    cls: "x-item-disabled-sec",
                    hidden: Const.BASICFLAG || Const.OPTIMALFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
                }]
            }, {
                title: Lang.limity.capitalise(),
                frame: true,
                border: false,
                autoWidth: true,
                scrollable: true,
                autoHeight: true,
                layout: WindowExdMobileDEVICEV ? 'fit' : 'anchor',
                height: 452,
                hidden: Const.SKPFLAG || Const.BASICFLAG || Const.STARTERFLAG,
                defaults: {
                    anchor: "0",
                    labelWidth: 80
                },
                items: [this.limitPanelCp]
            }, {
                title: Lang.zbiorniki.capitalise(),
                id: this.cid + "formpanel3",
                hidden: false,
                xtype: "panel",
                layout: "fit",
                frame: true,
                autoWidth: true,
                labelAlign: "top",
                bodyStyle: 'padding:10px 10px 10px 10px',
                items: [{
                    xtype: 'panel',
                    frame: false,
                    border: false,
                    autoWidth: true,
                    layout: 'hbox',
                    layoutConfig: {
                        align: "stretch"
                    },
                    items: [new Ext.ux.grid.GridPanelExd({
                        store: new Ext.data.Store({
                            autoLoad: true,
                            proxy: {
                                type: "ajax",
                                url: "rest/zbiorniki/getList",
                                actionMethods: {
                                    read: 'GET'
                                },
                                reader: new Ext.data.JsonReader({
                                    id: "id",
                                    rootProperty: ''
                                }, [{
                                    name: 'nazwa',
                                    mapping: 'nazwa'
                                }])
                            }
                        }),
                        columns: [{
                            header: Lang.nazwa,
                            flex: 1,
                            width: 145,
                            sortable: true,
                            dataIndex: 'nazwa'
                        }],
                        title: Lang.dostepne.capitalise(),
                        height: 390,
                        flex: 1,
                        frame: true,
                        border: true,
                        id: this.cid + "zbiorniks_from",
                        stateId: null,
                        stateful: false,
                        stripeRows: true,
                        enableHdMenu: true,
                        selModel: {
                            selType: 'rowmodel',
                            mode: 'SINGLE'
                        },
                        listeners: {
                            scope: this,
                            rowdblclick: function () {
                                var grid = Ext.getCmp(this.cid + "zbiorniks_from");
                                var grid2 = Ext.getCmp(this.cid + "zbiorniks_to");
                                var r = grid.getSelR();
                                if (r == null || r.items.length == 0)
                                    return;
                                var selectedItem = r.items[0];
                                grid.store.remove(selectedItem);
                                grid2.store.add(selectedItem);
                            }
                        },
                        getSelR: function () {
                            var gridd = this;
                            if (!gridd.getSelectionModel().hasSelection()) {
                                return null;
                            } else {
                                if (gridd.getSelectionModel().getSelected() != null) {
                                    return gridd.getSelectionModel().getSelected();
                                }
                            }
                            return null;
                        }
                    }), {
                        width: 55,
                        xtype: 'panel',
                        frame: false,
                        border: false,
                        bodyStyle: 'padding:5px 5px 5px 5px',
                        height: 390,
                        layout: {
                            type: 'vbox',
                            pack: "center",
                            align: "stretch"
                        },
                        items: [{
                            xtype: 'button',
                            text: ">",
                            width: 50,
                            scope: this,
                            style: "margin-bottom:5px;",
                            handler: function () {
                                var grid = Ext.getCmp(this.cid + "zbiorniks_from");
                                var grid2 = Ext.getCmp(this.cid + "zbiorniks_to");
                                var r = grid.getSelR();
                                if (r == null || r.items.length == 0)
                                    return;
                                var selectedItem = r.items[0];
                                grid.store.remove(selectedItem);
                                grid2.store.add(selectedItem);
                            }
                        }, {
                            xtype: 'button',
                            text: ">>",
                            width: 50,
                            scope: this,
                            style: "margin-bottom:5px;",
                            handler: function () {
                                var grid = Ext.getCmp(this.cid + "zbiorniks_from");
                                var grid2 = Ext.getCmp(this.cid + "zbiorniks_to");
                                grid2.store.add(grid.store.getRange());
                                grid.store.removeAll();
                            }
                        }, {
                            xtype: 'button',
                            text: "<",
                            width: 50,
                            scope: this,
                            style: "margin-bottom:5px;",
                            handler: function () {
                                var grid = Ext.getCmp(this.cid + "zbiorniks_from");
                                var grid2 = Ext.getCmp(this.cid + "zbiorniks_to");
                                var r = grid2.getSelR();
                                if (r == null || r.items.length == 0)
                                    return;
                                var selectedItem = r.items[0];
                                grid2.store.remove(selectedItem);
                                grid.store.add(selectedItem);
                            }
                        }, {
                            xtype: 'button',
                            text: "<<",
                            width: 50,
                            scope: this,
                            style: "margin-bottom:5px;",
                            handler: function () {
                                var grid = Ext.getCmp(this.cid + "zbiorniks_from");
                                var grid2 = Ext.getCmp(this.cid + "zbiorniks_to");
                                grid.store.add(grid2.store.getRange());
                                grid2.store.removeAll();
                            }
                        }]
                    }, new Ext.ux.grid.GridPanelExd({
                        store: new Ext.data.Store({
                            autoLoad: false,
                            proxy: new Ext.data.MemoryProxy({}),
                            reader: new Ext.data.JsonReader({
                                id: "id",
                                rootProperty: ''
                            }, [{
                                name: 'nazwa',
                                mapping: 'nazwa'
                            }])
                        }),
                        columns: [{
                            header: Lang.nazwa,
                            flex: 1,
                            width: 145,
                            sortable: true,
                            dataIndex: 'nazwa'
                        }],
                        title: Lang.wybrane.capitalise(),
                        height: 390,
                        flex: 1,
                        frame: true,
                        border: true,
                        id: this.cid + "zbiorniks_to",
                        stateId: null,
                        stateful: false,
                        stripeRows: true,
                        enableHdMenu: true,
                        selModel: {
                            selType: 'rowmodel',
                            mode: 'SINGLE'
                        },
                        listeners: {
                            scope: this,
                            rowdblclick: function () {
                                var grid = Ext.getCmp(this.cid + "zbiorniks_from");
                                var grid2 = Ext.getCmp(this.cid + "zbiorniks_to");
                                var r = grid2.getSelR();
                                if (r == null || r.items.length == 0)
                                    return;
                                var selectedItem = r.items[0];
                                grid2.store.remove(selectedItem);
                                grid.store.add(selectedItem);
                            }
                        },
                        getSelR: function () {
                            var gridd = this;
                            if (!gridd.getSelectionModel().hasSelection()) {
                                return null;
                            } else {
                                if (gridd.getSelectionModel().getSelected() != null) {
                                    return gridd.getSelectionModel().getSelected();
                                }
                            }
                            return null;
                        }
                    })]
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid) {
        if (!validPrivilegesOperatorComplex("access.manage.firmy")) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.obiektid = obiektid;
        this.obiekt = null;
        performAjaxLPromise('rest/zbiorniki/getList').then(data => {
            let listFnData0 = data;
            performAjaxLPromise("rest/firmy/getObjectForEdit", {
                id: this.obiektid
            }).then(data => {
                this.obiekt = data;
                this.show();
                this.center();
                this.setKeyMap({
                    'ESC': {
                        scope: this,
                        handler: this.closeOp
                    }
                });
                let grid = Ext.getCmp(this.cid + "zbiorniks_from");
                let grid2 = Ext.getCmp(this.cid + "zbiorniks_to");
                Ext.getCmp(this.cid + "nazwa").setValue(null);
                Ext.getCmp(this.cid + "eiid").setValue(null);
                Ext.getCmp(this.cid + "opis").setValue(null);
                Ext.getCmp(this.cid + "maxmileagediffkm").setValue(null);
                Ext.getCmp(this.cid + "maxmileagediffrg").setValue(null);
                Ext.getCmp(this.cid + "controlmileagediff").setValue2(false);
                Ext.getCmp(this.cid + "nazwa").clearInvalid();
                Ext.getCmp(this.cid + "eiid").clearInvalid();
                Ext.getCmp(this.cid + "opis").clearInvalid();
                Ext.getCmp(this.cid + "maxmileagediffkm").clearInvalid();
                Ext.getCmp(this.cid + "maxmileagediffrg").clearInvalid();
                Ext.getCmp(this.cid + "deactivateall").clearInvalid();
                Ext.getCmp(this.cid + "deactivateall").setValue2(false);
                if (this.obiekt) {
                    let obss = generateDiffCollection(listFnData0, this.obiekt.zbiorniki, "id");
                    grid.store.loadData(obss.restls);
                    grid2.store.loadData(obss.containsls);
                    this.limitydatas = this.obiekt.limitydatas;
                    this.limitPanelCp.setLimitList(this.limitydatas);
                    Ext.getCmp(this.cid + "nazwa").setValue(this.obiekt.nazwa);
                    Ext.getCmp(this.cid + "eiid").setValue(this.obiekt.eiid);
                    Ext.getCmp(this.cid + "opis").setValue(this.obiekt.opis);
                    Ext.getCmp(this.cid + "ident_tryb").setValue(this.obiekt.ident_tryb);
                    Ext.getCmp(this.cid + "numberofdigits").setValue(this.obiekt.numberofdigits);
                    Ext.getCmp(this.cid + "ident_operator").setValue(this.obiekt.ident_operator);
                    Ext.getCmp(this.cid + "ident_odbiorca").setValue(this.obiekt.ident_odbiorca);
                    Ext.getCmp(this.cid + "ident_pojazd").setValue(this.obiekt.ident_pojazd);
                    Ext.getCmp(this.cid + "ident_pojqq").setValue2(this.obiekt.ident_pojqq);
                    Ext.getCmp(this.cid + "tankowanieoff").setValue2(data.tankowanieoff);
                    Ext.getCmp(this.cid + "obslugaoff").setValue2(data.obslugaoff);
                    Ext.getCmp(this.cid + "askforvolumetank").setValue2(data.askforvolumetank);
                    Ext.getCmp(this.cid + "mustsetlicznikstate").setValue2(data.mustsetlicznikstate);
                    Ext.getCmp(this.cid + "mustsetlicznikstatecontrol").setValue2(data.mustsetlicznikstatecontrol);
                    Ext.getCmp(this.cid + "deactivateall").setHidden(false);
                    Ext.getCmp(this.cid + "errorlicznikcondition").setValue2(data.errorlicznikcondition);
                    if (data.maxmileagediffkm > 0 && data.maxmileagediffrg > 0) {
                        Ext.getCmp(this.cid + "maxmileagediffkm").setValue(data.maxmileagediffkm);
                        Ext.getCmp(this.cid + "maxmileagediffrg").setValue(data.maxmileagediffrg);
                        Ext.getCmp(this.cid + "controlmileagediff").setValue2(true);
                    }
                } else {
                    grid.store.loadData(listFnData0);
                    grid2.store.loadData([]);
                    this.limitydatas = [];
                    this.limitPanelCp.setLimitList(this.limitydatas);
                    Ext.getCmp(this.cid + "mustsetlicznikstate").setValue2(false);
                    Ext.getCmp(this.cid + "mustsetlicznikstate").clearInvalid();
                    Ext.getCmp(this.cid + "mustsetlicznikstatecontrol").setValue2(false);
                    Ext.getCmp(this.cid + "mustsetlicznikstatecontrol").clearInvalid();
                    Ext.getCmp(this.cid + "errorlicznikcondition").setValue2(null);
                    Ext.getCmp(this.cid + "errorlicznikcondition").clearInvalid();
                    Ext.getCmp(this.cid + "tankowanieoff").setValue2(false);
                    Ext.getCmp(this.cid + "tankowanieoff").clearInvalid();
                    Ext.getCmp(this.cid + "obslugaoff").setValue2(false);
                    Ext.getCmp(this.cid + "obslugaoff").clearInvalid();
                    Ext.getCmp(this.cid + "askforvolumetank").setValue2(true);
                    Ext.getCmp(this.cid + "ident_tryb").setValue(Const.IDENT_PODWOJNA);
                    Ext.getCmp(this.cid + "ident_operator").setValue(Const.IDENT_ELEKTRONICZNA);
                    Ext.getCmp(this.cid + "ident_odbiorca").setValue(Const.IDENT_ELEKTRONICZNA);
                    Ext.getCmp(this.cid + "ident_pojazd").setValue(Const.IDENT_ELEKTRONICZNA);
                    Ext.getCmp(this.cid + "ident_pojqq").setValue2(false);
                }
                var mustsetlicznikstatecontrol = Ext.getCmp(this.cid + "mustsetlicznikstatecontrol");
                mustsetlicznikstatecontrol.fireEvent("change", mustsetlicznikstatecontrol, mustsetlicznikstatecontrol.getValue2());
                var mustsetlicznikstate = Ext.getCmp(this.cid + "mustsetlicznikstate");
                mustsetlicznikstate.fireEvent("change", mustsetlicznikstate, mustsetlicznikstate.getValue2());
                this.checkIdentChange();
            }).catch(err => {
                Ext.MessageBox.alert(Lang.error, err);
            });
        }).catch(err => {
            Ext.MessageBox.alert(Lang.error, err);
        });

    },
    saveOp: function () {
        var canWrite = false;
        if (Ext.getCmp(this.cid + "nazwa").isValid() && Ext.getCmp(this.cid + "maxmileagediffkm").isValid() && Ext.getCmp(this.cid + "maxmileagediffrg").isValid() && Ext.getCmp(this.cid + "numberofdigits").isValid()) {
            canWrite = true;
        }
        var controlMileageDiff = Ext.getCmp(this.cid + "controlmileagediff").getValue2();
        if (canWrite) {
            let grid2 = Ext.getCmp(this.cid + "zbiorniks_to");
            zbiorniki = grid2.store.collect('id');
            sendAjaxDataJsonRpcL("rest/firmy/saveObject", {
                id: this.obiektid,
                nazwa: Ext.getCmp(this.cid + "nazwa").getValue(),
                eiid: Ext.getCmp(this.cid + "eiid").getValue(),
                opis: Ext.getCmp(this.cid + "opis").getValue(),
                ident_tryb: Ext.getCmp(this.cid + "ident_tryb").getValue(),
                ident_operator: Ext.getCmp(this.cid + "ident_operator").getValue(),
                ident_odbiorca: Ext.getCmp(this.cid + "ident_odbiorca").getValue(),
                ident_pojazd: Ext.getCmp(this.cid + "ident_pojazd").getValue(),
                ident_pojqq: Ext.getCmp(this.cid + "ident_pojqq").getValue2(),
                tankowanieoff: Ext.getCmp(this.cid + "tankowanieoff").getValue2(),
                obslugaoff: Ext.getCmp(this.cid + "obslugaoff").getValue2(),
                askforvolumetank: Ext.getCmp(this.cid + "askforvolumetank").getValue2(),
                mustsetlicznikstate: Ext.getCmp(this.cid + "mustsetlicznikstate").getValue2(),
                mustsetlicznikstatecontrol: Ext.getCmp(this.cid + "mustsetlicznikstatecontrol").getValue2(),
                maxmileagediffkm: controlMileageDiff ? Ext.getCmp(this.cid + "maxmileagediffkm").getValue() : 0,
                maxmileagediffrg: controlMileageDiff ? Ext.getCmp(this.cid + "maxmileagediffrg").getValue() : 0,
                errorlicznikcondition: Ext.getCmp(this.cid + "errorlicznikcondition").getValue2(),
                optlockversion: this.obiekt == null ? null : this.obiekt.optlockversion,
                zbiorniki: zbiorniki,
                deactivateall: Ext.getCmp(this.cid + "deactivateall").getValue2(),
                numberofdigits: Ext.getCmp(this.cid + "numberofdigits").getValue(),
                data: {
                    limitydatas: this.limitPanelCp.getLimitList()
                }
            }, this, function (data) {
                if (data.status) {
                    data = data.data;
                    this.obiektid = data.id;
                    this.close();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
                if (this.callbackF)
                    this.callbackF();
            });
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    closeOp: function () {
        this.close();
    }
});
