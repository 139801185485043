Ext.define('ViewAlarmy', {
    extend: 'Ext.panel.Panel',
    cls: 'a-ViewAlarmy',
    grid: null,
    gridpage: null,
    id: "alarmy",
    rebuildGrid: false,
    showState: false,
    statics: {
        accessPropertyViewName: "access.view.alarmy"
    },
    cat: "p",
    text: Lang.alarmy,
    buttonIconCls: "iconAlarm",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.buildGrid();
        this.items = [{
            region: "north",
            xtype: 'toolbar',
            items: [{
                xtype: 'button',
                id: this.id + "otworzalarm",
                iconAlign: 'left',
                iconCls: 'iconEdit',
                text: Lang.podglad.capitalise(),
                scope: this,
                handler: this.otworzAlarm
            }, "-", {
                xtype: 'button',
                id: this.id + "markprzeczytano",
                iconAlign: 'left',
                iconCls: 'iconEdit',
                text: Lang.przeczytano.capitalise(),
                scope: this,
                handler: this.markAsPrzeczytano
            }, "-", {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: 'iconEdit',
                text: Lang.przeczytano_wszystkie.capitalise(),
                scope: this,
                handler: this.markAsPrzeczytanoAll
            }, "-", {
                xtype: 'button',
                id: this.id + "markarchived",
                iconAlign: 'left',
                iconCls: 'iconDelete',
                text: Lang.zarchiwizowano.capitalise(),
                scope: this,
                handler: this.markAsArchived
            }, "-", {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: 'iconDelete',
                text: Lang.zarchiwizowano_wszystkie.capitalise(),
                scope: this,
                handler: this.markAsArchivedAll
            }, "-", {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconRefresh",
                text: Lang.odswiez.capitalise(),
                scope: this,
                handler: this.refreshDatastore
            }, {
                xtype: "box",
                id: this.id + "kontekstfirmybox",
                cls: "kontekstfirmy-box"
            }, {
                id: this.id + "kontekstfirmy",
                xtype: 'label',
                style: 'display:block;margin-right:5px',
                cls: "kontekstfirmy-text"
            }]
        }, {
            region: "center",
            xtype: 'panel',
            frame: false,
            border: false,
            layout: 'fit',
            items: [this.grid]
        }];
        this.callParent(arguments);
    },
    showFirst: function (eventMain) {
        eventMain.on("firmaChangeContext", this.changeContext, this);
    },
    remove: function () {
        this.callParent(arguments);
    },
    show: function () {
        this.showState = true;
        this.callParent(arguments);
        this.changeContextRebuild(null, null);
        if (this.grid && this.grid.rendered)
            this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        this.refreshDatastore();
    },
    hide: function () {
        this.showState = false;
        this.callParent(arguments);
    },
    scrollToView: function (id) {
        this.grid.selectRowScroll("id", id);
    },
    changeContextRebuild: function (eAppFIRMACTX, eAppFIRMACTXold) {
        var kontekstfirmy = Ext.getCmp(this.id + "kontekstfirmy");
        var kontekstfirmybox = Ext.getCmp(this.id + "kontekstfirmybox");
        if (AppFIRMACTX != null && !Const.AGGREGATEDCORE) {
            kontekstfirmybox.update("<div class='appSetColourRedSplit'></div>");
            kontekstfirmy.setText(Lang.pracujesz_w_kontekscie + AppFIRMACTX.nazwa);
        } else {
            kontekstfirmybox.update("");
            kontekstfirmy.setText("");
        }
        if (this.rebuildGrid) {
            this.rebuildGrid = false;
            var onwer = this.grid.ownerCt;
            Ext.suspendLayouts();
            onwer.remove(this.grid);
            this.grid.destroy();
            this.buildGrid();
            onwer.add(this.grid);
            Ext.resumeLayouts(true);
            if (this.grid && this.grid.rendered)
                this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        }
    },
    changeContext: function (eAppFIRMACTX, eAppFIRMACTXold) {
        this.rebuildGrid = true;
        if (this.showState) {
            this.changeContextRebuild(null, null);
            this.refreshDatastore();
        }
    },
    buildGrid: function (datals) {
        var store = Ext.create('Ext.data.Store', {
            autoLoad: false,
            remoteSort: true,
            remoteFilter: true,
            pageSize: 50,
            fields: [{
                name: 'czas',
                mapping: 'czas',
                type: 'date'
            }, {
                name: 'msg',
                mapping: 'msg'
            }, {
                name: 'opis',
                mapping: 'opis'
            }, {
                name: 'typ',
                mapping: 'typ'
            }, {
                name: 'firma',
                mapping: 'firma'
            }, {
                name: 'readed',
                mapping: 'readed'
            }, {
                name: 'archived',
                mapping: 'archived'
            }],
            proxy: {
                type: "ajax",
                url: "rest/alarmy/getList",
                filterParam: "filters",
                actionMethods: {
                    read: "POST"
                },
                reader: {
                    type: 'json',
                    id: 'id',
                    rootProperty: 'data',
                    totalProperty: 'total'
                },
                listeners: {
                    scope: this,
                    exception: handlGridLoadExceptionJsonRpcL
                }
            },
            sorters: {
                property: 'czas',
                direction: 'DESC'
            },
            listeners: {
                scope: this,
                load: handlGridLoadStoreCheckJsonRpcL
            }
        });
        store.on("load", function (store) {
            Ext.defer(function () {
                var count = store.getTotalCount();
                var pageSize = store.getPageSize();
                var pageCount = Math.ceil(count / pageSize);
                var currentPage = store.currentPage;
                if (currentPage > pageCount && pageCount > 0) {
                    --store.currentPage;
                }
            }, 1000, this);
        }, this);
        this.grid = new Ext.ux.grid.GridPanelExd({
            plugins: {
                gridfilters: true
            },
            store: store,
            viewConfig: {
                forceFit: true,
                getRowClass: function (record, index) {
                    if (!record.data.readed) {
                        return 'rowBold';
                    } else {
                        return '';
                    }
                }
            },
            columns: [{
                header: Lang.czas,
                flex: 1,
                sortable: true,
                dataIndex: 'czas',
                renderer: Ext.util.Format.dateRenderer(),
                filter: {
                    type: 'date'
                },
                filterable: true
            }, {
                header: Lang.komunikat,
                flex: 2,
                sortable: true,
                dataIndex: 'msg',
                filter: {
                    type: 'string'
                },
                filterable: true
            }, {
                header: Lang.opis,
                flex: 3,
                sortable: true,
                dataIndex: 'opis',
                filter: {
                    type: 'string'
                },
                filterable: true
            }, {
                header: Lang.firma,
                flex: 1,
                sortable: true,
                dataIndex: 'firma',
                filter: {
                    type: 'string'
                },
                filterable: true
                // }, {
                //     header: Lang.typ,
                //     flex: 1,
                //     sortable: true,
                //     dataIndex: 'typ',
                //     filter: {
                //         type: 'string'
                //     },
                //     filterable: true
            }],
            frame: false,
            border: false,
            stateId: "grid-" + this.id + (AppFIRMACTX == null ? "-all" : "-"),
            stateful: true,
            stripeRows: true,
            enableHdMenu: true,
            selModel: {
                selType: 'rowmodel',
                mode: 'multi'
            },
            bbar: {
                xtype: 'pagingtoolbarexd',
                displayInfo: true,
                pagingStore: store
            }
        });
        this.grid.addListener("rowdblclick", function () {
            if (validPrivilegesOperatorComplex(ViewAlarmy.accessPropertyViewName))
                this.otworzAlarm();
        }, this);
        this.grid.addListener("render", function () {
            this.manageSelect(this.grid.getSelectionModel());
        }, this);
        this.grid.addListener("select", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.grid.addListener("selectionchange", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
    },
    manageSelect: function () {
        var otworzalarm = Ext.getCmp(this.id + "otworzalarm");
        var markprzeczytano = Ext.getCmp(this.id + "markprzeczytano");
        var markarchived = Ext.getCmp(this.id + "markarchived");
        if (otworzalarm != null && markprzeczytano != null && markarchived != null) {
            if (!this.grid.getSelectionModel().hasSelection() || this.grid.getSelectionModel().getSelected() == null) {
                otworzalarm.setDisabled(true);
                markprzeczytano.setDisabled(true);
                markarchived.setDisabled(true);
            } else {
                otworzalarm.setDisabled(false);
                markprzeczytano.setDisabled(!this.grid.getSelectionModel().getSelection().some(x => (x == null || !x.data.readed)));
                markarchived.setDisabled(this.grid.getSelectionModel().getSelection().length < 1);
            }
        }
    },
    refreshDatastore: function () {
        this.grid.store.load();
    },
    otworzAlarm: function () {
        let ids = this.getSelectedIds();
        if (ids.length == 1) {
            windowObject = Ext.create('WindowAlarm');
            windowObject.showW(ids[0]);
            windowObject.on('closeSuccess', this.refreshDatastore, this);
        }
    },
    markAsPrzeczytano: function () {
        sendAjaxDataJsonRpcL("rest/alarmy/markAsReaded?alarmIds=" + this.getSelectedIds(), null, this, function (data) {
            if (data.status) {
                this.refreshDatastore();
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    markAsPrzeczytanoAll: function () {
        sendAjaxDataJsonRpcL("rest/alarmy/markAsReaded?markAll=true", null, this, function (data) {
            if (data.status) {
                this.refreshDatastore();
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    markAsArchived: function () {
        Ext.MessageBox.show({
            title: Lang.archiwizacja,
            message: Lang.czy_napewno_archiwizowac_obiekt,
            buttons: Ext.MessageBox.YESNO,
            scope: this,
            fn: function (btn) {
                if (btn == "yes") {
                    sendAjaxDataJsonRpcL("rest/alarmy/markAsArchived?alarmIds=" + this.getSelectedIds(), null, this, function (data) {
                        if (data.status) {
                            this.refreshDatastore();
                        } else {
                            Ext.MessageBox.alert(Lang.error, data.error);
                        }
                    });
                }
            }
        });
    },
    markAsArchivedAll: function () {
        Ext.MessageBox.show({
            title: Lang.archiwizacja,
            message: Lang.czy_napewno_archiwizowac_obiekt,
            buttons: Ext.MessageBox.YESNO,
            scope: this,
            fn: function (btn) {
                if (btn == "yes") {
                    sendAjaxDataJsonRpcL("rest/alarmy/markAsArchived?markAll=true", null, this, function (data) {
                        if (data.status) {
                            this.refreshDatastore();
                        } else {
                            Ext.MessageBox.alert(Lang.error, data.error);
                        }
                    });
                }
            }
        });
    },
    getSelectedIds: function () {
        let ids = [];
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                ids.push(this.rowContext.id);
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                this.grid.getSelectionModel().getSelection().forEach(x => {
                    ids.push(x.id);
                });
            }
        }
        return ids;
    }
});
