Ext.define('WindowSkpuliteFlashChoose', {
    extend: 'Ext.ux.window.WindowExd',
    accessPropertyManageName: "access.manage.syskonf",
    accessPropertyViewName: "access.view.syskonf",
    title: Lang.flashowanie.capitalise(),
    width: 400,
    minHeight: 250,
    closable: false,
    collapsible: false,
    modal: true,
    scrollable: true,
    resizable: false,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'panel',
            layout: 'column',
            scrollable: WindowExdMobileDEVICEV,
            items: [{
                columnWidth: 1,
                xtype: 'form',
                id: this.cid + 'formpanel',
                bodyStyle: 'padding:10px 10px 10px 10px',
                scrollable: true,
                defaults: {
                    anchor: '0',
                    labelWidth: 150,
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                items: [{
                    id: this.cid + "currentversion",
                    name: "currentversion",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.biezacy,
                    editable: false
                }, {
                    id: this.cid + 'versions',
                    xtype: 'comboexd',
                    valueField: 'version',
                    displayField: 'version',
                    fieldLabel: Lang.zmien_na,
                    store: new Ext.data.JsonStore({
                        id: "id",
                        rootProperty: '',
                        sorters: [{
                            property: "nazwa",
                            direction: "ASC"
                        }],
                        fields: [{
                            name: 'version',
                            mapping: 'version'
                        }]
                    }),
                    triggerAction: 'all',
                    resizable: false,
                    editable: false,
                    mode: "local",
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    listeners: {
                        scope: this,
                        select: function (c, r, i) {
                            Ext.getCmp(this.cid + 'save').setDisabled(r.get("version") == null || r.get("version") == this.version)
                        }
                    },
                    cls: "x-item-disabled-sec"
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (id, version, devid) {
        if (!validPrivilegesOperatorComplex("access.manage.skps")) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.obiekt = null;
        this.iddev = id;
        this.version = version;
        this.devid = devid;
        sendAjaxDataJsonRpcL("rest/skpulite/getAvailableFlashVersions", {id: this.iddev}, this, function (data) {
            if (data.status) {
                var listFnData = data.data;
                this.show();
                this.center();
                this.setKeyMap({
                    'ESC': {
                        scope: this,
                        handler: this.closeOp
                    }
                });
                Ext.getCmp(this.cid + 'versions').store.loadData(listFnData);
                Ext.getCmp(this.cid + 'currentversion').setValue(version);
                Ext.getCmp(this.cid + 'save').setDisabled(listFnData == null || listFnData.length == 0)
                if (listFnData != null && listFnData.length != 0) {
                    Ext.getCmp(this.cid + 'versions').setValue(listFnData[0]);
                    Ext.getCmp(this.cid + 'save').setDisabled(listFnData[0].version == this.version)
                }
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    saveOp: function () {
        if (!validPrivilegesOperatorComplex("access.manage.skps")) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        var canWrite = false;
        if (Ext.getCmp(this.cid + 'formpanel').isValid()) {
            canWrite = true;
        }
        if (canWrite) {
            sendAjaxDataJsonRpcL("rest/skpulite/changeFlashVersion", {
                id: this.iddev,
                version: Ext.getCmp(this.cid + 'versions').getRow().get('version')
            }, this, function (data) {
                if (data.status) {
                    Ext.MessageBox.alert(Lang.ok, Lang.rozkaz_zostal_wyslany);
                    this.hide();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                    return;
                }
                if (this.callbackF) {
                    this.callbackF();
                }
            }, 0);
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    closeOp: function () {
        this.close();
    }
});
