Ext.define('WindowFirmaProDef', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.konfiguracja_domyslnej_ustawien.capitalise(),
    width: 800,
    closable: false,
    collapsible: false,
    modal: true,
    scrollable: true,
    resizable: false,
    layout: {
        type: 'vbox',
        align: 'stretch'
    },
    checkIdentChange: function () {
        var cc = Ext.getCmp(this.cid + 'ident_tryb');
        var cch = Ext.getCmp(this.cid + 'ident_pojqq');
        cch.setDisabled(cc.getValue() != 1);
    },
    initComponent: function () {
        this.cid = Ext.id();
        let formpanel = {
            columnWidth: WindowExdMobileDEVICEV ? 1 : 0.5,
            xtype: 'form',
            id: this.cid + 'formpanel',
            bodyStyle: 'padding:10px 10px 10px 10px',
            scrollable: true,
            defaults: {
                anchor: '0',
                labelWidth: 150,
                labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
            },
            items: [{
                id: this.cid + 'ident_tryb',
                xtype: 'comboexd',
                width: '95%',
                store: Const.STARTERFLAG ? [[Const.IDENT_POJEDYNCZA, Lang.pojedyncza]] : [[Const.IDENT_PODWOJNA, Lang.podwojna], [Const.IDENT_POJEDYNCZA, Lang.pojedyncza]],
                triggerAction: 'all',
                resizable: false,
                mode: "local",
                editable: false,
                fieldLabel: Lang.tryb_identyfikacji,
                listeners: {
                    scope: this,
                    select: this.checkIdentChange
                }
            }, {
                id: this.cid + "numberofdigits",
                name: "numberofdigits",
                xtype: 'numberfield',
                fieldLabel: Lang.ilosc_znakow_w_kodzie_recznym,
                allowBlank: false,
                maxLength: 200,
                blankText: Lang.pole_nie_moze_byc_puste,
                value: 8,
                maxValue: 8,
                minValue: 4
            }, {
                xtype: 'radiogroupexd',
                id: this.cid + "ident_pojqq",
                name: "ident_pojqq",
                fieldLabel: Lang.pytanie_o_nazwe,
                columns: 2,
                vertical: false,
                items: [{
                    boxLabel: Lang.tak,
                    inputValue: true
                }, {
                    boxLabel: Lang.nie,
                    inputValue: false,
                    value: true
                }],
                hidden: Const.STARTERFLAG
            }, {
                id: this.cid + 'ident_operator',
                xtype: 'comboexd',
                width: '95%',
                store: [[Const.IDENT_ELEKTRONICZNA, Lang.elektroniczna], [Const.IDENT_ELEKTRONICZNAPIN, Lang.elektroniczna_pin], [Const.IDENT_KODRECZNY, Lang.kodem_recznym]],
                triggerAction: 'all',
                resizable: false,
                mode: "local",
                editable: false,
                fieldLabel: Lang.identyfikacja_operatora,
                hidden: !Const.ENTERPRISEFLAG
            }, {
                id: this.cid + 'ident_odbiorca',
                xtype: 'comboexd',
                width: '95%',
                store: [[Const.IDENT_ELEKTRONICZNA, Lang.elektroniczna], [Const.IDENT_ELEKTRONICZNAPIN, Lang.elektroniczna_pin], [Const.IDENT_KODRECZNY, Lang.kodem_recznym]],
                triggerAction: 'all',
                resizable: false,
                mode: "local",
                editable: false,
                fieldLabel: Lang.identyfikacja_odbiorcy,
                hidden: Const.STARTERFLAG
            }, {
                id: this.cid + 'ident_pojazd',
                xtype: 'comboexd',
                width: '95%',
                store: [[Const.IDENT_ELEKTRONICZNA, Lang.elektroniczna], [Const.IDENT_ELEKTRONICZNAPIN, Lang.elektroniczna_pin], [Const.IDENT_KODRECZNY, Lang.kodem_recznym]],
                triggerAction: 'all',
                resizable: false,
                mode: "local",
                editable: false,
                fieldLabel: Lang.identyfikacja_pojazdu
            }, {
                xtype: 'radiogroupexd',
                id: this.cid + "tankowanieoff",
                name: "tankowanieoff",
                fieldLabel: Lang.tankowanie,
                columns: 2,
                vertical: false,
                items: [{
                    boxLabel: Lang.wlaczone.capitalise(),
                    inputValue: false
                }, {
                    boxLabel: Lang.wylaczone.capitalise(),
                    inputValue: true,
                    value: true
                }]
            }]
        };
        let formitems = [{
            xtype: 'radiogroupexd',
            id: this.cid + "mustsetlicznikstate",
            name: "mustsetlicznikstate",
            fieldLabel: Lang.wymagalnosc_stanu_licznika,
            columns: 2,
            vertical: false,
            items: [{
                boxLabel: Lang.tak,
                inputValue: true
            }, {
                boxLabel: Lang.nie,
                inputValue: false,
                value: true
            }],
            listeners: {
                scope: this,
                change: function (radiogroup, newValue) {
                    var errorlicznikcondition = Ext.getCmp(this.cid + "errorlicznikcondition");
                    if (!newValue) {
                        errorlicznikcondition.setValue(false);
                    }
                    errorlicznikcondition.setDisabled(!newValue);
                }
            },
            hidden: Const.STARTERFLAG
        }, {
            xtype: 'radiogroupexd',
            id: this.cid + "askforvolumetank",
            name: "askforvolumetank",
            fieldLabel: Lang.pytanie_o_ilosc_dozatankowania,
            columns: 2,
            vertical: false,
            hidden: (!Const.OPTIMALFLAG && !Const.BASICFLAG && !Const.STANDARDFLAG) || Const.STARTERFLAG,
            items: [{
                boxLabel: Lang.tak,
                inputValue: true
            }, {
                boxLabel: Lang.nie,
                inputValue: false,
                value: true
            }]
        }, {
            xtype: 'radiogroupexd',
            id: this.cid + "mustsetlicznikstatecontrol",
            name: "mustsetlicznikstatecontrol",
            fieldLabel: Lang.kontrola_stanu_licznika,
            columns: 2,
            vertical: false,
            hidden: Const.OPTIMALFLAG || Const.SKPFLAG || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
            items: [{
                boxLabel: Lang.tak,
                inputValue: true
            }, {
                boxLabel: Lang.nie,
                inputValue: false,
                value: true
            }]
        }, {
            id: this.cid + "errorlicznikcondition",
            name: "errorlicznikcondition",
            xtype: 'radiogroupexd',
            fieldLabel: Lang.warunek_blednego_stanu_licznika,
            columns: 2,
            vertical: false,
            hidden: Const.BASICFLAG || Const.OPTIMALFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
            items: [{
                boxLabel: Lang.blokada.capitalise(),
                inputValue: true,
                value: true
            }, {
                boxLabel: Lang.informacja.capitalise(),
                inputValue: false
            }]
        }, {
            xtype: 'radiogroupexd',
            id: this.id + "controlmileagediff",
            name: "controlmileagediff",
            fieldLabel: Lang.kontrola_roznicy_przebiegu,
            columns: 2,
            vertical: false,
            hidden: Const.BASICFLAG || Const.OPTIMALFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
            items: [{
                boxLabel: Lang.tak.capitalise(),
                inputValue: true,
                value: true
            }, {
                boxLabel: Lang.nie.capitalise(),
                inputValue: false
            }],
            listeners: {
                change: function (radiogroup, newValue) {
                    Ext.getCmp(this.id + "maxmileagediffkm").setDisabled(!newValue);
                    Ext.getCmp(this.id + "maxmileagediffrg").setDisabled(!newValue);
                },
                scope: this
            }
        }, {
            id: this.id + "maxmileagediffkm",
            name: "maxmileagediffkm",
            xtype: 'numberfieldexd',
            fieldLabel: Lang.maksymalna_roznica_przebiegu_km,
            allowBlank: false,
            disabled: true,
            decimalPrecision: 2,
            minValue: 0,
            width: '95%',
            cls: "x-item-disabled-sec",
            hidden: Const.BASICFLAG || Const.OPTIMALFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
        }, {
            id: this.id + "maxmileagediffrg",
            name: "maxmileagediffrg",
            xtype: 'numberfieldexd',
            fieldLabel: Lang.maksymalna_roznica_przebiegu_rg,
            allowBlank: false,
            disabled: true,
            decimalPrecision: 2,
            minValue: 0,
            width: '95%',
            cls: "x-item-disabled-sec",
            hidden: Const.BASICFLAG || Const.OPTIMALFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
        }];
        let opis = {
            labelStyle: "margin-top:10px",
            style: "margin-top:10px; padding-right: 10px",
            fieldLabel: Lang.opis,
            id: this.cid + "opis",
            name: "opis",
            //width : '95%',
            anchor: '0',
            height: 150,
            xtype: 'textareaexd'
        };
        if (WindowExdMobileDEVICEV) {
            this.items = [{
                xtype: 'panel',
                layout: 'column',
                scrollable: true,
                items: [formpanel]
            }];
            formpanel.items.push(...formitems, opis);
        } else {
            this.items = [{
                xtype: 'panel',
                layout: 'column',
                scrollable: true,
                items: [formpanel, {
                    columnWidth: 0.5,
                    xtype: 'form',
                    id: this.cid + 'formpanel2',
                    bodyStyle: 'padding:10px 10px 10px 10px',
                    scrollable: true,
                    defaults: {
                        labelWidth: 150
                    },
                    items: [...formitems]
                }]
            }, {
                xtype: 'form',
                id: this.cid + 'formpanel3',
                bodyStyle: 'padding:10px 10px 10px 10px',
                scrollable: true,
                defaults: {
                    anchor: '0',
                    labelWidth: 150
                },
                items: [opis]
            }];
        }
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            disabled: !validPrivilegesOperatorComplex("access.manage.syskonf"),
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function () {
        sendAjaxDataJsonRpcL("rest/firmy/getObjectForEdit", {
            id: 0,
            getfirstdefault: true
        }, this, function (data) {
            if (data.status) {
                data = data.data;
                this.obiekt = data;
                this.show();
                this.center();
                this.setKeyMap({
                    'ESC': {
                        scope: this,
                        handler: this.closeOp
                    }
                });
                Ext.getCmp(this.cid + "opis").setValue(null);
                Ext.getCmp(this.cid + "opis").clearInvalid();
                Ext.getCmp(this.id + "maxmileagediffkm").setValue(null);
                Ext.getCmp(this.id + "maxmileagediffrg").setValue(null);
                Ext.getCmp(this.id + "controlmileagediff").setValue2(false);
                if (this.obiekt != null) {
                    this.obiektid = this.obiekt.id;
                    Ext.getCmp(this.cid + "opis").setValue(this.obiekt.opis);
                    Ext.getCmp(this.cid + "ident_tryb").setValue(this.obiekt.ident_tryb);
                    Ext.getCmp(this.cid + "numberofdigits").setValue(this.obiekt.numberofdigits);
                    Ext.getCmp(this.cid + "ident_operator").setValue(this.obiekt.ident_operator);
                    Ext.getCmp(this.cid + "ident_odbiorca").setValue(this.obiekt.ident_odbiorca);
                    Ext.getCmp(this.cid + "ident_pojazd").setValue(this.obiekt.ident_pojazd);
                    Ext.getCmp(this.cid + "ident_pojqq").setValue2(this.obiekt.ident_pojqq);
                    Ext.getCmp(this.cid + "tankowanieoff").setValue2(data.tankowanieoff);
                    Ext.getCmp(this.cid + "askforvolumetank").setValue2(data.askforvolumetank);
                    Ext.getCmp(this.cid + "mustsetlicznikstate").setValue2(data.mustsetlicznikstate);
                    Ext.getCmp(this.cid + "mustsetlicznikstatecontrol").setValue2(data.mustsetlicznikstatecontrol);
                    Ext.getCmp(this.cid + "errorlicznikcondition").setValue2(data.errorlicznikcondition);
                    if (data.maxmileagediffkm > 0 && data.maxmileagediffrg > 0) {
                        Ext.getCmp(this.id + "maxmileagediffkm").setValue(data.maxmileagediffkm);
                        Ext.getCmp(this.id + "maxmileagediffrg").setValue(data.maxmileagediffrg);
                        Ext.getCmp(this.id + "controlmileagediff").setValue2(true);
                    }
                } else {
                    Ext.getCmp(this.cid + "mustsetlicznikstate").setValue2(false);
                    Ext.getCmp(this.cid + "mustsetlicznikstate").clearInvalid();
                    Ext.getCmp(this.cid + "mustsetlicznikstatecontrol").setValue2(false);
                    Ext.getCmp(this.cid + "mustsetlicznikstatecontrol").clearInvalid();
                    Ext.getCmp(this.cid + "errorlicznikcondition").setValue2(null);
                    Ext.getCmp(this.cid + "errorlicznikcondition").clearInvalid();
                    Ext.getCmp(this.cid + "tankowanieoff").setValue2(false);
                    Ext.getCmp(this.cid + "tankowanieoff").clearInvalid();
                    Ext.getCmp(this.cid + "askforvolumetank").setValue2(true);
                    Ext.getCmp(this.cid + "askforvolumetank").clearInvalid();
                    Ext.getCmp(this.cid + "ident_tryb").setValue(Const.STARTERFLAG ? Const.IDENT_POJEDYNCZA : Const.IDENT_PODWOJNA);
                    Ext.getCmp(this.cid + "ident_operator").setValue(Const.IDENT_ELEKTRONICZNA);
                    Ext.getCmp(this.cid + "ident_odbiorca").setValue(Const.IDENT_ELEKTRONICZNA);
                    Ext.getCmp(this.cid + "ident_pojazd").setValue(Const.IDENT_ELEKTRONICZNA);
                    Ext.getCmp(this.cid + "ident_pojqq").setValue2(false);
                }
                this.checkIdentChange();
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    saveOp: function () {
        if (!Ext.getCmp(this.cid + 'formpanel').form.isValid() || !Ext.getCmp(this.cid + 'formpanel2').form.isValid() || !Ext.getCmp(this.cid + 'formpanel3').form.isValid()) {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
            return;
        }
        var controlMileageDiff = Ext.getCmp(this.id + "controlmileagediff").getValue2();
        sendAjaxDataJsonRpcL("rest/firmy/saveObject", {
            id: this.obiektid,
            nazwa: this.obiekt == null ? "DEFAULT" : this.obiekt.nazwa,
            opis: Ext.getCmp(this.cid + "opis").getValue(),
            active: true,
            ident_tryb: Ext.getCmp(this.cid + "ident_tryb").getValue(),
            ident_operator: Ext.getCmp(this.cid + "ident_operator").getValue(),
            ident_odbiorca: Ext.getCmp(this.cid + "ident_odbiorca").getValue(),
            ident_pojazd: Ext.getCmp(this.cid + "ident_pojazd").getValue(),
            ident_pojqq: Ext.getCmp(this.cid + "ident_pojqq").getValue2(),
            tankowanieoff: Ext.getCmp(this.cid + "tankowanieoff").getValue2(),
            askforvolumetank: Ext.getCmp(this.cid + "askforvolumetank").getValue2(),
            mustsetlicznikstate: Ext.getCmp(this.cid + "mustsetlicznikstate").getValue2(),
            mustsetlicznikstatecontrol: Ext.getCmp(this.cid + "mustsetlicznikstatecontrol").getValue2(),
            errorlicznikcondition: Ext.getCmp(this.cid + "errorlicznikcondition").getValue2(),
            maxmileagediffkm: controlMileageDiff ? Ext.getCmp(this.id + "maxmileagediffkm").getValue() : 0,
            maxmileagediffrg: controlMileageDiff ? Ext.getCmp(this.id + "maxmileagediffrg").getValue() : 0,
            optlockversion: this.obiekt == null ? null : this.obiekt.optlockversion,
            numberofdigits: Ext.getCmp(this.cid + "numberofdigits").getValue(),
            data: {
                limitydatas: []
            }
        }, this, function (data) {
            if (data.status) {
                data = data.data;
                this.obiektid = data.id;
                this.hide();
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
                return;
            }
            if (this.callbackF) {
                this.callbackF();
            }
        }, 0);
    },
    closeOp: function () {
        this.close();
    }
});
