Ext.define('ViewSterowniki', {
    extend: 'Ext.panel.Panel',
    grid: null,
    windowObject: null,
    id: "sterowniki",
    taskContextMenu: null,
    rowContext: null,
    rebuildGrid: false,
    showState: false,
    statics: {
        accessPropertyName: "access.manage.sterowniki",
        accessPropertyViewName: "access.view.sterowniki"
    },
    cat: "k",
    text: Lang.sterowniki,
    buttonIconCls: "iconSterownik",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.buildGrid();
        this.items = [{
            region: "north",
            xtype: 'toolbar',
            items: [{
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconNew",
                text: Lang.nowy.capitalise(),
                handler: this.dodajObiekt,
                scope: this,
                disabled: !validPrivilegesOperatorComplex(ViewSterowniki.accessPropertyName + "/add"),
                hidden: Const.CLOUDFLAG
            }, {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconNew",
                text: Lang.rejestruj.capitalise(),
                handler: this.rejestrujObiekt,
                scope: this,
                disabled: !validPrivilegesOperatorComplex(ViewSterowniki.accessPropertyName + "/add"),
                hidden: !Const.CLOUDFLAG || Const.AGGREGATEDCORE
            }, {
                id: this.id + "bedit3",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconNew",
                text: Lang.klonowanie.capitalise(),
                hidden: Const.CLOUDFLAG,
                scope: this,
                handler: this.edytujObiekt2,
                disabled: !validPrivilegesOperatorComplex(ViewSterowniki.accessPropertyName + "/add")
            }, {
                id: this.id + "bedit",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconEdit",
                text: Lang.edytuj.capitalise(),
                scope: this,
                handler: this.edytujObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewSterowniki.accessPropertyName + "/update"),
                hidden: Const.AGGREGATEDCORE
            }, {
                id: this.id + "bdelete",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconDelete",
                text: Lang.usun.capitalise(),
                scope: this,
                handler: this.usunObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewSterowniki.accessPropertyName + "/delete"),
                hidden: Const.AGGREGATEDCORE
            }, {
                id: this.id + "beditflash",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconProgramowanieFlash",
                text: Lang.programowanie_flash.capitalise(),
                scope: this,
                handler: this.flashObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewSterowniki.accessPropertyName + "/update"),
                hidden: !validPrivilegesOperatorComplex('access.view.flash') && !(Const.AGGREGATEDCORE && AppOPERATOR.id == 1)
            }, "-", {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconPDF",
                text: Lang.pdf.capitalise(),
                scope: this,
                handler: this.getDownloadLsPdf,
                hidden: Const.AGGREGATEDCORE
            }, {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconEXCEL",
                text: Lang.excel.capitalise(),
                scope: this,
                handler: this.getDownloadLsExcel,
                hidden: Const.AGGREGATEDCORE
            }, "-", {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconRefresh",
                text: Lang.odswiez.capitalise(),
                scope: this,
                handler: this.refreshDatastore
            }, {
                xtype: "box",
                id: this.id + "kontekstfirmybox",
                cls: "kontekstfirmy-box"
            }, {
                id: this.id + "kontekstfirmy",
                xtype: 'label',
                style: 'display:block;margin-right:5px',
                cls: "kontekstfirmy-text"
            }]
        }, {
            region: "center",
            xtype: 'panel',
            frame: false,
            border: false,
            layout: 'fit',
            items: [this.grid]
        }];
        this.callParent(arguments);
    },
    showFirst: function (eventMain) {
        eventMain.on("firmaChangeContext", this.changeContext, this);
    },
    remove: function () {
        this.callParent(arguments);
    },
    show: function () {
        this.showState = true;
        this.callParent(arguments);
        this.changeContextRebuild(null, null);
        this.refreshDatastore();
    },
    hide: function () {
        this.showState = false;
        this.callParent(arguments);
    },
    onDestroy: function () {
        this.taskContextMenu.destroy();
        this.callParent(arguments);
    },
    changeContextRebuild: function (eAppFIRMACTX, eAppFIRMACTXold) {
        var kontekstfirmy = Ext.getCmp(this.id + "kontekstfirmy");
        var kontekstfirmybox = Ext.getCmp(this.id + "kontekstfirmybox");
        if (AppFIRMACTX != null && !Const.AGGREGATEDCORE) {
            kontekstfirmybox.update("<div class='appSetColourRedSplit'></div>");
            kontekstfirmy.setText(Lang.pracujesz_w_kontekscie + AppFIRMACTX.nazwa);
        } else {
            kontekstfirmybox.update("");
            kontekstfirmy.setText("");
        }
        if (this.rebuildGrid) {
            this.rebuildGrid = false;
            var onwer = this.grid.ownerCt;
            Ext.suspendLayouts();
            onwer.remove(this.grid);
            this.grid.destroy();
            this.taskContextMenu.destroy();
            this.buildGrid();
            onwer.add(this.grid);
            Ext.resumeLayouts(true);
        }
    },
    changeContext: function (eAppFIRMACTX, eAppFIRMACTXold) {
        this.rebuildGrid = true;
        if (this.showState) {
            this.changeContextRebuild(null, null);
            this.refreshDatastore();
        }
    },
    buildGrid: function (datals) {
        var cols = [{
            header: "ID",
            width: 145,
            sortable: true,
            dataIndex: 'id',
            filter: {
                type: 'numeric'
            },
            filterable: true,
            hidden: true
        }, {
            header: Lang.numer_seryjny,
            flex: 2,
            sortable: true,
            dataIndex: 'serialnumber',
            filter: {
                type: 'string'
            },
            sorter: Ext.bind(customSorterForGrid, this, ["serialnumber"], true),
            filterable: true
        }, {
            header: Lang.firma,
            flex: 2,
            sortable: true,
            dataIndex: 'firma',
            filter: {
                type: 'string'
            },
            sorter: Ext.bind(customSorterForGrid, this, ["firma"], true),
            filterable: true,
            hidden: !Const.AGGREGATEDCORE
        }, {
            header: Lang.nazwa,
            flex: 2,
            sortable: true,
            dataIndex: 'nazwadsp',
            filter: {
                type: 'string'
            },
            sorter: Ext.bind(customSorterForGrid, this, ["nazwadsp"], true),
            filterable: true
        }, {
            header: Lang.opis,
            flex: 2,
            sortable: true,
            dataIndex: 'opis',
            filter: {
                type: 'string'
            },
            sorter: Ext.bind(customSorterForGrid, this, ["opis"], true),
            filterable: true,
            hidden: Ext.getViewportWidth() <= 1366
        }, {
            header: Lang.wersja,
            flex: 2,
            sortable: true,
            dataIndex: 'version',
            filter: {
                type: 'string'
            },
            filterable: true
        }, {
            header: Lang.stan,
            flex: 3,
            sortable: true,
            dataIndex: 'stateopis',
            filter: {
                type: 'string'
            },
            sorter: Ext.bind(customSorterForGrid, this, ["stateopis"], true),
            filterable: true
        }, {
            header: Lang.komunikacja,
            flex: 2,
            sortable: true,
            dataIndex: 'iscommunicationok',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            },
            filterable: true
        }, {
            header: Lang.typ,
            flex: 2,
            sortable: true,
            dataIndex: 'tconnect',
            renderer: function (v) {
                return LangComp.typy_podlaczen[v];
            },
            filterable: true,
            filter: {
                type: 'list',
                options: [[Const.STEROWNIK_CONNECT_NO, Lang.niepodlaczony], [Const.STEROWNIK_CONNECT_USB, Lang.podlaczony_usb], [Const.STEROWNIK_CONNECT_GSM, Lang.podlaczony_gsm], [Const.STEROWNIK_CONNECT_WIFI, Lang.podlaczony_wifi]]
            },
            hidden: Ext.getViewportWidth() <= 1366
        }, {
            header2: Lang.active,
            menuText: Lang.active,
            header: Ext.DomHelper.markup({
                tag: "div",
                cls: "iconDivCol iconActive",
                cn: [{
                    tag: "span",
                    html: "&nbsp;"
                }, {
                    tag: "div",
                    html: Lang.active
                }]
            }),
            flex: 1,
            sortable: true,
            dataIndex: 'active',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            },
            filterable: true
        }, {
            header: Lang.localization,
            flex: 2,
            sortable: true,
            dataIndex: 'localization',
            filter: {
                type: 'string'
            },
            sorter: Ext.bind(customSorterForGrid, this, ["localization"], true),
            filterable: true,
            hidden: true
        }, {
            header: Lang.sim_quota,
            flex: 2,
            sortable: true,
            dataIndex: 'simquota',
            filter: {
                type: 'numeric'
            },
            renderer: function (v) {
                if (v == null)
                    return null;
                return Ext.Number.roundToPrecision(v, 2).toString().replace(".", Ext.DECIMAL_SEPARATOR);
            },
            filterable: true,
            hidden: !Const.ENABLECUSTOMVENDOR
        }]
        if (!Const.AGGREGATEDCORE) {
            removeObjectFromArrayById(cols, "firma");
        }
        if (!Const.ENABLEGIRTEKA) {
            removeObjectFromArrayById(cols, "localization");
        }
        if (!Const.ENABLECUSTOMVENDOR) {
            removeObjectFromArrayById(cols, "simquota");
        }
        this.grid = new Ext.ux.grid.GridPanelExd({
            features: [{
                ftype: 'grouping'
            }],
            plugins: {
                gridfilters: true
            },
            store: new Ext.data.Store({
                proxy: new Ext.data.MemoryProxy(),
                autoLoad: true,
                groupField: 'tconnect',
                sorters: [{
                    property: 'nazwa',
                    direction: "ASC"
                }],
                model: Ext.define(null, {
                    extend: 'Ext.data.Model',
                    idProperty: 'id',
                    fields: [{
                        name: 'id',
                        mapping: 'id'
                    }, {
                        name: 'serialnumber',
                        mapping: 'serialnumber'
                    }, {
                        name: 'firma',
                        mapping: 'firma'
                    }, {
                        name: 'nazwadsp',
                        mapping: 'nazwadsp'
                    }, {
                        name: 'opis',
                        mapping: 'opis'
                    }, {
                        name: 'version',
                        mapping: 'version'
                    }, {
                        name: 'devid',
                        mapping: 'devid'
                    }, {
                        name: 'active',
                        mapping: 'active'
                    }, {
                        name: 'tconnect',
                        mapping: 'tconnect'
                    }, {
                        name: 'iscommunicationok',
                        mapping: 'iscommunicationok'
                    }, {
                        name: 'stateopis',
                        mapping: 'stateopis'
                    }, {
                        name: 'localization',
                        mapping: 'localization'
                    }, {
                        name: 'simquota',
                        mapping: 'simquota'
                    }]
                }),
                reader: new Ext.data.ArrayReader()
            }),
            columns: cols,
            frame: false,
            border: false,
            stateId: "grid-" + this.id + (AppFIRMACTX == null ? "-all" : "-"),
            stateful: true,
            stripeRows: true,
            enableHdMenu: true,
            selModel: {
                selType: 'rowmodel',
                mode: 'single',
                allowDeselect: false
            }
        });
        this.grid.addListener("rowdblclick", function () {
            if (Const.AGGREGATEDCORE) {
                return;
            }
            if (validPrivilegesOperatorComplex(ViewSterowniki.accessPropertyName + "/update"))
                this.edytujObiekt();
        }, this);
        this.grid.addListener("render", function () {
            this.manageSelect(this.grid.getSelectionModel());
        }, this);
        this.grid.addListener("select", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.grid.addListener("selectionchange", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.taskContextMenu = new Ext.menu.Menu();
        this.taskContextMenu.add([{
            iconAlign: 'left',
            iconCls: "iconNew",
            text: Lang.nowy.capitalise(),
            scope: this,
            handler: this.dodajObiekt,
            disabled: !validPrivilegesOperatorComplex(ViewSterowniki.accessPropertyName + "/add"),
            hidden: Const.AGGREGATEDCORE
        }, {
            id: this.id + "bedit4",
            iconAlign: 'left',
            iconCls: "iconNew",
            text: Lang.klonowanie.capitalise(),
            hidden: Const.CLOUDFLAG,
            scope: this,
            handler: this.edytujObiekt2,
            disabled: !validPrivilegesOperatorComplex(ViewSterowniki.accessPropertyName + "/add")
        }, {
            id: this.id + "bedit2",
            iconAlign: 'left',
            iconCls: "iconEdit",
            text: Lang.edytuj.capitalise(),
            scope: this,
            handler: this.edytujObiekt,
            disabled: !validPrivilegesOperatorComplex(ViewSterowniki.accessPropertyName + "/update"),
            hidden: Const.AGGREGATEDCORE
        }, {
            id: this.id + "bdelete2",
            iconAlign: 'left',
            iconCls: "iconDelete",
            text: Lang.usun.capitalise(),
            scope: this,
            handler: this.usunObiekt,
            disabled: !validPrivilegesOperatorComplex(ViewSterowniki.accessPropertyName + "/delete"),
            hidden: Const.AGGREGATEDCORE
        }, "-", {
            iconAlign: 'left',
            iconCls: "iconPDF",
            text: Lang.pdf.capitalise(),
            scope: this,
            handler: this.getDownloadLsPdf,
            hidden: Const.AGGREGATEDCORE
        }, {
            iconAlign: 'left',
            iconCls: "iconEXCEL",
            text: Lang.excel.capitalise(),
            scope: this,
            hidden: Const.AGGREGATEDCORE,
            handler: this.getDownloadLsExcel
        }, "-", {
            iconAlign: 'left',
            iconCls: "iconRefresh",
            text: Lang.odswiez.capitalise(),
            scope: this,
            handler: this.refreshDatastore
        }]);
        this.grid.addListener("rowcontextmenu", function (grid, record, tr, rowIndex, e) {
            this.rowContext = grid.getStore().getAt(rowIndex);
            this.manageSelect();
            this.taskContextMenu.showAt(e.getXY());
            e.preventDefault();
        }, this);
        this.taskContextMenu.addListener("hide", function () {
            this.rowContext = null;
        }, this);
        this.grid.addListener("containercontextmenu", function (grid, e) {
            this.rowContext = null;
            this.manageSelect();
            this.taskContextMenu.showAt(e.getXY());
            e.preventDefault();
        }, this);
    },
    manageSelect: function () {
        var bedit = Ext.getCmp(this.id + "bedit");
        var bedit3 = Ext.getCmp(this.id + "bedit3");
        var bdelete = Ext.getCmp(this.id + "bdelete");
        var bedit2 = Ext.getCmp(this.id + "bedit2");
        var bedit4 = Ext.getCmp(this.id + "bedit4");
        var bdelete2 = Ext.getCmp(this.id + "bdelete2");
        if (!this.grid.getSelectionModel().hasSelection() || this.grid.getSelectionModel().getSelection() == null || this.grid.getSelectionModel().getSelection().length == 0) {
            bdelete.setDisabled(true);
            bedit.setDisabled(true);
            bedit3.setDisabled(true);
        } else {
            var row = this.grid.getSelectionModel().getSelection()[0];
            bdelete.setDisabled(!validPrivilegesOperatorComplex(ViewSterowniki.accessPropertyName + "/delete"));
            bedit.setDisabled(!validPrivilegesOperatorComplex(ViewSterowniki.accessPropertyName + "/update"));
            bedit3.setDisabled(!validPrivilegesOperatorComplex(ViewSterowniki.accessPropertyName + "/add"));
        }
        if (this.rowContext == null) {
            bdelete2.setDisabled(true);
            bedit2.setDisabled(true);
            bedit4.setDisabled(true);
        } else {
            bdelete2.setDisabled(!validPrivilegesOperatorComplex(ViewSterowniki.accessPropertyName + "/delete"));
            bedit2.setDisabled(!validPrivilegesOperatorComplex(ViewSterowniki.accessPropertyName + "/update"));
            bedit4.setDisabled(!validPrivilegesOperatorComplex(ViewSterowniki.accessPropertyName + "/add"));
        }
    },
    getDownloadLsPdf: function () {
        postFormAsync("rest/pdfexcelexport/downloadExport", {
            format: "pdf",
            name: Lang.sterowniki,
            defd: Ext.encode(this.grid.getDefGridDataCSF({
                colsEx: [],
                colsIn: [],
                useRenderer: true,
                fnd: function (v, c, metaData, record, rowIndex, colIndex, store) {
                    if (c == "active" || c == "iscommunicationok") {
                        if (v == 'true')
                            v = Lang.tak;
                        else
                            v = Lang.nie;
                    }
                    return v;
                }
            }))
        }, null);
    },
    getDownloadLsExcel: function () {
        postFormAsync("rest/pdfexcelexport/downloadExport", {
            format: "excel",
            name: Lang.sterowniki,
            defd: Ext.encode(this.grid.getDefGridDataCSF({
                colsEx: [],
                colsIn: [],
                useRenderer: true,
                fnd: function (v, c, metaData, record, rowIndex, colIndex, store) {
                    if (c == "active" || c == "iscommunicationok") {
                        if (v == 'true')
                            v = Lang.tak;
                        else
                            v = Lang.nie;
                    }
                    return v;
                }
            }))
        }, null);
    },
    refreshDatastore: function () {
        updateCzasRefreshSerwer();
        if (this.grid && this.grid.rendered)
            this.grid.mask();
        sendAjaxDataJsonRpcL("rest/sterowniki/getList", null, this, function (data) {
            if (this.grid && this.grid.rendered)
                this.grid.unmask();
            if (data.status) {
                this.grid.store.loadData(data.data);
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    dodajObiekt: function () {
        if (Const.OPTIMALFLAG && !Const.AGGREGATEDCORE) {
            this.windowObject = Ext.create('WindowSterownikiOpt', {
                callbackF: Ext.Function.bind(this.refreshDatastore, this)
            });
        } else {
            this.windowObject = Ext.create('WindowSterowniki', {
                callbackF: Ext.Function.bind(this.refreshDatastore, this)
            });
        }
        this.windowObject.showW(0);
    },
    rejestrujObiekt: function () {
        this.windowObject = Ext.create('WindowRejestrujSterowniki', {
            callbackF: Ext.Function.bind(this.refreshDatastore, this)
        });
        this.windowObject.show();
    },
    flashObiekt: function () {
        windowObjectFlash = Ext.create('WindowCfgProgramFlash', {
            typeo: "sterownik",
            addwarninfo: Lang.wgranie_do_kdp_warning_message
        });
        windowObjectFlash.showW();
        windowObjectFlash.on("close", this.refreshDatastore, this);
    },
    edytujObiekt: function () {
        var id = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        if (Const.OPTIMALFLAG && !Const.AGGREGATEDCORE) {
            this.windowObject = Ext.create('WindowSterownikiOpt', {
                callbackF: Ext.Function.bind(this.refreshDatastore, this)
            });
        } else {
            this.windowObject = Ext.create('WindowSterowniki', {
                callbackF: Ext.Function.bind(this.refreshDatastore, this)
            });
        }
        this.windowObject.showW(id);
    },
    edytujObiekt2: function () {
        var id = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        if (Const.OPTIMALFLAG && !Const.AGGREGATEDCORE) {
            this.windowObject = Ext.create('WindowSterownikiOpt', {
                callbackF: Ext.Function.bind(this.refreshDatastore, this)
            });
        } else {
            this.windowObject = Ext.create('WindowSterowniki', {
                callbackF: Ext.Function.bind(this.refreshDatastore, this)
            });
        }
        this.windowObject.showW(id, true);
    },
    usunObiekt: function () {
        var id = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        Ext.MessageBox.show({
            title: Lang.usuwanie,
            msg: Lang.czy_napewno_usunac_obiekt,
            buttons: Ext.MessageBox.YESNO,
            scope: this,
            fn: function (btn) {
                if (btn == "yes") {
                    sendAjaxDataJsonRpcL("rest/sterowniki/deleteObject", {
                        id: id
                    }, this, function (data) {
                        if (data.status) {
                        } else {
                            Ext.MessageBox.alert(Lang.error, data.error);
                        }
                        this.refreshDatastore();
                    });
                }
            }
        });
    }
});
