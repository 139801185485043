Ext.define('WindowReczne', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.tankowanie_reczne.capitalise(),
    width: 580,
    height: 450,
    closable: false,
    collapsible: false,
    modal: true,
    scrollable: true,
    resizable: false,
    layout: 'fit',
    initComponent: function (config) {
        this.cid = Ext.id();
        let formpanel = {
            id: this.cid + "formpanel",
            xtype: 'form',
            frame: true,
            border: false,
            autoWidth: true,
            autoHeight: true,
            scrollable: WindowExdMobileDEVICEV,
            bodyStyle: 'padding:10px 10px 10px 10px',
            defaults: {
                anchor: "0"
            },
            fileUpload: true,
            items: [{
                anchor: "0",
                layout: 'column',
                frame: false,
                border: false,
                items: [{
                    columnWidth: WindowExdMobileDEVICEV ? 1 : .5,
                    frame: false,
                    border: false,
                    layout: 'anchor',
                    style: 'padding-left: 10px; padding-top: 10px;',
                    defaults: {
                        anchor: "0",
                        labelWidth: 120,
                        labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                    },
                    items: [{
                        id: this.cid + "kod",
                        name: "kod",
                        xtype: 'textfieldexd',
                        fieldLabel: Lang.kod,
                        allowBlank: false,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        regex: new RegExp("^[0-9]{6,6}$"),
                        regexText: Lang.kod_reczny_musi_miec_n_cyfr,
                        cls: "x-item-disabled-sec"
                    }, {
                        id: this.cid + 'idsterownik',
                        xtype: 'comboexd',
                        valueField: 'id',
                        displayField: 'nazwadsp',
                        store: new Ext.data.JsonStore({
                            id: "id",
                            rootProperty: '',
                            fields: [{
                                name: 'id',
                                mapping: 'id'
                            }, {
                                name: 'nazwadsp',
                                mapping: 'nazwadsp'
                            }]
                        }),
                        triggerAction: 'all',
                        resizable: false,
                        editable: false,
                        mode: "local",
                        fieldLabel: Lang.sterownik,
                        allowBlank: false,
                        blankText: Lang.pole_nie_moze_byc_puste
                    }, {
                        id: this.cid + 'idplyn',
                        name: "idplyn",
                        xtype: 'comboexd',
                        triggerAction: 'all',
                        resizable: false,
                        mode: "local",
                        editable: false,
                        valueField: 'id',
                        displayField: 'nazwa',
                        store: new Ext.data.JsonStore({
                            id: "id",
                            rootProperty: '',
                            fields: [{
                                name: 'id',
                                mapping: 'id'
                            }, {
                                name: 'nazwa',
                                mapping: 'nazwa'
                            }]
                        }),
                        allowBlank: false,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        fieldLabel: Lang.plyn
                    }, {
                        id: this.cid + "vlimit",
                        name: "vlimit",
                        xtype: 'numberfieldexd',
                        fieldLabel: Lang.limit + Lang.jednostka_text,
                        allowBlank: false,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        minValue: 0,
                        maxValue: 9999999
                    }]
                }]
            }]
        };
        let waznosc = {
            id: this.cid + "waznosc",
            name: "waznosc",
            xtype: 'datefieldexdmod',
            fieldLabel: Lang.waznosc,
            allowBlank: false,
            blankText: Lang.pole_nie_moze_byc_puste
        };
        let opis = {
            id: this.cid + "opis",
            name: "opis",
            xtype: 'textareaexd',
            labelStyle: 'width: 115px !important;',
            fieldLabel: Lang.opis
        };
        if (WindowExdMobileDEVICEV)
            formpanel.items[0].items[0].items.push(waznosc, opis);
        else {
            formpanel.items[0].items.push({
                columnWidth: .5,
                layout: 'form',
                frame: false,
                border: false,
                bodyStyle: 'padding-left:10px;',
                defaults: {
                    anchor: "0"
                },
                items: [waznosc]
            });
            formpanel.items.push({
                anchor: "0",
                layout: 'form',
                frame: false,
                border: false,
                labelWidth: 120,
                items: [opis]
            })
        }
        formpanel.items.push({
            xtype: 'label',
            style: 'display:block;margin-top:10px;',
            text: Lang.uzycie_kodu_na_kdp
        })
        this.items = [{
            xtype: 'panel',
            layout: 'fit',
            items: [formpanel]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid) {
        if (!validPrivilegesOperatorComplex("access.manage.treczne")) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.obiektid = obiektid;
        this.obiekt = null;
        sendAjaxDataJsonRpcLGroup(["rest/reczne/getObjectForEdit", "rest/systemkonf/obtainAutoGeneratedTankowanieKodRecznyAwaryjne"], [{
            id: this.obiektid
        }, null], this, function (gstatus, data, datad, status) {
            if (gstatus) {
                this.obiekt = datad[0];
                this.kodGen = datad[1];
                sendAjaxDataJsonRpcLGroup(["rest/sterowniki/getList", "rest/plyny/getShortList"], [{
                    id: this.obiektid
                }, null], this, function (gstatus, data, datad, status) {
                    if (gstatus) {
                        if (datad[0]) {
                            this.show();
                            this.center();
                            this.setKeyMap({
                                'ESC': {
                                    scope: this,
                                    handler: this.closeOp
                                }
                            });
                            Ext.getCmp(this.cid + 'idsterownik').store.loadData(datad[0]);
                            Ext.getCmp(this.cid + 'idplyn').store.loadData(datad[1]);
                            if (this.obiekt != null) {
                                Ext.getCmp(this.cid + "kod").setValue(this.obiekt.kod);
                                Ext.getCmp(this.cid + "kod").setDisabled(true);
                                Ext.getCmp(this.cid + "idsterownik").setValue2(this.obiekt.idsterownik);
                                Ext.getCmp(this.cid + "idsterownik").setDisabled(true);
                                Ext.getCmp(this.cid + "idplyn").setValue2(this.obiekt.idplyn);
                                Ext.getCmp(this.cid + "idplyn").setDisabled(true);
                                Ext.getCmp(this.cid + "vlimit").setValue(this.obiekt.vlimit);
                                Ext.getCmp(this.cid + "vlimit").setDisabled(true);
                                Ext.getCmp(this.cid + "waznosc").setValue(this.obiekt.waznosc);
                                Ext.getCmp(this.cid + "waznosc").setDisabled(true);
                                Ext.getCmp(this.cid + "opis").setValue(this.obiekt.opis);
                            } else {
                                Ext.getCmp(this.cid + "idplyn").setValue2(null);
                                Ext.getCmp(this.cid + "idplyn").clearInvalid();
                                Ext.getCmp(this.cid + "idplyn").setDisabled(false);
                                Ext.getCmp(this.cid + "idsterownik").setValue2(null);
                                Ext.getCmp(this.cid + "idsterownik").clearInvalid();
                                Ext.getCmp(this.cid + "idsterownik").setDisabled(false);
                                Ext.getCmp(this.cid + "kod").setValue(this.kodGen);
                                Ext.getCmp(this.cid + "kod").clearInvalid();
                                Ext.getCmp(this.cid + "kod").setDisabled(true);
                                Ext.getCmp(this.cid + "vlimit").setValue(1);
                                Ext.getCmp(this.cid + "vlimit").clearInvalid();
                                Ext.getCmp(this.cid + "vlimit").setDisabled(false);
                                Ext.getCmp(this.cid + "waznosc").setValue(Ext.Date.add(new Date(), Ext.Date.HOUR, 1));
                                Ext.getCmp(this.cid + "waznosc").clearInvalid();
                                Ext.getCmp(this.cid + "waznosc").setDisabled(false);
                                Ext.getCmp(this.cid + "opis").setValue(null);
                            }
                            var title = Lang.tankowanie_reczne.capitalise();
                            if (AppFIRMACTX != null)
                                title += " (" + AppFIRMACTX.nazwa + ")";
                            this.setTitle(title);
                        }
                    } else {
                        Ext.MessageBox.alert(Lang.error, data.error);
                    }
                });
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    saveOp: function () {
        var canWrite = false;
        if (Ext.getCmp(this.cid + "formpanel").form.isValid()) {
            canWrite = true;
        }
        if (canWrite) {
            sendAjaxDataJsonRpcL("rest/reczne/saveObject", {
                id: this.obiektid,
                kod: Ext.getCmp(this.cid + "kod").getValue(),
                idsterownik: Ext.getCmp(this.cid + "idsterownik").getValue2(),
                idplyn: Ext.getCmp(this.cid + "idplyn").getValue2(),
                vlimit: Ext.getCmp(this.cid + "vlimit").getValue(),
                opis: Ext.getCmp(this.cid + "opis").getValue(),
                waznosc: Ext.getCmp(this.cid + "waznosc").getValue2(),
                optlockversion: this.obiekt == null ? null : this.obiekt.optlockversion
            }, this, function (data) {
                if (data.status) {
                    data = data.data;
                    this.obiektid = data.id;
                    this.close();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
                if (this.callbackF)
                    this.callbackF();
            });
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    closeOp: function () {
        this.close();
    }
});
