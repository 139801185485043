Ext.define('LimitPanelCp', {
    extend: 'Ext.panel.Panel',
    alias: "widget.limitpanelcp",
    collapsible: false,
    titleCollapse: false,
    collapsed: false,
    title: Lang.limity,
    frame: false,
    border: false,
    height: 220,
    layout: "fit",
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'gridpanelexd',
            dockedItems: {
                xtype: 'toolbar',
                dock: 'top',
                scrollable: 'x',
                items: [{
                    text: Lang.nowy,
                    handler: function () {
                        var www = Ext.create('WindowLimitEditAdd', {
                            obiekt: null,
                            parentObj: this
                        });
                        www.showW();
                        www.on("closeok", function () {
                            var grid = Ext.getCmp(this.cid + "limitygrid");
                            var rpp = {
                                plyn: www.obiekt.plyn,
                                wartosc: www.obiekt.wartosc,
                                wartoscleft: www.obiekt.wartosc,
                                recordv: www.obiekt
                            };
                            grid.store.add(rpp);
                        }, this);
                    },
                    scope: this
                }, {
                    id: this.cid + "bedit",
                    text: Lang.edytuj,
                    handler: function () {
                        var selR = Ext.getCmp(this.cid + "limitygrid").getSelR();
                        if (selR != null) {
                            var recordv = selR.get("recordv");
                            var www = Ext.create('WindowLimitEditAdd', {
                                obiekt: recordv,
                                parentObj: this
                            });
                            www.showW();
                            www.on("closeok", function () {
                                selR.set("plyn", www.obiekt.plyn);
                                selR.set("wartosc", www.obiekt.wartosc, {
                                    commit: true
                                });
                            }, this);
                        }
                    },
                    scope: this
                }, {
                    text: Lang.usun,
                    id: this.cid + "bdelete",
                    handler: function () {
                        var selR = Ext.getCmp(this.cid + "limitygrid").getSelR();
                        if (selR != null) {
                            Ext.getCmp(this.cid + "limitygrid").store.remove(selR);
                        }

                    },
                    scope: this
                }, {
                    text: Lang.pozostale_limity_zero,
                    id: this.cid + 'bzerolimity',
                    handler: function () {
                        sendAjaxDataJsonRpcL("rest/plyny/getShortList", null, this, function (data) {
                            if (data.status) {
                                var plyny = data.data;
                                var store = Ext.getCmp(this.cid + "limitygrid").getStore();
                                var existingPlyny = store.collect("plyn");
                                var remainingPlyny = Ext.Array.filter(plyny, function (item) {
                                    return !Ext.Array.contains(existingPlyny, item.nazwa);
                                }, this);
                                Ext.Array.each(remainingPlyny, function (item) {
                                    var limit = {
                                        id: 0,
                                        wartosc: 0,
                                        hourszero: Ext.Date.parse("2000-01-01 00:00:00", Date.patterns.ISO8601Long),
                                        typ: Const.LIMIT_TYPE_NIECYKLICZNY,
                                        weekdayszero: Const.TIME_WEEK_DAY_NIEDZIELA,
                                        daymonthszero: 1,
                                        monthszero: Const.TIME_MONTH_STYCZEN,
                                        idplyn: item.id,
                                        plyn: item.nazwa
                                    };
                                    store.add({
                                        plyn: item.nazwa,
                                        wartosc: 0,
                                        wartoscleft: 0,
                                        recordv: limit
                                    });
                                });
                            } else {
                                Ext.MessageBox.alert(Lang.error, data.error);
                            }
                        });
                    },
                    scope: this
                }]
            },
            store: new Ext.data.Store({
                autoLoad: false,
                autoSync: true,
                proxy: new Ext.data.MemoryProxy({}),
                reader: new Ext.data.JsonReader({}, [{
                    name: 'id',
                    mapping: 'id'
                }, {
                    name: 'plyn',
                    mapping: 'plyn'
                }, {
                    name: 'wartosc',
                    mapping: 'wartosc'
                }, {
                    name: 'wartoscleft',
                    mapping: 'wartoscleft'
                }, {
                    name: 'recordv',
                    mapping: "recordv",
                    type: "auto"
                }, {
                    name: 'iszaladuneklimity',
                    mapping: 'iszaladuneklimity'
                }])
            }),
            columns: [{
                header: Lang.plyn,
                flex: 2,
                sortable: false,
                dataIndex: 'plyn'
            }, {
                header: Lang.wartosc,
                flex: 2,
                sortable: false,
                dataIndex: 'wartosc',
                renderer: function (v) {
                    return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                },
                resizable: false
            }, {
                header: Lang.pozostalo,
                flex: 2,
                sortable: false,
                dataIndex: 'wartoscleft',
                renderer: function (v) {
                    return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                },
                resizable: false
            }],
            frame: false,
            border: true,
            id: this.cid + "limitygrid",
            stateId: null,
            stateful: false,
            stripeRows: true,
            enableHdMenu: false,
            selModel: {
                selType: 'rowmodel',
                mode: 'single',
                allowDeselect: false,
                listeners: {
                    scope: this,
                    select: function (model, record, index, eventOptions) {
                        this.manageSelect();
                    },
                    selectionchange: function (model, selected, eOptions) {
                        this.manageSelect();
                    }
                }
            },
            listeners: {
                scope: this,
                rowdblclick: function (component, record, element, rowIndex, e, eventOptions) {
                    var selR = Ext.getCmp(this.cid + "limitygrid").getSelR();
                    var iszaladuneklimity = selR.get("iszaladuneklimity");
                    if (iszaladuneklimity == false || AppOPERATORROLA == Const.OPERATOR_P_SERWIS) {
                        var selR = record;
                        if (selR == null)
                            return;
                        var recordv = selR.get("recordv");
                        var www = Ext.create('WindowLimitEditAdd', {
                            obiekt: recordv,
                            parentObj: this
                        });
                        www.showW();
                        www.on("closeok", function () {
                            selR.set("plyn", www.obiekt.plyn);
                            selR.set("wartosc", www.obiekt.wartosc, {
                                commit: true
                            });
                        }, this);

                    }
                }
            },
            getSelR: function () {
                var gridd = this;
                if (!gridd.getSelectionModel().hasSelection()) {
                    return null;
                } else {
                    if (gridd.getSelection() != null && gridd.getSelection().length > 0) {
                        return gridd.getSelection()[0];
                    }
                }
                return null;
            }
        }];
        this.callParent(arguments);
    },
    getLimitList: function () {
        this.limitydatas = [];
        var str = Ext.getCmp(this.cid + "limitygrid").store;
        for (var i = 0; i < str.getCount(); i++) {
            var r = str.getAt(i);
            var recordv = r.get("recordv");
            delete recordv["recordv"];
            this.limitydatas.push(recordv);
        }
        return this.limitydatas;
    },
    setLimitList: function (limitydatas) {
        this.limitydatas = limitydatas;
        var grid = Ext.getCmp(this.cid + "limitygrid");
        for (var i = 0; i < this.limitydatas.length; i++) {
            this.limitydatas[i]["recordv"] = this.limitydatas[i];
        }
        grid.store.loadData(this.limitydatas);
    },
    manageSelect: function () {
        var bedit = Ext.getCmp(this.cid + "bedit");
        var bdelete = Ext.getCmp(this.cid + "bdelete");
        var selR = Ext.getCmp(this.cid + "limitygrid").getSelR();
        var grid = Ext.getCmp(this.cid + "limitygrid");
        if (selR != null) {
            var iszaladuneklimity = selR.get("iszaladuneklimity");
            if (iszaladuneklimity == true && !AppOPERATORROLA == Const.OPERATOR_P_SERWIS) {
                bdelete.setDisabled(true);
                bedit.setDisabled(true);
            } else {
                bdelete.setDisabled(false);
                bedit.setDisabled(false);
            }
        } else {
            bdelete.setDisabled(true);
            bedit.setDisabled(true);
        }
    }
});
