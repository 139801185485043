Ext.define('WindowKorekta', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.korekta.capitalise(),
    width: 750,
    height: 500,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    scrollable: true,
    layout: 'fit',
    initComponent: function (config) {
        this.cid = Ext.id();
        let formpanel = {
            id: this.cid + "formpanel",
            xtype: 'form',
            frame: true,
            border: false,
            autoWidth: true,
            autoHeight: true,
            scrollable: WindowExdMobileDEVICEV,
            labelWidth: 80,
            bodyStyle: 'padding:10px 10px 10px 10px',
            defaults: {
                anchor: "0",
                labelWidth: 80
            },
            layout: {
                type: 'vbox',
                align: 'stretch'
            },
            fileUpload: true,
            items: [{
                anchor: "0",
                layout: 'column',
                frame: false,
                border: false,
                items: [{
                    columnWidth: WindowExdMobileDEVICEV ? 1 : .5,
                    layout: 'anchor',
                    frame: false,
                    border: false,
                    labelWidth: 120,
                    style: 'padding-left: 10px; padding-top: 10px;',
                    defaults: {
                        anchor: "0",
                        labelWidth: 120,
                        labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                    },
                    items: [{
                        id: this.cid + 'idzbiornik',
                        xtype: 'comboexd',
                        valueField: 'id',
                        displayField: 'nazwa',
                        store: new Ext.data.JsonStore({
                            id: "id",
                            rootProperty: '',
                            sorters: [{
                                property: "nazwa",
                                direction: "ASC"
                            }],
                            fields: [{
                                name: 'id',
                                mapping: 'id'
                            }, {
                                name: 'nazwa',
                                mapping: 'nazwa'
                            }, {
                                name: 'nazwa',
                                mapping: 'nazwa'
                            }, {
                                name: 'stanzbiornika',
                                mapping: 'stanzbiornika',
                                type: 'float'
                            }, {
                                name: 'stanzbiornikareal',
                                mapping: 'stanzbiornikareal',
                                useNull: true,
                                type: 'float'
                            }, {
                                name: 'pojemnosc',
                                mapping: 'pojemnosc',
                                type: 'float'
                            }, {
                                name: 'stanalarmowy',
                                mapping: 'stanalarmowy',
                                type: 'float'
                            }, {
                                name: 'stanblokady',
                                mapping: 'stanblokady',
                                type: 'float'
                            }]
                        }),
                        triggerAction: 'all',
                        resizable: false,
                        editable: false,
                        mode: "local",
                        fieldLabel: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG ? Lang.sterownik : Lang.zbiornik,
                        allowBlank: false,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        tpl: new Ext.XTemplate('<tpl for="."><li role="option" unselectable="on" class="x-boundlist-item" tabindex="-1" data-recordindex="{#}" data-recordid="{#+1}" data-boundview=""">{nazwa} - <i>{[this.ffrdv(values.stanzbiornika)]}</i></li></tpl>', {
                            ffrdv: function (v) {
                                return Ext.Number.roundToPrecision(v, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                            }
                        }),
                        listeners: {
                            scope: this,
                            select: function (c, r, i) {
                                Ext.getCmp(this.cid + "stan").setValue(r.get('stanzbiornika'));
                                Ext.getCmp(this.cid + "stanzbiornikareal").setValue(r.get('stanzbiornikareal'));
                                Ext.getCmp(this.cid + "stan2").setValue(r.get('stanzbiornika'));
                                Ext.getCmp(this.cid + "pojemnosc").setValue(r.get('pojemnosc'));
                                Ext.getCmp(this.cid + "stanalarmowy").setValue(r.get('stanalarmowy'));
                                Ext.getCmp(this.cid + "stanblokady").setValue(r.get('stanblokady'));
                                var gestoscwymagana = r.get("gestoscwymagana");
                                Ext.getCmp(this.cid + "gestoscsrednia").allowBlank = !gestoscwymagana;
                                Ext.getCmp(this.cid + "gestoscsrednia").setValue(r.get('gestoscsrednia'));
                                if (r.get("gestoscmin") > 0) {
                                    Ext.getCmp(this.cid + "gestoscsrednia").minValue = r.get("gestoscmin");
                                }
                                if (r.get("gestoscmax") > 0) {
                                    Ext.getCmp(this.cid + "gestoscsrednia").maxValue = r.get("gestoscmax");
                                }
                            }
                        },
                        cls: "x-item-disabled-sec"
                    }, {
                        xtype: 'numberfieldexd',
                        id: this.cid + "pojemnosc",
                        name: "pojemnosc",
                        decimalPrecision: 3,
                        fieldLabel: Lang.pojemnosc + Lang.jednostka_text,
                        disabled: true,
                        cls: "x-item-disabled-sec"
                    }, {
                        xtype: 'numberfieldexd',
                        id: this.cid + "stanalarmowy",
                        name: "stanalarmowy",
                        decimalPrecision: 3,
                        fieldLabel: Lang.stanalarmowy + Lang.jednostka_text,
                        disabled: true,
                        cls: "x-item-disabled-sec"
                    }, {
                        xtype: 'numberfieldexd',
                        id: this.cid + "stanblokady",
                        name: "stanblokady",
                        decimalPrecision: 3,
                        fieldLabel: Lang.stanblokady + Lang.jednostka_text,
                        disabled: true,
                        cls: "x-item-disabled-sec"
                    }]
                }]
            }]
        };
        let formitems = [{
            xtype: 'numberfieldexd',
            id: this.cid + "stan",
            name: "stan",
            decimalPrecision: 3,
            fieldLabel: Lang.stan_obecny + Lang.jednostka_text,
            disabled: true,
            cls: "x-item-disabled-sec",
            hidden: Const.SKPFLAG
        }, {
            xtype: 'numberfieldexd',
            id: this.cid + "stanzbiornikareal",
            name: "stanzbiornikareal",
            decimalPrecision: 3,
            fieldLabel: Lang.stanzbiornikareal + Lang.jednostka_text,
            disabled: true,
            cls: "x-item-disabled-sec",
            hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
        }, {
            xtype: 'numberfieldexd',
            id: this.cid + "stan2",
            name: "stan2",
            fieldLabel: Lang.stan_pokorekcie + Lang.jednostka_text,
            allowBlank: false,
            blankText: Lang.pole_nie_moze_byc_puste,
            minValue: 0,
            maxValue: 9999999,
            decimalPrecision: 3,
            validator: Ext.Function.bind(function (v) {
                return this.validIle;
            }, this, []),
            cls: "x-item-disabled-sec"
        }, {
            id: this.cid + "gestoscsrednia",
            name: "gestoscsrednia",
            xtype: 'numberfieldexd',
            fieldLabel: Lang.gestosc_srednia + "[kg/m^3]",
            allowBlank: true,
            allowNegative: false,
            decimalPrecision: 3,
            blankText: Lang.pole_nie_moze_byc_puste,
            minValue: 600,
            maxValue: 1100,
            cls: "x-item-disabled-sec",
            hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
        }];
        if (WindowExdMobileDEVICEV) {
            formpanel.items[0].items[0].items.push(...formitems);
        } else {
            formpanel.items[0].items.push({
                columnWidth: .5,
                layout: 'anchor',
                frame: false,
                border: false,
                bodyStyle: 'padding-left:10px; padding-right: 10px; padding-top: 10px;',
                labelWidth: 120,
                defaults: {
                    anchor: "0",
                    labelWidth: 120
                },
                items: [...formitems]
            });
        }
        formpanel.items.push({
            anchor: "0",
            xtype: 'form',
            frame: false,
            border: false,
            bodyStyle: WindowExdMobileDEVICEV ? 'padding: 0 0 0 10px;' : 'padding:10px 10px 10px 10px',
            layout: 'anchor',
            defaults: {
                anchor: '0',
                labelWidth: 120,
                labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
            },
            items: [{
                id: this.cid + "opis",
                name: "opis",
                xtype: 'textareaexd',
                height: 120,
                fieldLabel: Lang.opis
            }]
        });
        this.items = [{
            xtype: 'panel',
            layout: 'fit',
            items: [formpanel]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    updateStan: function () {
        var pojemnosc = Ext.getCmp(this.cid + "pojemnosc").getValue();
        var stan2 = Ext.getCmp(this.cid + "stan2").getValue2();
        if (stan2 != null) {
            if (pojemnosc < stan2) {
                this.validIle = Lang.przekroczona_pojemnosc_zbiornika;
            } else {
                this.validIle = true;
            }
            Ext.getCmp(this.cid + "stan2").validate();
        } else {
        }
    },
    showW: function (obiektid) {
        if (!validPrivilegesOperatorComplex("access.manage.korekty")) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        Ext.getCmp(this.cid + "formpanel").form.setValues({
            ile: 0,
            stan: 0,
            stan2: 0,
            gestoscsrednia: 0,
            pojemnosc: 0,
            stanblokady: 0,
            stanalarmowy: 0,
            opis: ""
        });
        this.obiektid = obiektid;
        this.obiekt = null;
        sendAjaxDataJsonRpcL("rest/zaladunekkorekty/getObjectForEdit", {
            id: this.obiektid
        }, this, function (data) {
            if (data.status) {
                this.obiekt = data.data;
                sendAjaxDataJsonRpcL("rest/zbiorniki/getList", {
                    idinclude: this.obiekt != null ? this.obiekt.idzbiornik : 0
                }, this, function (data) {
                    if (data.status) {
                        var listFnData = data.data;
                        this.show();
                        this.center();
                        this.setKeyMap({
                            'ESC': {
                                scope: this,
                                handler: this.closeOp
                            }
                        });
                        Ext.getCmp(this.cid + 'idzbiornik').store.loadData(listFnData);
                        if (this.obiekt == null) {
                            Ext.getCmp(this.cid + 'idzbiornik').setValue2Default(null);
                            if (Ext.getCmp(this.cid + 'idzbiornik').isRow()) {
                                var row = Ext.getCmp(this.cid + 'idzbiornik').getRow();
                                Ext.getCmp(this.cid + "stan").setValue(!row ? null : row.get('stanzbiornika'));
                                Ext.getCmp(this.cid + "stan2").setValue(!row ? null : row.get('stanzbiornika'));
                                Ext.getCmp(this.cid + "gestoscsrednia").setValue(!row ? null : row.get('gestoscsrednia'));
                                Ext.getCmp(this.cid + "pojemnosc").setValue(!row ? null : row.get('pojemnosc'));
                                Ext.getCmp(this.cid + "stanalarmowy").setValue(!row ? null : row.get('stanalarmowy'));
                                Ext.getCmp(this.cid + "stanblokady").setValue(!row ? null : row.get('stanblokady'));
                                Ext.getCmp(this.cid + "stanzbiornikareal").setValue(!row ? null : row.get('stanzbiornikareal'));
                                if (row.get("gestoscmin") > 0) {
                                    Ext.getCmp(this.cid + "gestoscsrednia").minValue = row.get("gestoscmin");
                                }
                                if (row.get("gestoscmax") > 0) {
                                    Ext.getCmp(this.cid + "gestoscsrednia").maxValue = row.get("gestoscmax");
                                }
                            }
                            Ext.getCmp(this.cid + "stan2").clearInvalid();
                            Ext.getCmp(this.cid + "stan2").setDisabled(false);
                            Ext.getCmp(this.cid + "idzbiornik").setDisabled(false);
                            this.validIle = true;
                            this.task = {
                                run: this.updateStan,
                                scope: this,
                                interval: 1000
                            };
                            Ext.TaskManager.start(this.task);
                        } else {
                            Ext.getCmp(this.cid + 'idzbiornik').setValue2(this.obiekt.idzbiornik);
                            var row = Ext.getCmp(this.cid + 'idzbiornik').getRow();
                            Ext.getCmp(this.cid + "stan").setValue(this.obiekt.stanzbiornika - this.obiekt.ile);
                            Ext.getCmp(this.cid + "stan2").setValue(this.obiekt.stanzbiornika);
                            Ext.getCmp(this.cid + "pojemnosc").setValue(!row ? null : row.get('pojemnosc'));
                            Ext.getCmp(this.cid + "stanalarmowy").setValue(!row ? null : row.get('stanalarmowy'));
                            Ext.getCmp(this.cid + "stanblokady").setValue(!row ? null : row.get('stanblokady'));
                            Ext.getCmp(this.cid + "opis").setValue(this.obiekt.opis);
                            Ext.getCmp(this.cid + "stan2").setDisabled(true);
                            Ext.getCmp(this.cid + "idzbiornik").setDisabled(true);
                            Ext.getCmp(this.cid + "gestoscsrednia").setValue(this.obiekt.gestoscsrednia);
                            Ext.getCmp(this.cid + "gestoscsrednia").setDisabled(true);
                            this.validIle = true;
                        }
                    } else {
                        Ext.MessageBox.alert(Lang.error, data.error);
                    }
                });
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    saveOp: function () {
        var canWrite = false;
        if (!this.obiektid) {
            if (Ext.getCmp(this.cid + "stan2").isValid() && Ext.getCmp(this.cid + "gestoscsrednia").isValid()) {
                canWrite = true;
            }
        } else
            canWrite = true;
        if (canWrite) {
            var stan = Ext.getCmp(this.cid + "stan").getValue();
            var stan2 = Ext.getCmp(this.cid + "stan2").getValue();
            sendAjaxDataJsonRpcL("rest/zaladunekkorekty/saveObject", {
                id: this.obiektid,
                idzbiornik: Ext.getCmp(this.cid + "idzbiornik").getValue(),
                ile: stan2 - stan,
                stan: Ext.getCmp(this.cid + "stan").getValue(),
                stan2: Ext.getCmp(this.cid + "stan2").getValue(),
                opis: Ext.getCmp(this.cid + "opis").getValue(),
                gestoscsrednia: Ext.getCmp(this.cid + "gestoscsrednia").getValue(),
                iskorekta: true,
                optlockversion: this.obiekt == null ? null : this.obiekt.optlockversion
            }, this, function (data) {
                if (data.status) {
                    data = data.data;
                    this.obiektid = data.id;
                    if (this.task != undefined) {
                        Ext.TaskManager.stop(this.task);
                    }
                    this.close();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
                if (this.callbackF)
                    this.callbackF();
            });
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    closeOp: function () {
        if (this.task != undefined) {
            Ext.TaskManager.stop(this.task);
        }
        this.close();
    }
});
