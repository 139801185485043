Ext.define('PanelWizardSortChoose', {
    extend: 'Ext.panel.Panel',
    initComponent: function () {
        this.cid = Ext.id();
        Ext.apply(this, {
            layout: {
                type: "vbox",
                align: "stretch"
            },
            frame: false,
            title: Lang.rplsortowanie,
            border: false,
            autoWidth: false,
            height: 457,
            autoHeight: false,
            items: [{
                xtype: 'grid',
                store: new Ext.data.Store({
                    autoLoad: false,
                    proxy: new Ext.data.MemoryProxy({}),
                    reader: new Ext.data.JsonReader({
                        id: "id",
                        rootProperty: ''
                    }, [{
                        name: 'nazwa',
                        mapping: 'nazwa'
                    }, {
                        name: 'order',
                        mapping: 'order'
                    }, {
                        name: 'allowdrag',
                        mapping: 'allowdrag'
                    }])
                }),
                columns: [{
                    header: Lang.nazwa,
                    flex: 3,
                    sortable: true,
                    dataIndex: 'nazwa',
                    editable: false,
                    sortable: false,
                    renderer: function (value, metaData, record, rowIndex, colIndex, store, view) {
                        if (!record.get("allowdrag"))
                            metaData.css += " x-rlist-view-disable-drag";
                        return value;
                    }
                }, {
                    header: Lang.kierunek,
                    flex: 2,
                    resizable: false,
                    sortable: true,
                    dataIndex: 'order',
                    sortable: false,
                    renderer: function (v) {
                        if (v == Const.SORT_ORDER_ASC)
                            return Lang.rosnaco;
                        if (v == Const.SORT_ORDER_DESC)
                            return Lang.malejaco;
                    },
                    editor: {
                        completeOnEnter: true,
                        field: {
                            xtype: 'combo',
                            store: new Ext.data.ArrayStore({
                                data: [[Const.SORT_ORDER_ASC, Lang.rosnaco], [Const.SORT_ORDER_DESC, Lang.malejaco]],
                                fields: ["value", "text"]
                            }),
                            displayField: "text",
                            valueField: "value",
                            triggerAction: 'all',
                            editable: false,
                            mode: "local"
                        }
                    }
                }],
                flex: 1,
                cls: "x-grid-own-edit-prop",
                frame: true,
                border: true,
                id: this.cid + "firmas_to2",
                stateId: null,
                height: 410,
                stateful: false,
                stripeRows: true,
                enableHdMenu: false,
                scrollable: true,
                selModel: {
                    selType: 'rowmodel',
                    mode: 'single'
                },
                viewConfig: {
                    plugins: {
                        gridviewdragdrop: {
                            ddGroup: this.cid + "groupColSelectedSort",
                            enableDrop: true,
                            enableDrag: true,
                            dragZone: {
                                onBeforeDrag: function (da, e) {
                                    var node = this.view.findTargetByEvent(e);
                                    if (node) {
                                        var rowIndexr = this.view.getRecord(node);
                                        if (!rowIndexr.get("allowdrag")) {
                                            return false;
                                        }
                                        return true;
                                    }
                                    return false;
                                }
                            },
                            dropZone: {
                                notifyOver: function (dd, e, data) {
                                    var node = this.view.findTargetByEvent(e);
                                    if (node) {
                                        var rowIndexr = this.view.getRecord(node);
                                        if (!rowIndexr.get("allowdrag")) {
                                            return false;
                                        }
                                    }
                                    return this.dropAllowed;
                                },
                                notifyDrop: function (dd, e, data) {
                                    var node = this.view.findTargetByEvent(e);
                                    if (node) {
                                        var rowIndexr = this.view.getRecord(node);
                                        if (!rowIndexr.get("allowdrag")) {
                                            return false;
                                        }
                                    }
                                    var grid = this.view.up("grid");
                                    var sm = grid.getSelectionModel();
                                    var rows = sm.getSelection();
                                    var node = this.view.findTargetByEvent(e);
                                    var cindex = this.view.indexOf(node);
                                    if (sm.hasSelection()) {
                                        for (i = 0; i < rows.length; i++) {
                                            grid.store.remove(grid.store.getById(rows[i].id));
                                            grid.store.insert(cindex, rows[i]);
                                        }
                                        sm.select(rows);
                                    }
                                }
                            }
                        }
                    }
                },
                plugins: {
                    cellediting: {
                        clicksToEdit: 1
                    }
                },
                listeners: {
                    scope: this,
                    beforedrop: function (node, data, overModel, dropPosition, dropHandlers) {
                        var ddf = true;
                        data.records.forEach(function (r) {
                            if (!r.get("allowdrag")) {
                                ddf = false;
                            }
                        })
                        return ddf;
                    }
                }
            }]
        });
        this.callParent(arguments);
    },
    callAfterShow: function () {
        var grid2 = Ext.getCmp(this.cid + "firmas_to2");
        var dragZone = grid2.getView().dragZone;
        Ext.apply(dragZone, {
            onBeforeDrag: function (da, e) {
                var t = Ext.lib.Event.getTarget(e);
                var rowIndex = this.view.findRowIndex(t);
                if (rowIndex !== false) {
                    var rowIndexr = this.grid.store.getAt(rowIndex);
                    if (!rowIndexr.get("allowdrag"))
                        return false;
                    return true;
                }
                return false;
            }
        });
        var ddrow = new Ext.dd.DropTarget(grid2.getView().body, {
            ddGroup: grid2.ddGroup,
            notifyOver: function (dd, e, data) {
                var t = Ext.lib.Event.getTarget(e);
                var rowIndex = grid2.view.findRowIndex(t);
                if (rowIndex !== false) {
                    var rowIndexr = grid2.store.getAt(rowIndex);
                    if (!rowIndexr.get("allowdrag"))
                        return false;
                    return this.dropAllowed;
                }
                return this.dropAllowed;
            },
            notifyDrop: function (dd, e, data) {
                var t = Ext.lib.Event.getTarget(e);
                var rowIndex = grid2.view.findRowIndex(t);
                if (rowIndex !== false) {
                    var rowIndexr = grid2.store.getAt(rowIndex);
                    if (!rowIndexr.get("allowdrag"))
                        return false;
                }
                var sm = grid2.getSelectionModel();
                var rows = sm.getSelection();
                var cindex = dd.getDragData(e).rowIndex;
                if (sm.hasSelection()) {
                    for (i = 0; i < rows.length; i++) {
                        grid2.store.remove(grid2.store.getById(rows[i].id));
                        grid2.store.insert(cindex, rows[i]);
                    }
                    sm.select(rows);
                }
            }
        });
        var langmap = this.datad.mainCtrl.getKoolumnNazwaLangMap();
        var kolumnyyD = [];
        kolumnyyD.push({
            id: "czas",
            nazwa: langmap["czas"],
            order: Const.SORT_ORDER_ASC,
            allowdrag: true
        });
        if (Const.ENTERPRISEFLAG)
            kolumnyyD.push({
                id: "firma",
                nazwa: langmap["firma"],
                order: Const.SORT_ORDER_ASC,
                allowdrag: true
            });
        kolumnyyD.push({
            id: "plyn",
            nazwa: langmap["plyn"],
            order: Const.SORT_ORDER_ASC,
            allowdrag: true
        });
        kolumnyyD.push({
            id: "zbiornik",
            nazwa: langmap["zbiornik"],
            order: Const.SORT_ORDER_ASC,
            allowdrag: true
        });
        kolumnyyD.push({
            id: "typ_tankowania",
            nazwa: langmap["typ_tankowania"],
            order: Const.SORT_ORDER_ASC,
            allowdrag: true
        });
        kolumnyyD.push({
            id: "ile",
            nazwa: langmap["ile"],
            order: Const.SORT_ORDER_ASC,
            allowdrag: true
        });
        kolumnyyD.push({
            id: "ilebezkompensacji",
            nazwa: langmap["ilebezkompensacji"],
            order: Const.SORT_ORDER_ASC,
            allowdrag: true
        });

        if (this.datad.mainCtrl.repFirma == null) {
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "odbiorca",
                    nazwa: langmap["odbiorca"],
                    order: Const.SORT_ORDER_ASC,
                    allowdrag: true
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "pojazdTRW",
                    nazwa: langmap["pojazdTRW"],
                    order: Const.SORT_ORDER_ASC,
                    allowdrag: true
                });
        } else {
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "odbiorca",
                    nazwa: langmap["odbiorca"],
                    order: Const.SORT_ORDER_ASC,
                    allowdrag: true
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "pojazdTRW",
                    nazwa: langmap["pojazdTRW"],
                    order: Const.SORT_ORDER_ASC,
                    allowdrag: true
                });
        }
        if (!Const.SKPFLAG)
            kolumnyyD.push({
                id: "licznik",
                nazwa: langmap["licznik"],
                order: Const.SORT_ORDER_ASC,
                allowdrag: true
            });
        if (!Const.SKPFLAG)
            kolumnyyD.push({
                id: "dystans",
                nazwa: langmap["dystans"],
                order: Const.SORT_ORDER_ASC,
                allowdrag: true
            });
        if (!Const.SKPFLAG)
            if (!Const.BASICFLAG && !Const.STARTERFLAG)
                kolumnyyD.push({
                    id: "zuzycie100",
                    nazwa: langmap["zuzycie100"],
                    order: Const.SORT_ORDER_ASC,
                    allowdrag: true
                });
        if (!Const.SKPFLAG)
            if (!Const.BASICFLAG && !Const.STARTERFLAG)
                kolumnyyD.push({
                    id: "normazuzycie100",
                    nazwa: langmap["normazuzycie100"],
                    order: Const.SORT_ORDER_ASC,
                    allowdrag: true
                });
        if (!Const.SKPFLAG)
            if (!Const.BASICFLAG && !Const.STARTERFLAG)
                if (!Const.STANDARDFLAG)
                    kolumnyyD.push({
                        id: "motogodziny",
                        nazwa: langmap["motogodziny"],
                        order: Const.SORT_ORDER_ASC,
                        allowdrag: true
                    });
        if (!Const.SKPFLAG)
            if (!Const.BASICFLAG && !Const.STARTERFLAG)
                if (!Const.STANDARDFLAG)
                    kolumnyyD.push({
                        id: "czaspracy",
                        nazwa: langmap["czaspracy"],
                        order: Const.SORT_ORDER_ASC,
                        allowdrag: true
                    });
        if (!Const.SKPFLAG)
            if (!Const.BASICFLAG && !Const.STARTERFLAG)
                if (!Const.STANDARDFLAG)
                    kolumnyyD.push({
                        id: "zuzycie1h",
                        nazwa: langmap["zuzycie1h"],
                        order: Const.SORT_ORDER_ASC,
                        allowdrag: true
                    });

        if (!Const.SKPFLAG)
            if (!Const.BASICFLAG && !Const.STARTERFLAG)
                if (!Const.STANDARDFLAG)
                    kolumnyyD.push({
                        id: "normazuzycie1h",
                        nazwa: langmap["normazuzycie1h"],
                        order: Const.SORT_ORDER_ASC,
                        allowdrag: true
                    });
        if (this.datad.mainCtrl.repFirma == null) {
            if (!Const.SKPFLAG)
                if (!Const.OPTIMALFLAG)
                    if (!Const.BASICFLAG && !Const.STARTERFLAG)
                        if (!Const.STANDARDFLAG)
                            kolumnyyD.push({
                                id: "pojazdTRWRok",
                                nazwa: langmap["pojazdTRWRok"],
                                order: Const.SORT_ORDER_ASC,
                                allowdrag: true
                            });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "pojazdTRWIE",
                    nazwa: langmap["pojazdTRWIE"],
                    order: Const.SORT_ORDER_ASC,
                    allowdrag: true
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "odbiorcaEIID",
                    nazwa: langmap["odbiorcaEIID"],
                    order: Const.SORT_ORDER_ASC,
                    allowdrag: true
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "pojazdTRWEIID",
                    nazwa: langmap["pojazdTRWEIID"],
                    order: Const.SORT_ORDER_ASC,
                    allowdrag: true
                });
        } else {
            if (!Const.SKPFLAG)
                if (!Const.OPTIMALFLAG)
                    if (!Const.BASICFLAG && !Const.STARTERFLAG)
                        if (!Const.STANDARDFLAG)
                            kolumnyyD.push({
                                id: "pojazdTRWRok",
                                nazwa: langmap["pojazdTRWRok"],
                                order: Const.SORT_ORDER_ASC,
                                allowdrag: true
                            });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "pojazdTRWIE",
                    nazwa: langmap["pojazdTRWIE"],
                    order: Const.SORT_ORDER_ASC,
                    allowdrag: true
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "odbiorcaEIID",
                    nazwa: langmap["odbiorcaEIID"],
                    order: Const.SORT_ORDER_ASC,
                    allowdrag: true
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "pojazdTRWEIID",
                    nazwa: langmap["pojazdTRWEIID"],
                    order: Const.SORT_ORDER_ASC,
                    allowdrag: true
                });
        }
        if (!Const.SKPFLAG)
            kolumnyyD.push({
                id: "zdopis",
                nazwa: langmap["zdopis"],
                order: Const.SORT_ORDER_ASC,
                allowdrag: true
            });
        if (!Const.SKPFLAG)
            kolumnyyD.push({
                id: "sterownik",
                nazwa: langmap["sterownik"],
                order: Const.SORT_ORDER_ASC,
                allowdrag: true
            });
        if (!Const.SKPFLAG)
            kolumnyyD.push({
                id: "rozszerzeniester",
                nazwa: langmap["rozszerzeniester"],
                order: Const.SORT_ORDER_ASC,
                allowdrag: true
            });
        kolumnyyD.push({
            id: "operator",
            nazwa: langmap["operator"],
            order: Const.SORT_ORDER_ASC,
            allowdrag: true
        });
        kolumnyyD.push({
            id: "dostawca",
            nazwa: langmap["dostawca"],
            order: Const.SORT_ORDER_ASC,
            allowdrag: true
        });
        kolumnyyD.push({
            id: "stanZbiornika",
            nazwa: langmap["stan_zbiornika"],
            order: Const.SORT_ORDER_ASC,
            allowdrag: true
        });
        kolumnyyD.push({
            id: "plynEIID",
            nazwa: langmap["plynEIID"],
            order: Const.SORT_ORDER_ASC,
            allowdrag: true
        });
        kolumnyyD.push({
            id: "grupa_odbiorca",
            nazwa: langmap["grupa_odbiorca"],
            order: Const.SORT_ORDER_ASC,
            allowdrag: true
        });
        kolumnyyD.push({
            id: "grupa_pojazd",
            nazwa: langmap["grupa_pojazd"],
            order: Const.SORT_ORDER_ASC,
            allowdrag: true
        });
        if (Const.COSTCOLUMNS) {
            kolumnyyD.push({
                id: "kosztcalosc",
                nazwa: langmap["kosztcalosc"],
                order: Const.SORT_ORDER_ASC,
                allowdrag: true
            });
            kolumnyyD.push({
                id: "kosztlitr",
                nazwa: langmap["kosztlitr"],
                order: Const.SORT_ORDER_ASC,
                allowdrag: true
            });
        }
        if (!Const.BASICFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG) {
            if (Const.ENABLEGPS) {
                kolumnyyD.push({
                    id: "gpspojazdprzyrostbak",
                    nazwa: langmap["przyrost_bak"],
                    order: Const.SORT_ORDER_ASC,
                    allowdrag: true
                });
                kolumnyyD.push({
                    id: "gpspojazdroznicabak",
                    nazwa: langmap["roznica_bak"],
                    order: Const.SORT_ORDER_ASC,
                    allowdrag: true
                });
            }
            kolumnyyD.push({
                id: "pomiarPrzyrostZaladunek",
                nazwa: langmap["pomiar_przyrost_zbiornik_zaladunek"],
                order: Const.SORT_ORDER_ASC,
                allowdrag: true
            });
            kolumnyyD.push({
                id: "rzeczywistyUbytekTankowanie",
                nazwa: langmap["pomiar_ubytek_zbiornik_tank"],
                order: Const.SORT_ORDER_ASC,
                allowdrag: true
            });
        }
        kolumnyyD = generateDiffCollection(kolumnyyD, this.datad.mainCtrl.repDef.columnsInReport, "id");
        kolumnyyD = kolumnyyD.containsls;
        if (this.datad.mainCtrl.repDef.columnsInReportGroup.indexOf("czas") >= 0) {
            kolumnyyD.push({
                id: "czas2",
                nazwa: langmap["czas2"],
                order: Const.SORT_ORDER_ASC,
                allowdrag: true
            });
        }
        Ext.each(kolumnyyD, function (v) {
            var ii = this.datad.mainCtrl.repDef.columnsInReportSort.indexOf(v.id);
            if (ii >= 0) {
                v.order = this.datad.mainCtrl.repDef.columnsInReportSortOrder[ii];
            }
        }, this);
        var obssskolumnyyD = generateDiffCollection(kolumnyyD, this.datad.mainCtrl.repDef.columnsInReportGroup, "id");
        var kolumnyyD1 = obssskolumnyyD.restls;
        var kolumnyyD2 = obssskolumnyyD.containsls;
        kolumnyyD2 = generateCollectionOrder(kolumnyyD2, this.datad.mainCtrl.repDef.columnsInReportGroup, "id");
        kolumnyyD1 = generateCollectionOrder(kolumnyyD1, this.datad.mainCtrl.repDef.columnsInReportSort, "id");
        Ext.each(kolumnyyD2, function (v) {
            v.allowdrag = false;
        });
        kolumnyyD = kolumnyyD2.concat(kolumnyyD1);
        var grid2 = Ext.getCmp(this.cid + "firmas_to2");
        grid2.store.loadData(kolumnyyD);
    },
    callAfterHide: function () {
        if (this.callAfterShowCalled) {
            var okrestyp = Ext.getCmp(this.cid + "okrestyp");
            var okresod = Ext.getCmp(this.cid + "okresod");
            var okresdo = Ext.getCmp(this.cid + "okresdo");
            this.datad.mainCtrl.repDef.typeCzas = okrestyp.getValue();
            this.datad.mainCtrl.repDef.fromDate = okresod.getValue2();
            this.datad.mainCtrl.repDef.toDate = okresdo.getValue2();
        }
        return null;
    },
    reParseReportValidate: function () {
    },
    validateShowLess: function () {
        return this.validateShow();
    },
    validateShow: function () {
        var grid2 = Ext.getCmp(this.cid + "firmas_to2");
        var colsNext2 = grid2.store.collect("id");
        var colsNexto2 = [];
        grid2.store.each(function (model) {
            colsNexto2.push(model.data.order);
        }, this);
        this.datad.mainCtrl.repDef.columnsInReportSort = colsNext2;
        this.datad.mainCtrl.repDef.columnsInReportSortOrder = colsNexto2;
        return null;
    },
    setData: function (datad) {
        this.datad = datad;
    }
});
