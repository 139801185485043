window.ZBIORNIK_COMMUNICATION_STATE_STATUS = [
    [Const.ZBIORNIK_COMMUNICATION_STATE_STATUS_ACTIVE, Lang.active],
    [Const.ZBIORNIK_COMMUNICATION_STATE_STATUS_SHORT_INACTIVITY, Lang.nie_komunikuje_sie_krotki_czas],
    [Const.ZBIORNIK_COMMUNICATION_STATE_STATUS_LONG_INACTIVITY, Lang.nie_komunikuje_sie_dlugo],
    [Const.ZBIORNIK_COMMUNICATION_STATE_STATUS_INACTIVE, Lang.w_komunikacji_recznej]
];

window.SKP_TYPES = [
    [Const.TYPE_SKP_SKP, Lang.skp],
    [Const.TYPE_SKP_SKPULITE, Lang.skpulite]
];