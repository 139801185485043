Ext.define('WindowBackupCreate', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.wykonaj_kopie_zapasowa.capitalise(),
    width: 350,
    scrollable: true,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'panel',
            layout: 'column',
            items: [{
                columnWidth: 1,
                xtype: 'form',
                id: this.cid + 'formpanel',
                bodyStyle: 'padding:10px 10px 10px 10px',
                scrollable: true,
                layout: 'anchor',
                defaults: {
                    anchor: '0',
                    labelWidth: 100
                },
                items: [{
                    id: this.cid + "name",
                    name: "name",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.nazwa
                }, {
                    id: this.cid + "opis",
                    name: "opis",
                    xtype: 'textareaexd',
                    height: 120,
                    fieldLabel: Lang.opis
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid) {
        if (!validPrivilegesOperatorComplex("access.manage.backup")) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.show();
        this.center();
        this.setKeyMap({
            'ESC': {
                scope: this,
                handler: this.closeOp
            }
        });
    },
    saveOp: function () {
        var canWrite = false;
        if (Ext.getCmp(this.cid + "formpanel").form.isValid()) {
            canWrite = true;
        }
        if (canWrite) {
            Ext.getCmp(this.cid + 'save').setDisabled(true);
            sendAjaxDataJsonRpcL("rest/backup/create", {
                name: Ext.getCmp(this.cid + "name").getValue(),
                opis: Ext.getCmp(this.cid + "opis").getValue()
            }, this, function (data) {
                if (data.status) {
                    data = data.data;
                    Ext.TaskManager.start(this.task);
                    this.close();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
            });
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    closeOp: function () {
        this.close();
    }
});
