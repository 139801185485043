Ext.define('WindowMassEditChooseObjects', {
    extend: 'Ext.ux.window.WindowExd',
    title: "",
    width: 650,
    closable: false,
    collapsible: false,
    scrollable: true,
    modal: true,
    resizable: false,
    layout: 'fit',
    typ: Const.MASS_EDIT_TYPE_POJAZD, //Const.MASS_EDIT_TYPE_ODBIORCA
    formFields: {},
    initComponent: function () {
        this.cid = Ext.id();
        this.title = this.typ == Const.MASS_EDIT_TYPE_POJAZD ? Lang.wybor_pojazdow_do_edycji : Lang.wybor_odbiorcow_do_edycji;
        let pojazdyPanel = this.createPojazdyPanel();
        let pojazdyGrupyPanel = this.createPojazdyGrupyPanel();
        let odbiorcyPanel = this.createOdbiorcyPanel();
        let odbiorcyGrupyPanel = this.createOdbiorcyGrupyPanel();
        this.items = [{
            xtype: 'tabpanel',
            autoWidth: true,
            height: 555,
            border: false,
            deferredRender: false,
            activeTab: 0,
            scrollable: true,
            scope: this,
            items: [{
                title: this.typ == Const.MASS_EDIT_TYPE_POJAZD ? Lang.pojazdy.capitalise() : Lang.odbiorcy.capitalise(),
                disabled: isValidOperatorFirmaLimit(),
                itemId: "mainformpanel",
                xtype: "panel",
                layout: {
                    type: 'vbox',
                    align: 'stretch'
                },
                frame: true,
                height: 400,
                autoWidth: true,
                labelAlign: "top",
                bodyStyle: 'padding:10px 10px 10px 10px',
                items: [{
                    xtype: 'radiogroupexd',
                    itemId: "byeach",
                    name: "byeach",
                    baseCls: 'x-radio-column',
                    style: 'margin:20px 20px 30px 20px',
                    labelWidth: 150,
                    fieldLabel: Lang.kryterium_edycji.capitalise(),
                    vertical: false,
                    items: [{
                        boxLabel: this.typ == Const.MASS_EDIT_TYPE_POJAZD ? Lang.pojazdy.capitalise() : Lang.odbiorcy.capitalise(),
                        inputValue: true,
                        value: true
                    }, {
                        boxLabel: Lang.grupy.capitalise(),
                        inputValue: false
                    }],
                    listeners: {
                        scope: this,
                        change: function (radiogroup, newValue) {
                            if (this.typ == Const.MASS_EDIT_TYPE_POJAZD) {
                                this.down("#pojazdyPanel").setHidden(!newValue)
                                this.down("#pojazdyGrupyPanel").setHidden(newValue)
                                this.down("#mainformpanel").setTitle(newValue ? Lang.pojazdy.capitalise() : Lang.grupy.capitalise())
                            } else if (this.typ == Const.MASS_EDIT_TYPE_ODBIORCA) {
                                this.down("#odbiorcyPanel").setHidden(!newValue)
                                this.down("#odbiorcyGrupyPanel").setHidden(newValue)
                                this.down("#mainformpanel").setTitle(newValue ? Lang.odbiorcy.capitalise() : Lang.grupy.capitalise())
                            }
                        }
                    }
                }, pojazdyPanel, odbiorcyPanel, odbiorcyGrupyPanel, pojazdyGrupyPanel]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            itemId: 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp
        }, {
            itemId: 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    createPojazdyPanel: function () {
        return {
            xtype: 'panel',
            itemId: 'pojazdyPanel',
            frame: false,
            border: false,
            autoWidth: true,
            hidden: this.typ == Const.MASS_EDIT_TYPE_ODBIORCA,
            layout: 'hbox',
            layoutConfig: {
                align: "stretch"
            },
            items: [new Ext.ux.grid.GridPanelExd({
                store: new Ext.data.Store({
                    autoLoad: false,
                    proxy: new Ext.data.MemoryProxy({}),
                    reader: new Ext.data.JsonReader({
                        id: "id",
                        rootProperty: ''
                    }, [{
                        name: 'nazwa',
                        mapping: 'nazwa'
                    }])
                }),
                columns: [{
                    header: Lang.nazwa,
                    flex: 1,
                    width: 145,
                    sortable: true,
                    dataIndex: 'nazwa'
                }],
                title: Lang.dostepne.capitalise(),
                height: 390,
                flex: 1,
                frame: true,
                border: true,
                itemId: "pojazdy_from",
                stateId: null,
                stateful: false,
                stripeRows: true,
                enableHdMenu: true,
                selModel: {
                    selType: 'rowmodel',
                    mode: 'SINGLE'
                },
                listeners: {
                    scope: this,
                    rowdblclick: function () {
                        var grid = this.down("#pojazdy_from");
                        var grid2 = this.down("#pojazdy_to");
                        var r = grid.getSelR();
                        if (r == null || r.items.length == 0)
                            return;
                        var selectedItem = r.items[0];
                        grid.store.remove(selectedItem);
                        grid2.store.add(selectedItem);
                    }
                },
                getSelR: function () {
                    var gridd = this;
                    if (!gridd.getSelectionModel().hasSelection()) {
                        return null;
                    } else {
                        if (gridd.getSelectionModel().getSelected() != null) {
                            return gridd.getSelectionModel().getSelected();
                        }
                    }
                    return null;
                }
            }), {
                width: 55,
                xtype: 'panel',
                frame: false,
                border: false,
                bodyStyle: 'padding:5px 5px 5px 5px',
                height: 390,
                layout: {
                    type: 'vbox',
                    pack: "center",
                    align: "stretch"
                },
                items: [{
                    xtype: 'button',
                    text: ">",
                    width: 50,
                    scope: this,
                    style: "margin-bottom:5px;",
                    handler: function () {
                        var grid = this.down("#pojazdy_from");
                        var grid2 = this.down("#pojazdy_to");
                        var r = grid.getSelR();
                        if (r == null || r.items.length == 0)
                            return;
                        var selectedItem = r.items[0];
                        grid.store.remove(selectedItem);
                        grid2.store.add(selectedItem);
                    }
                }, {
                    xtype: 'button',
                    text: ">>",
                    width: 50,
                    scope: this,
                    style: "margin-bottom:5px;",
                    handler: function () {
                        var grid = this.down("#pojazdy_from");
                        var grid2 = this.down("#pojazdy_to");
                        grid2.store.add(grid.store.getRange());
                        grid.store.removeAll();
                    }
                }, {
                    xtype: 'button',
                    text: "<",
                    width: 50,
                    scope: this,
                    style: "margin-bottom:5px;",
                    handler: function () {
                        var grid = this.down("#pojazdy_from");
                        var grid2 = this.down("#pojazdy_to");
                        var r = grid2.getSelR();
                        if (r == null || r.items.length == 0)
                            return;
                        var selectedItem = r.items[0];
                        grid2.store.remove(selectedItem);
                        grid.store.add(selectedItem);
                    }
                }, {
                    xtype: 'button',
                    text: "<<",
                    width: 50,
                    scope: this,
                    style: "margin-bottom:5px;",
                    handler: function () {
                        var grid = this.down("#pojazdy_from");
                        var grid2 = this.down("#pojazdy_to");
                        grid.store.add(grid2.store.getRange());
                        grid2.store.removeAll();
                    }
                }]
            }, new Ext.ux.grid.GridPanelExd({
                store: new Ext.data.Store({
                    autoLoad: false,
                    proxy: new Ext.data.MemoryProxy({}),
                    reader: new Ext.data.JsonReader({
                        id: "id",
                        rootProperty: ''
                    }, [{
                        name: 'nazwa',
                        mapping: 'nazwa'
                    }])
                }),
                columns: [{
                    header: Lang.nazwa,
                    flex: 1,
                    width: 145,
                    sortable: true,
                    dataIndex: 'nazwa'
                }],
                title: Lang.wybrane.capitalise(),
                height: 390,
                flex: 1,
                frame: true,
                border: true,
                itemId: "pojazdy_to",
                stateId: null,
                stateful: false,
                stripeRows: true,
                enableHdMenu: true,
                selModel: {
                    selType: 'rowmodel',
                    mode: 'SINGLE'
                },
                listeners: {
                    scope: this,
                    rowdblclick: function () {
                        var grid = this.down("#pojazdy_from");
                        var grid2 = this.down("#pojazdy_to");
                        var r = grid2.getSelR();
                        if (r == null || r.items.length == 0)
                            return;
                        var selectedItem = r.items[0];
                        grid2.store.remove(selectedItem);
                        grid.store.add(selectedItem);
                    }
                },
                getSelR: function () {
                    var gridd = this;
                    if (!gridd.getSelectionModel().hasSelection()) {
                        return null;
                    } else {
                        if (gridd.getSelectionModel().getSelected() != null) {
                            return gridd.getSelectionModel().getSelected();
                        }
                    }
                    return null;
                }
            })]
        }
    },
    createPojazdyGrupyPanel: function () {
        return {
            xtype: 'panel',
            itemId: 'pojazdyGrupyPanel',
            frame: false,
            border: false,
            autoWidth: true,
            hidden: true,
            layout: 'hbox',
            layoutConfig: {
                align: "stretch"
            },
            items: [new Ext.ux.grid.GridPanelExd({
                store: new Ext.data.Store({
                    autoLoad: false,
                    proxy: new Ext.data.MemoryProxy({}),
                    reader: new Ext.data.JsonReader({
                        id: "id",
                        rootProperty: ''
                    }, [{
                        name: 'nazwa',
                        mapping: 'nazwa'
                    }])
                }),
                columns: [{
                    header: Lang.nazwa,
                    flex: 1,
                    width: 145,
                    sortable: true,
                    dataIndex: 'nazwa'
                }],
                title: Lang.dostepne.capitalise(),
                height: 390,
                flex: 1,
                frame: true,
                border: true,
                itemId: "pojazdy_grupy_from",
                stateId: null,
                stateful: false,
                stripeRows: true,
                enableHdMenu: true,
                selModel: {
                    selType: 'rowmodel',
                    mode: 'SINGLE'
                },
                listeners: {
                    scope: this,
                    rowdblclick: function () {
                        var grid = this.down("#pojazdy_grupy_from");
                        var grid2 = this.down("#pojazdy_grupy_to");
                        var r = grid.getSelR();
                        if (r == null || r.items.length == 0)
                            return;
                        var selectedItem = r.items[0];
                        grid.store.remove(selectedItem);
                        grid2.store.add(selectedItem);
                    }
                },
                getSelR: function () {
                    var gridd = this;
                    if (!gridd.getSelectionModel().hasSelection()) {
                        return null;
                    } else {
                        if (gridd.getSelectionModel().getSelected() != null) {
                            return gridd.getSelectionModel().getSelected();
                        }
                    }
                    return null;
                }
            }), {
                width: 55,
                xtype: 'panel',
                frame: false,
                border: false,
                bodyStyle: 'padding:5px 5px 5px 5px',
                height: 390,
                layout: {
                    type: 'vbox',
                    pack: "center",
                    align: "stretch"
                },
                items: [{
                    xtype: 'button',
                    text: ">",
                    width: 50,
                    scope: this,
                    style: "margin-bottom:5px;",
                    handler: function () {
                        var grid = this.down("#pojazdy_grupy_from");
                        var grid2 = this.down("#pojazdy_grupy_to");
                        var r = grid.getSelR();
                        if (r == null || r.items.length == 0)
                            return;
                        var selectedItem = r.items[0];
                        grid.store.remove(selectedItem);
                        grid2.store.add(selectedItem);
                    }
                }, {
                    xtype: 'button',
                    text: ">>",
                    width: 50,
                    scope: this,
                    style: "margin-bottom:5px;",
                    handler: function () {
                        var grid = this.down("#pojazdy_grupy_from");
                        var grid2 = this.down("#pojazdy_grupy_to");
                        grid2.store.add(grid.store.getRange());
                        grid.store.removeAll();
                    }
                }, {
                    xtype: 'button',
                    text: "<",
                    width: 50,
                    scope: this,
                    style: "margin-bottom:5px;",
                    handler: function () {
                        var grid = this.down("#pojazdy_grupy_from");
                        var grid2 = this.down("#pojazdy_grupy_to");
                        var r = grid2.getSelR();
                        if (r == null || r.items.length == 0)
                            return;
                        var selectedItem = r.items[0];
                        grid2.store.remove(selectedItem);
                        grid.store.add(selectedItem);
                    }
                }, {
                    xtype: 'button',
                    text: "<<",
                    width: 50,
                    scope: this,
                    style: "margin-bottom:5px;",
                    handler: function () {
                        var grid = this.down("#pojazdy_grupy_from");
                        var grid2 = this.down("#pojazdy_grupy_to");
                        grid.store.add(grid2.store.getRange());
                        grid2.store.removeAll();
                    }
                }]
            }, new Ext.ux.grid.GridPanelExd({
                store: new Ext.data.Store({
                    autoLoad: false,
                    proxy: new Ext.data.MemoryProxy({}),
                    reader: new Ext.data.JsonReader({
                        id: "id",
                        rootProperty: ''
                    }, [{
                        name: 'nazwa',
                        mapping: 'nazwa'
                    }])
                }),
                columns: [{
                    header: Lang.nazwa,
                    flex: 1,
                    width: 145,
                    sortable: true,
                    dataIndex: 'nazwa'
                }],
                title: Lang.wybrane.capitalise(),
                height: 390,
                flex: 1,
                frame: true,
                border: true,
                itemId: "pojazdy_grupy_to",
                stateId: null,
                stateful: false,
                stripeRows: true,
                enableHdMenu: true,
                selModel: {
                    selType: 'rowmodel',
                    mode: 'SINGLE'
                },
                listeners: {
                    scope: this,
                    rowdblclick: function () {
                        var grid = this.down("#pojazdy_grupy_from");
                        var grid2 = this.down("#pojazdy_grupy_to");
                        var r = grid2.getSelR();
                        if (r == null || r.items.length == 0)
                            return;
                        var selectedItem = r.items[0];
                        grid2.store.remove(selectedItem);
                        grid.store.add(selectedItem);
                    }
                },
                getSelR: function () {
                    var gridd = this;
                    if (!gridd.getSelectionModel().hasSelection()) {
                        return null;
                    } else {
                        if (gridd.getSelectionModel().getSelected() != null) {
                            return gridd.getSelectionModel().getSelected();
                        }
                    }
                    return null;
                }
            })]
        }
    },
    createOdbiorcyPanel: function () {
        return {
            xtype: 'panel',
            itemId: 'odbiorcyPanel',
            frame: false,
            border: false,
            autoWidth: true,
            hidden: this.typ == Const.MASS_EDIT_TYPE_POJAZD,
            layout: 'hbox',
            layoutConfig: {
                align: "stretch"
            },
            items: [new Ext.ux.grid.GridPanelExd({
                store: new Ext.data.Store({
                    autoLoad: false,
                    proxy: new Ext.data.MemoryProxy({}),
                    reader: new Ext.data.JsonReader({
                        id: "id",
                        rootProperty: ''
                    }, [{
                        name: 'nazwa',
                        mapping: 'nazwa'
                    }])
                }),
                columns: [{
                    header: Lang.nazwa,
                    flex: 1,
                    width: 145,
                    sortable: true,
                    dataIndex: 'nazwa'
                }],
                title: Lang.dostepne.capitalise(),
                height: 390,
                flex: 1,
                frame: true,
                border: true,
                itemId: "odbiorcy_from",
                stateId: null,
                stateful: false,
                stripeRows: true,
                enableHdMenu: true,
                selModel: {
                    selType: 'rowmodel',
                    mode: 'SINGLE'
                },
                listeners: {
                    scope: this,
                    rowdblclick: function () {
                        var grid = this.down("#odbiorcy_from");
                        var grid2 = this.down("#odbiorcy_to");
                        var r = grid.getSelR();
                        if (r == null || r.items.length == 0)
                            return;
                        var selectedItem = r.items[0];
                        grid.store.remove(selectedItem);
                        grid2.store.add(selectedItem);
                    }
                },
                getSelR: function () {
                    var gridd = this;
                    if (!gridd.getSelectionModel().hasSelection()) {
                        return null;
                    } else {
                        if (gridd.getSelectionModel().getSelected() != null) {
                            return gridd.getSelectionModel().getSelected();
                        }
                    }
                    return null;
                }
            }), {
                width: 55,
                xtype: 'panel',
                frame: false,
                border: false,
                bodyStyle: 'padding:5px 5px 5px 5px',
                height: 390,
                layout: {
                    type: 'vbox',
                    pack: "center",
                    align: "stretch"
                },
                items: [{
                    xtype: 'button',
                    text: ">",
                    width: 50,
                    scope: this,
                    style: "margin-bottom:5px;",
                    handler: function () {
                        var grid = this.down("#odbiorcy_from");
                        var grid2 = this.down("#odbiorcy_to");
                        var r = grid.getSelR();
                        if (r == null || r.items.length == 0)
                            return;
                        var selectedItem = r.items[0];
                        grid.store.remove(selectedItem);
                        grid2.store.add(selectedItem);
                    }
                }, {
                    xtype: 'button',
                    text: ">>",
                    width: 50,
                    scope: this,
                    style: "margin-bottom:5px;",
                    handler: function () {
                        var grid = this.down("#odbiorcy_from");
                        var grid2 = this.down("#odbiorcy_to");
                        grid2.store.add(grid.store.getRange());
                        grid.store.removeAll();
                    }
                }, {
                    xtype: 'button',
                    text: "<",
                    width: 50,
                    scope: this,
                    style: "margin-bottom:5px;",
                    handler: function () {
                        var grid = this.down("#odbiorcy_from");
                        var grid2 = this.down("#odbiorcy_to");
                        var r = grid2.getSelR();
                        if (r == null || r.items.length == 0)
                            return;
                        var selectedItem = r.items[0];
                        grid2.store.remove(selectedItem);
                        grid.store.add(selectedItem);
                    }
                }, {
                    xtype: 'button',
                    text: "<<",
                    width: 50,
                    scope: this,
                    style: "margin-bottom:5px;",
                    handler: function () {
                        var grid = this.down("#odbiorcy_from");
                        var grid2 = this.down("#odbiorcy_to");
                        grid.store.add(grid2.store.getRange());
                        grid2.store.removeAll();
                    }
                }]
            }, new Ext.ux.grid.GridPanelExd({
                store: new Ext.data.Store({
                    autoLoad: false,
                    proxy: new Ext.data.MemoryProxy({}),
                    reader: new Ext.data.JsonReader({
                        id: "id",
                        rootProperty: ''
                    }, [{
                        name: 'nazwa',
                        mapping: 'nazwa'
                    }])
                }),
                columns: [{
                    header: Lang.nazwa,
                    flex: 1,
                    width: 145,
                    sortable: true,
                    dataIndex: 'nazwa'
                }],
                title: Lang.wybrane.capitalise(),
                height: 390,
                flex: 1,
                frame: true,
                border: true,
                itemId: "odbiorcy_to",
                stateId: null,
                stateful: false,
                stripeRows: true,
                enableHdMenu: true,
                selModel: {
                    selType: 'rowmodel',
                    mode: 'SINGLE'
                },
                listeners: {
                    scope: this,
                    rowdblclick: function () {
                        var grid = this.down("#odbiorcy_from");
                        var grid2 = this.down("#odbiorcy_to");
                        var r = grid2.getSelR();
                        if (r == null || r.items.length == 0)
                            return;
                        var selectedItem = r.items[0];
                        grid2.store.remove(selectedItem);
                        grid.store.add(selectedItem);
                    }
                },
                getSelR: function () {
                    var gridd = this;
                    if (!gridd.getSelectionModel().hasSelection()) {
                        return null;
                    } else {
                        if (gridd.getSelectionModel().getSelected() != null) {
                            return gridd.getSelectionModel().getSelected();
                        }
                    }
                    return null;
                }
            })]
        }
    },
    createOdbiorcyGrupyPanel: function () {
        return {
            xtype: 'panel',
            itemId: 'odbiorcyGrupyPanel',
            frame: false,
            border: false,
            autoWidth: true,
            hidden: true,
            layout: 'hbox',
            layoutConfig: {
                align: "stretch"
            },
            items: [new Ext.ux.grid.GridPanelExd({
                store: new Ext.data.Store({
                    autoLoad: false,
                    proxy: new Ext.data.MemoryProxy({}),
                    reader: new Ext.data.JsonReader({
                        id: "id",
                        rootProperty: ''
                    }, [{
                        name: 'nazwa',
                        mapping: 'nazwa'
                    }])
                }),
                columns: [{
                    header: Lang.nazwa,
                    flex: 1,
                    width: 145,
                    sortable: true,
                    dataIndex: 'nazwa'
                }],
                title: Lang.dostepne.capitalise(),
                height: 390,
                flex: 1,
                frame: true,
                border: true,
                itemId: "odbiorcy_grupy_from",
                stateId: null,
                stateful: false,
                stripeRows: true,
                enableHdMenu: true,
                selModel: {
                    selType: 'rowmodel',
                    mode: 'SINGLE'
                },
                listeners: {
                    scope: this,
                    rowdblclick: function () {
                        var grid = this.down("#odbiorcy_grupy_from");
                        var grid2 = this.down("#odbiorcy_grupy_to");
                        var r = grid.getSelR();
                        if (r == null || r.items.length == 0)
                            return;
                        var selectedItem = r.items[0];
                        grid.store.remove(selectedItem);
                        grid2.store.add(selectedItem);
                    }
                },
                getSelR: function () {
                    var gridd = this;
                    if (!gridd.getSelectionModel().hasSelection()) {
                        return null;
                    } else {
                        if (gridd.getSelectionModel().getSelected() != null) {
                            return gridd.getSelectionModel().getSelected();
                        }
                    }
                    return null;
                }
            }), {
                width: 55,
                xtype: 'panel',
                frame: false,
                border: false,
                bodyStyle: 'padding:5px 5px 5px 5px',
                height: 390,
                layout: {
                    type: 'vbox',
                    pack: "center",
                    align: "stretch"
                },
                items: [{
                    xtype: 'button',
                    text: ">",
                    width: 50,
                    scope: this,
                    style: "margin-bottom:5px;",
                    handler: function () {
                        var grid = this.down("#odbiorcy_grupy_from");
                        var grid2 = this.down("#odbiorcy_grupy_to");
                        var r = grid.getSelR();
                        if (r == null || r.items.length == 0)
                            return;
                        var selectedItem = r.items[0];
                        grid.store.remove(selectedItem);
                        grid2.store.add(selectedItem);
                    }
                }, {
                    xtype: 'button',
                    text: ">>",
                    width: 50,
                    scope: this,
                    style: "margin-bottom:5px;",
                    handler: function () {
                        var grid = this.down("#odbiorcy_grupy_from");
                        var grid2 = this.down("#odbiorcy_grupy_to");
                        grid2.store.add(grid.store.getRange());
                        grid.store.removeAll();
                    }
                }, {
                    xtype: 'button',
                    text: "<",
                    width: 50,
                    scope: this,
                    style: "margin-bottom:5px;",
                    handler: function () {
                        var grid = this.down("#odbiorcy_grupy_from");
                        var grid2 = this.down("#odbiorcy_grupy_to");
                        var r = grid2.getSelR();
                        if (r == null || r.items.length == 0)
                            return;
                        var selectedItem = r.items[0];
                        grid2.store.remove(selectedItem);
                        grid.store.add(selectedItem);
                    }
                }, {
                    xtype: 'button',
                    text: "<<",
                    width: 50,
                    scope: this,
                    style: "margin-bottom:5px;",
                    handler: function () {
                        var grid = this.down("#odbiorcy_grupy_from");
                        var grid2 = this.down("#odbiorcy_grupy_to");
                        grid.store.add(grid2.store.getRange());
                        grid2.store.removeAll();
                    }
                }]
            }, new Ext.ux.grid.GridPanelExd({
                store: new Ext.data.Store({
                    autoLoad: false,
                    proxy: new Ext.data.MemoryProxy({}),
                    reader: new Ext.data.JsonReader({
                        id: "id",
                        rootProperty: ''
                    }, [{
                        name: 'nazwa',
                        mapping: 'nazwa'
                    }])
                }),
                columns: [{
                    header: Lang.nazwa,
                    flex: 1,
                    width: 145,
                    sortable: true,
                    dataIndex: 'nazwa'
                }],
                title: Lang.wybrane.capitalise(),
                height: 390,
                flex: 1,
                frame: true,
                border: true,
                itemId: "odbiorcy_grupy_to",
                stateId: null,
                stateful: false,
                stripeRows: true,
                enableHdMenu: true,
                selModel: {
                    selType: 'rowmodel',
                    mode: 'SINGLE'
                },
                listeners: {
                    scope: this,
                    rowdblclick: function () {
                        var grid = this.down("#odbiorcy_grupy_from");
                        var grid2 = this.down("#odbiorcy_grupy_to");
                        var r = grid2.getSelR();
                        if (r == null || r.items.length == 0)
                            return;
                        var selectedItem = r.items[0];
                        grid2.store.remove(selectedItem);
                        grid.store.add(selectedItem);
                    }
                },
                getSelR: function () {
                    var gridd = this;
                    if (!gridd.getSelectionModel().hasSelection()) {
                        return null;
                    } else {
                        if (gridd.getSelectionModel().getSelected() != null) {
                            return gridd.getSelectionModel().getSelected();
                        }
                    }
                    return null;
                }
            })]
        }
    },
    showW: function () {
        if (this.typ == Const.MASS_EDIT_TYPE_POJAZD) {
            sendAjaxDataJsonRpcL("rest/pojazdy/getAll", {}, this, function (paretntOpData) {
                if (paretntOpData.status) {
                    parentData = paretntOpData.data;
                    sendAjaxDataJsonRpcL("rest/grupy/getList?type=" + Const.GROUP_TYPE_POJAZD, {}, this, function (data) {
                        if (data.status) {
                            data = data.data;
                            var grid = this.down("#pojazdy_from");
                            var grid2 = this.down("#pojazdy_to");
                            grid.store.loadData(parentData);
                            grid2.store.loadData([]);
                            var grid3 = this.down("#pojazdy_grupy_from");
                            var grid4 = this.down("#pojazdy_grupy_to");
                            grid3.store.loadData(data);
                            grid4.store.loadData([]);
                            this.show();
                            this.center();
                        } else {
                            Ext.MessageBox.alert(Lang.error, data.error);
                        }
                    })
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
            })
        } else if (this.typ == Const.MASS_EDIT_TYPE_ODBIORCA) {
            sendAjaxDataJsonRpcL("rest/odbiorcy/getAll", {}, this, function (paretntOpData) {
                if (paretntOpData.status) {
                    parentData = paretntOpData.data;
                    sendAjaxDataJsonRpcL("rest/grupy/getList?type=" + Const.GROUP_TYPE_ODBIORCA, {}, this, function (data) {
                        if (data.status) {
                            data = data.data;
                            var grid = this.down("#odbiorcy_from");
                            var grid2 = this.down("#odbiorcy_to");
                            grid.store.loadData(parentData);
                            grid2.store.loadData([]);
                            var grid3 = this.down("#odbiorcy_grupy_from");
                            var grid4 = this.down("#odbiorcy_grupy_to");
                            grid3.store.loadData(data);
                            grid4.store.loadData([]);
                            this.show();
                            this.center();
                        } else {
                            Ext.MessageBox.alert(Lang.error, data.error);
                        }
                    })
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
            })
        }
    },
    saveOp: function () {
        if (this.typ == Const.MASS_EDIT_TYPE_ODBIORCA) {
            let byeach = this.down("#byeach").getValue();
            let list = (byeach ? this.down("#odbiorcy_to").store.getRange() : this.down("#odbiorcy_grupy_to").store.getRange())
            if (!list.length) {
                Ext.MessageBox.alert(Lang.error, Lang.wybierz_co_najmniej_jeden_obiekt);
                return;
            }
            let ids = []
            list.forEach(el => {
                ids.push(el.data.id)
            })
            sendAjaxDataJsonRpcL("rest/odbiorcy/saveMassEdit", {
                fields: this.formFields,
                byeach: byeach,
                applyto: ids
            }, this, function (data) {
                if (data.status) {
                    this.closeOp()
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
            });
        } else if (this.typ == Const.MASS_EDIT_TYPE_POJAZD) {
            let byeach = this.down("#byeach").getValue();
            let list = (byeach ? this.down("#pojazdy_to").store.getRange() : this.down("#pojazdy_grupy_to").store.getRange());
            if (!list.length) {
                Ext.MessageBox.alert(Lang.error, Lang.wybierz_co_najmniej_jeden_obiekt);
                return;
            }
            let ids = []
            list.forEach(el => {
                ids.push(el.data.id)
            })
            sendAjaxDataJsonRpcL("rest/pojazdy/saveMassEdit", {
                fields: this.formFields,
                byeach: byeach,
                applyto: ids
            }, this, function (data) {
                if (data.status) {
                    this.closeOp()
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
            });
        }
    },
    closeOp: function () {
        this.fireEvent('closeSuccess');
        this.close();
    }
});