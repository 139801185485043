Ext.define('WindowSterownikiOpt', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.sterownik.capitalise(),
    width: 760,
    height: 650,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    scrollable: true,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        let ogolne = {
            title: Lang.ogolne.capitalise(),
            xtype: 'form',
            id: this.cid + 'formpanel1',
            bodyStyle: 'padding:10px 10px 10px 10px',
            frame: true,
            autoWidth: true,
            scrollable: true,
            defaults: {
                anchor: "0"
            },
            items: [{
                anchor: "0",
                layout: 'column',
                border: false,
                items: [{
                    columnWidth: WindowExdMobileDEVICEV ? 1 : .5,
                    border: false,
                    layout: 'anchor',
                    labelWidth: 110,
                    defaults: {
                        anchor: "-10",
                        labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                    },
                    items: [{
                        id: this.cid + "nazwa",
                        name: "nazwa",
                        xtype: 'textfieldexd',
                        fieldLabel: Lang.nazwa,
                        allowBlank: false,
                        maxLength: 16,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        disabled: isValidOperatorFirmaLimit()
                    }, {
                        id: this.cid + "serialnumber",
                        name: "serialnumber",
                        xtype: 'textfieldexd',
                        fieldLabel: Lang.numer_seryjny,
                        allowBlank: false,
                        maxLength: 16,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        disabled: true
                    }, {
                        id: this.cid + "idzbiornik",
                        xtype: 'comboexd',
                        fieldLabel: Lang.zbiornik,
                        valueField: 'id',
                        displayField: 'nazwa',
                        store: Ext.create('Ext.data.Store', {
                            fields: ['id', 'nazwa'],
                            sorters: [{
                                property: 'nazwa',
                                direction: "ASC"
                            }],
                            reader: {
                                type: 'json',
                                rootProperty: ''
                            }
                        }),
                        triggerAction: 'all',
                        resizable: false,
                        editable: false,
                        mode: "local",
                        allowBlank: false,
                        blankText: Lang.pole_nie_moze_byc_puste
                    }, {
                        xtype: 'radiogroupexd',
                        id: this.cid + "active",
                        name: "active",
                        baseCls: 'x-radio-column',
                        fieldLabel: Lang.active,
                        columns: 2,
                        vertical: false,
                        items: [{
                            boxLabel: Lang.tak.capitalise(),
                            inputValue: true,
                            value: true
                        }, {
                            boxLabel: Lang.nie.capitalise(),
                            inputValue: false
                        }]
                    }, {
                        id: this.cid + "devid",
                        name: "devid",
                        xtype: 'textfieldexd',
                        fieldLabel: Lang.typ,
                        disabled: true
                    }, {
                        id: this.cid + "version",
                        name: "version",
                        xtype: 'textfieldexd',
                        fieldLabel: Lang.wersja,
                        disabled: true
                    }, {
                        id: this.cid + "opis",
                        name: "opis",
                        xtype: 'textareaexd',
                        height: 100,
                        fieldLabel: Lang.opis,
                        allowBlank: true,
                        blankText: Lang.pole_nie_moze_byc_puste,
                        disabled: isValidOperatorFirmaLimit()
                    }]
                }]
            }]
        };
        let ogolnefields = [{
            xtype: 'radiogroupexd',
            id: this.cid + "tankowanieoff",
            name: "tankowanieoff",
            baseCls: 'x-radio-column',
            fieldLabel: Lang.tankowanie,
            columns: 2,
            vertical: false,
            items: [{
                boxLabel: Lang.wlaczone.capitalise(),
                inputValue: false
            }, {
                boxLabel: Lang.wylaczone.capitalise(),
                inputValue: true,
                value: true
            }]
        }, {
            xtype: 'radiogroupexd',
            id: this.cid + "iszaladunek",
            name: "iszaladunek",
            baseCls: 'x-radio-column',
            fieldLabel: Lang.zaladunkowy,
            hidden: Const.OPTIMALFLAG || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
            columns: 2,
            vertical: false,
            items: [{
                boxLabel: Lang.tak.capitalise(),
                inputValue: true
            }, {
                boxLabel: Lang.nie.capitalise(),
                inputValue: false,
                value: true
            }]
        }, {
            id: this.cid + "tconnect",
            name: "tconnect",
            xtype: 'comboexd',
            store: [[Const.STEROWNIK_CONNECT_NO, Lang.niepodlaczony], [Const.STEROWNIK_CONNECT_USB, Lang.podlaczony_usb], [Const.STEROWNIK_CONNECT_GSM, Lang.podlaczony_gsm], [Const.STEROWNIK_CONNECT_WIFI, Lang.podlaczony_wifi]],
            triggerAction: 'all',
            resizable: false,
            mode: "local",
            editable: false,
            fieldLabel: Lang.typ_podlaczenia,
            allowBlank: false,
            blankText: Lang.pole_nie_moze_byc_puste,
            disabled: true
        }, {
            id: this.cid + "adres",
            name: "adres",
            xtype: 'numberfieldexd',
            minValue: 1,
            maxValue: 62,
            fieldLabel: Lang.adres_can,
            allowBlank: false,
            blankText: Lang.pole_nie_moze_byc_puste,
            disabled: true
        }, {
            id: this.cid + "mobileprovider",
            name: "mobileprovider",
            xtype: 'comboexd',
            store: [[Const.STEROWNIK_MOBILE_PROVIDER_INNY, Lang.inny], [Const.STEROWNIK_MOBILE_PROVIDER_1NCE, Lang.onence]],
            triggerAction: 'all',
            resizable: false,
            mode: "local",
            editable: false,
            fieldLabel: Lang.mobile_provider,
            allowBlank: false,
            blankText: Lang.pole_nie_moze_byc_puste,
            disabled: false,
            hidden: false,
            cls: "x-item-disabled-sec"
        }, {
            id: this.cid + "localization",
            name: "localization",
            xtype: 'textfieldexd',
            fieldLabel: Lang.localization,
            allowBlank: !Const.ENABLEGIRTEKA,
            blankText: Lang.pole_nie_moze_byc_puste,
            cls: "x-item-disabled-sec",
            hidden: !Const.ENABLEGIRTEKA
        }, {
            xtype: "checkbox",
            boxLabel: Lang.forceupdate,
            id: this.cid + "forceupdate"
        }, {
            xtype: "checkboxexd",
            boxLabel: Lang.zapytaj_o_dane_archiwalne,
            id: this.cid + "archiveon",
            hidden: !Const.SERVICEMODE,
            listeners: {
                change: function (el, newValue) {
                    Ext.getCmp(this.cid + "archivefrom").setHidden(!newValue);
                    Ext.getCmp(this.cid + "archivefrom").allowBlank = !newValue;
                },
                scope: this
            }
        }, {
            id: this.cid + "archivefrom",
            name: "archivefrom",
            xtype: 'datefieldexdmod',
            fieldLabel: Lang.data_od,
            allowBlank: true,
            blankText: Lang.pole_nie_moze_byc_puste,
            hidden: true
        }, {
            id: this.cid + "numerickeyboardrefuelling",
            xtype: "checkboxexd",
            hideLabel: true,
            boxLabel: Lang.klawiatura_numeryczna_do_informacji_dodatkowych,
            hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
        }, {
            id: this.cid + "supercodebutton",
            name: "supercodebutton",
            xtype: "button",
            text: Lang.ustaw_kod_superoperatora,
            hidden: AppOPERATORROLA != Const.OPERATOR_P_SERWIS || Const.AGGREGATEDCORE,
            handler: function () {
                var supercodeWindow = Ext.create('WindowSupercodeSterownik');
                supercodeWindow.showW(this.obiektid);
            },
            scope: this
        }, {
            id: this.cid + "replacesnbutton",
            name: "replacesnbutton",
            xtype: "button",
            text: Lang.serwis_sterownika,
            margin: '10 0 0 0',
            hidden: true,
            handler: function () {
                var serviceWindow = Ext.create('WindowServiceSterownik');
                serviceWindow.showW(this.obiektid);
            },
            scope: this
        }];
        if (WindowExdMobileDEVICEV)
            ogolne.items[0].items[0].items.push(...ogolnefields);
        else
            ogolne.items[0].items.push({
                columnWidth: .5,
                layout: 'anchor',
                labelWidth: 110,
                border: false,
                defaults: {
                    anchor: "0",
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                items: [...ogolnefields]
            });
        this.items = [{
            xtype: 'tabpanel',
            autoWidth: true,
            height: 260,
            border: false,
            activeTab: 0,
            items: [ogolne, {
                title: Lang.polaczenie.capitalise(),
                xtype: 'form',
                id: this.cid + 'formpanel2',
                bodyStyle: 'padding:10px 10px 10px 10px',
                frame: true,
                autoWidth: true,
                scrollable: true,
                defaults: {
                    anchor: "0",
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                items: [{
                    id: this.cid + "tmsintervalquery",
                    name: "tmsintervalquery",
                    xtype: 'numberfieldexd',
                    fieldLabel: Lang.okres_polaczenia + "[min]",
                    allowBlank: false,
                    allowDecimal: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    minValue: 1,
                    maxValue: 99999
                }, {
                    id: this.cid + "tmsintervalqueryerror",
                    name: "tmsintervalqueryerror",
                    xtype: 'numberfieldexd',
                    fieldLabel: Lang.okres_polaczenia_gdy_blad + "[min]",
                    allowBlank: false,
                    allowDecimal: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    minValue: 1,
                    maxValue: 99999
                }, {
                    id: this.cid + "tmsintervalqueryerrorresetmodem",
                    name: "tmsintervalqueryerrorresetmodem",
                    xtype: 'numberfieldexd',
                    fieldLabel: Lang.okres_polaczenia_gdy_blad_modem_reset + "[min]",
                    allowBlank: false,
                    allowDecimal: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    minValue: 1,
                    maxValue: 99999,
                    validator: Ext.Function.bind(function (v) {
                        var tmsintervalquery = Ext.getCmp(this.cid + "tmsintervalquery").getValue();
                        var tmsintervalqueryerror = Ext.getCmp(this.cid + "tmsintervalqueryerror").getValue();
                        var tmsintervalqueryerrorresetmodem = Ext.getCmp(this.cid + "tmsintervalqueryerrorresetmodem").getValue();
                        return (tmsintervalqueryerrorresetmodem > tmsintervalqueryerror && tmsintervalqueryerrorresetmodem > tmsintervalquery) ? true : Lang.okres_resetu_ma_byc_wiekszy_od_okresow_polaczenia;
                    }, this, [])
                }, {
                    xtype: 'radiogroupexd',
                    id: this.cid + "tconnecteveryevent",
                    name: "tconnecteveryevent",
                    baseCls: 'x-radio-column',
                    fieldLabel: Lang.polaczenie_co_zdarzenie,
                    columns: 2,
                    vertical: false,
                    items: [{
                        boxLabel: Lang.wlaczone.capitalise(),
                        inputValue: true
                    }, {
                        boxLabel: Lang.wylaczone.capitalise(),
                        inputValue: false,
                        value: true
                    }]
                }]
            }, {
                title: Lang.parametry.capitalise(),
                xtype: 'form',
                id: this.cid + 'formpanel3',
                bodyStyle: 'padding:5px 10px 10px 10px',
                frame: true,
                autoWidth: true,
                scrollable: true,
                defaults: {
                    anchor: "0"
                },
                items: [{
                    id: this.cid + 'tprinttype',
                    xtype: 'comboexd',
                    store: new Ext.data.ArrayStore({
                        data: [[Const.STEROWNIK_TPRINTERTYPE_NO, Lang.brak_drukarki], [Const.STEROWNIK_TPRINTERTYPE_TYP1, Lang.drukarka_typ1], [Const.STEROWNIK_TPRINTERTYPE_TYP2, Lang.drukarka_typ2]],
                        fields: ["value", "text"]
                    }),
                    hidden: Const.OPTIMALFLAG || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                    displayField: "text",
                    valueField: "value",
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    editable: false,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    fieldLabel: Lang.typ_drukarki
                }, {
                    xtype: 'radiogroupexd',
                    id: this.cid + "tileusezadane",
                    name: "tileusezadane",
                    fieldLabel: Lang.zatankowana_ilosc_z_wprowadzenia,
                    columns: 2,
                    vertical: false,
                    hidden: Const.OPTIMALFLAG || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                    items: [{
                        boxLabel: Lang.z_wprowadzonej_ilosci.capitalise(),
                        inputValue: true
                    }, {
                        boxLabel: Lang.z_ilosci_z_pulsera.capitalise(),
                        inputValue: false,
                        value: true
                    }]
                }, {
                    anchor: "0",
                    layout: "column",
                    border: false,
                    items: [{
                        columnWidth: 1,
                        border: false,
                        layout: 'anchor',
                        defaults: {
                            labelWidth: 180,
                            anchor: "-10",
                            labelAlign: "top"
                        },
                        items: [{
                            id: this.cid + "intervalgetskpstates",
                            name: "intervalgetskpstates",
                            xtype: 'numberfieldexd',
                            fieldLabel: Lang.okres_zbierania_pomiarow_skp + "[min]",
                            allowBlank: false,
                            allowDecimal: false,
                            blankText: Lang.pole_nie_moze_byc_puste,
                            labelAlign: 'top',
                            minValue: 1,
                            maxValue: 99999,
                            hidden: Const.BASICFLAG || Const.STANDARDFLAG || (Const.CLOUDFLAG && (AppOPERATOR.id != 1)) || Const.STARTERFLAG
                        }, {
                            id: this.cid + "czast1",
                            name: "czast1",
                            xtype: 'numberfieldexd',
                            fieldLabel: Lang.czast1label,
                            allowBlank: false,
                            blankText: Lang.pole_nie_moze_byc_puste,
                            minValue: 1,
                            maxValue: 9999999
                        }, {
                            id: this.cid + "czast2",
                            name: "czast2",
                            xtype: 'numberfieldexd',
                            fieldLabel: Lang.czast2label,
                            allowBlank: false,
                            blankText: Lang.pole_nie_moze_byc_puste,
                            minValue: 1,
                            maxValue: 9999999
                        }, {
                            id: this.cid + "czast3",
                            name: "czast3",
                            xtype: 'numberfieldexd',
                            fieldLabel: Lang.czast3label,
                            allowBlank: false,
                            blankText: Lang.pole_nie_moze_byc_puste,
                            minValue: 1,
                            maxValue: 9999999
                        }, {
                            xtype: 'panel',
                            layout: {
                                type: 'hbox',
                                align: 'end'
                            },
                            bodyStyle: 'padding:0px 0px 5px 0px',
                            frame: false,
                            border: false,
                            items: [{
                                flex: 2,
                                id: this.cid + "wsplczynnikkalibracji",
                                name: "wsplczynnikkalibracji",
                                xtype: 'numberfieldexd',
                                fieldLabel: Lang.wspolczynnik_kalibracji + "[ml/imp]",
                                labelWidth: 180,
                                labelAlign: "top",
                                allowBlank: true,
                                decimalPrecision: 5,
                                blankText: Lang.pole_nie_moze_byc_puste,
                                minValue: 0.00001,
                                maxValue: 9999999,
                                disabled: true
                            }, {
                                flex: 1,
                                xtype: "button",
                                text: Lang.zmiana,
                                hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                                handler: function () {
                                    var e = Ext.getCmp(this.cid + "wsplczynnikkalibracji");
                                    e.setDisabled(false);
                                },
                                scope: this
                            }]
                        }, {
                            id: this.cid + "disableaskforlicznikinhour",
                            xtype: "checkboxexd",
                            hideLabel: true,
                            boxLabel: Lang.nie_pytac_o_stan_licznika_pojazdu_przy_ponownym_tankowaniu_w_okresie_1h,
                            hidden: Const.STARTERFLAG
                        }, {
                            xtype: 'radiogroupexd',
                            id: this.cid + "ignoreerrorsensortemp",
                            name: "ignoreerrorsensortemp",
                            fieldLabel: Lang.zachowanie_przy_bledzie_modulu_kompensacji_temperatury,
                            hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                            items: [{
                                boxLabel: Lang.przyjecie_temperatury_15_st.capitalise(),
                                inputValue: true
                            }, {
                                boxLabel: Lang.blokada_tankowania.capitalise(),
                                inputValue: false,
                                value: true
                            }]
                        }]
                    }]
                }]
            }, {
                title: Lang.harmonogram.capitalise(),
                xtype: 'form',
                id: this.cid + 'formpanel4',
                bodyStyle: 'padding:10px 10px 10px 10px',
                frame: true,
                autoWidth: true,
                scrollable: true,
                defaults: {
                    anchor: "0",
                    labelWidth: 250,
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                items: [{
                    xtype: 'radiogroupexd',
                    id: this.cid + "harmonogramon",
                    name: "active",
                    baseCls: 'x-radio-column',
                    fieldLabel: Lang.active,
                    columns: 2,
                    vertical: false,
                    items: [{
                        boxLabel: Lang.tak.capitalise(),
                        inputValue: true,
                        value: true
                    }, {
                        boxLabel: Lang.nie.capitalise(),
                        inputValue: false
                    }],
                    listeners: {
                        scope: this,
                        change: function (radiogroup, newValue) {
                            Ext.getCmp(this.cid + "harmonogrampin").setDisabled(!newValue);
                            Ext.getCmp(this.cid + "monday").setDisabled(!newValue);
                            Ext.getCmp(this.cid + "tuesday").setDisabled(!newValue);
                            Ext.getCmp(this.cid + "wednesday").setDisabled(!newValue);
                            Ext.getCmp(this.cid + "thursday").setDisabled(!newValue);
                            Ext.getCmp(this.cid + "friday").setDisabled(!newValue);
                            Ext.getCmp(this.cid + "saturday").setDisabled(!newValue);
                            Ext.getCmp(this.cid + "sunday").setDisabled(!newValue);
                        }
                    }
                }, {
                    xtype: 'label',
                    style: 'display:block;margin-bottom:5px;margin-right:5px',
                    text: Lang.godziny_aktywnosci_wskazywane_co_15_minut

                }, {
                    xtype: 'label',
                    style: 'display:block;margin-bottom:10px;margin-right:5px',
                    text: Lang.dopuszczalne_formaty_harmonogram
                }, {
                    id: this.cid + "monday",
                    name: "monday",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.poniedzialek,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    id: this.cid + "tuesday",
                    name: "tuesday",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.wtorek,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    id: this.cid + "wednesday",
                    name: "wednesday",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.sroda,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    id: this.cid + "thursday",
                    name: "thursday",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.czwartek,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    id: this.cid + "friday",
                    name: "friday",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.piatek,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    id: this.cid + "saturday",
                    name: "saturday",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.sobota,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    id: this.cid + "sunday",
                    name: "sunday",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.niedziela,
                    blankText: Lang.pole_nie_moze_byc_puste
                }, {
                    id: this.cid + "harmonogrampin",
                    name: "pin",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.pin_odblokowania,
                    maxLength: 4,
                    minLength: 4,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    minvalue: 1,
                    maxValue: 9999,
                    maskRe: /[0-9]/,
                    validator: function (v) {
                        if (/^-?[0-9]*$/.test(v)) {
                            if (/[^(\b(?:0000))]/.test(v)) {
                                return true;
                            } else {
                                return Lang.mozliwe_wszystkie_kombinacje_oprocz_4_zer;
                            }
                        } else {
                            return Lang.kod_pin_musi_miec_n_cyfr;
                        }
                    }
                }, {
                    xtype: 'label',
                    style: 'display:block;margin-top:10px;margin-bottom:5px;margin-right:5px',
                    text: Lang.pin_harmonogram_dzialanie
                }, {
                    xtype: 'label',
                    style: 'display:block;margin-right:5px',
                    text: Lang.uzycie_kodu_pin_na_kdp_autoryzacja
                }]
            }, {
                title: Lang.sim_info.capitalise(),
                xtype: 'form',
                id: this.cid + 'formpanel5',
                bodyStyle: 'padding:10px 10px 10px 10px',
                frame: true,
                autoWidth: true,
                scrollable: true,
                disabled: false,
                hidden: false,
                defaults: {
                    anchor: "0",
                    labelWidth: 250,
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                items: [{
                    id: this.cid + "simiccid",
                    name: "simiccid",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.sim_iccid,
                    editable: false
                }, {
                    id: this.cid + "simimsi",
                    name: "simimsi",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.sim_imsi,
                    editable: false
                }, {
                    id: this.cid + "simimei",
                    name: "simimei",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.sim_imei,
                    editable: false
                }, {
                    id: this.cid + "simmsisdn",
                    name: "simmsisdn",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.sim_msisdn,
                    editable: false
                }, {
                    id: this.cid + "simquota",
                    name: "simquota",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.sim_quota,
                    editable: false
                }, {
                    id: this.cid + "simexpirydate",
                    name: "simexpirydate",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.waznosc,
                    editable: false
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp,
            disabled: Const.AGGREGATEDCORE
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid, klonowanie, serialnumber) {
        if (!validPrivilegesOperatorComplex("access.manage.sterowniki")) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.serialnumber = serialnumber || false;
        this.klonowanie = klonowanie || false;
        this.obiektid = obiektid;
        this.obiekt = null;
        sendAjaxDataJsonRpcL("rest/sterowniki/getJointObjectForEdit", {
            id: this.obiektid
        }, this, function (data) {
            if (data.status) {
                data = data.data;
                this.obiekt = data;
                sendAjaxDataJsonRpcL("rest/zbiorniki/getList", {
                    idinclude: this.obiekt != null ? this.obiekt.idzbiornik : 0
                }, this, function (data) {
                    if (data.status) {
                        this.show();
                        this.center();
                        this.setKeyMap({
                            'ESC': {
                                scope: this,
                                handler: this.closeOp
                            }
                        });
                        Ext.getCmp(this.cid + 'idzbiornik').getStore().loadData(data.data);
                        if (this.obiekt != null) {
                            if (this.klonowanie) {
                                this.obiekt.nazwa += (this.obiektid + 1);
                                this.obiektid = 0;
                            }
                            Ext.getCmp(this.cid + "forceupdate").setValue(false);
                            Ext.getCmp(this.cid + "nazwa").setValue(this.obiekt.nazwadsp);
                            Ext.getCmp(this.cid + "serialnumber").setValue(data.serialnumber)
                            Ext.getCmp(this.cid + "opis").setValue(this.obiekt.opis);
                            Ext.getCmp(this.cid + "active").setValue2(this.obiekt.active);
                            Ext.getCmp(this.cid + "tconnect").setValue(this.obiekt.tconnect);
                            Ext.getCmp(this.cid + "mobileprovider").setValue(this.obiekt.mobileprovider);
                            Ext.getCmp(this.cid + "adres").setValue(this.obiekt.adres);
                            Ext.getCmp(this.cid + "localization").setValue(data.localization);
                            Ext.getCmp(this.cid + "version").setValue(this.obiekt.version);
                            Ext.getCmp(this.cid + "devid").setValue(this.obiekt.devid);
                            Ext.getCmp(this.cid + "tankowanieoff").setValue2(this.obiekt.tankowanieoff);
                            Ext.getCmp(this.cid + "iszaladunek").setValue2(this.obiekt.iszaladunek);
                            Ext.getCmp(this.cid + "tprinttype").setValue2(this.obiekt.tprinttype);
                            Ext.getCmp(this.cid + "tmsintervalquery").setValue(this.obiekt.tmsintervalquery);
                            Ext.getCmp(this.cid + "tmsintervalqueryerror").setValue(this.obiekt.tmsintervalqueryerror);
                            Ext.getCmp(this.cid + "tmsintervalqueryerrorresetmodem").setValue(this.obiekt.tmsintervalqueryerrorresetmodem);
                            Ext.getCmp(this.cid + "tileusezadane").setValue2(this.obiekt.tileusezadane);
                            Ext.getCmp(this.cid + "ignoreerrorsensortemp").setValue2(this.obiekt.ignoreerrorsensortemp);
                            Ext.getCmp(this.cid + "tconnecteveryevent").setValue2(this.obiekt.tconnecteveryevent);
                            Ext.getCmp(this.cid + "intervalgetskpstates").setValue(this.obiekt.intervalgetskpstates);
                            Ext.getCmp(this.cid + "numerickeyboardrefuelling").setValue(this.obiekt.numerickeyboardrefuelling);
                            Ext.getCmp(this.cid + "disableaskforlicznikinhour").setValue(this.obiekt.disableaskforlicznikinhour);
                            Ext.getCmp(this.cid + "czast1").setValue(this.obiekt.slot.czast1);
                            Ext.getCmp(this.cid + "czast2").setValue(this.obiekt.slot.czast2);
                            Ext.getCmp(this.cid + "czast3").setValue(this.obiekt.slot.czast3);
                            Ext.getCmp(this.cid + "wsplczynnikkalibracji").setValue(this.obiekt.slot.wsplczynnikkalibracjiv);
                            Ext.getCmp(this.cid + 'idzbiornik').setValue(this.obiekt.zbiornik.id);
                            Ext.getCmp(this.cid + "replacesnbutton").setHidden(AppOPERATORROLA != Const.OPERATOR_P_SERWIS || !Const.CLOUDFLAG || Const.AGGREGATEDCORE)
                            Ext.getCmp(this.cid + "harmonogramon").setValue(this.obiekt.harmonogramon);
                            Ext.getCmp(this.cid + "harmonogrampin").setValue(this.obiekt.harmonogrampin);
                            Ext.getCmp(this.cid + "simiccid").setValue(this.obiekt.simiccid);
                            Ext.getCmp(this.cid + "simimsi").setValue(this.obiekt.simimsi);
                            Ext.getCmp(this.cid + "simimei").setValue(this.obiekt.simimei);
                            Ext.getCmp(this.cid + "simquota").setValue(this.obiekt.simquota);
                            Ext.getCmp(this.cid + "simmsisdn").setValue(this.obiekt.simmsisdn);
                            Ext.getCmp(this.cid + "simexpirydate").setValue(this.obiekt.simexpirydate);
                            if (this.obiekt.harmonogram != null) {
                                Ext.getCmp(this.cid + "monday").setValue(this.obiekt.harmonogram.monday);
                                Ext.getCmp(this.cid + "tuesday").setValue(this.obiekt.harmonogram.tuesday);
                                Ext.getCmp(this.cid + "wednesday").setValue(this.obiekt.harmonogram.wednesday);
                                Ext.getCmp(this.cid + "thursday").setValue(this.obiekt.harmonogram.thursday);
                                Ext.getCmp(this.cid + "friday").setValue(this.obiekt.harmonogram.friday);
                                Ext.getCmp(this.cid + "saturday").setValue(this.obiekt.harmonogram.saturday);
                                Ext.getCmp(this.cid + "sunday").setValue(this.obiekt.harmonogram.sunday);
                            }
                            if (this.obiekt.archivefrom != null) {
                                Ext.getCmp(this.cid + "archivefrom").setValue(this.obiekt.archivefrom);
                                Ext.getCmp(this.cid + "archiveon").setValue(true);
                            }
                        } else {
                            Ext.getCmp(this.cid + "nazwa").setValue(null);
                            Ext.getCmp(this.cid + "nazwa").clearInvalid();
                            Ext.getCmp(this.cid + "serialnumber").setValue(this.serialnumber ? this.serialnumber : null);
                            Ext.getCmp(this.cid + "serialnumber").clearInvalid();
                            Ext.getCmp(this.cid + "forceupdate").setValue(false);
                            Ext.getCmp(this.cid + "tankowanieoff").setValue2(false);
                            Ext.getCmp(this.cid + "tankowanieoff").clearInvalid();
                            Ext.getCmp(this.cid + "iszaladunek").setValue2(false);
                            Ext.getCmp(this.cid + "iszaladunek").clearInvalid();
                            Ext.getCmp(this.cid + "active").setValue2(true);
                            Ext.getCmp(this.cid + "active").clearInvalid();
                            Ext.getCmp(this.cid + "opis").setValue(null);
                            Ext.getCmp(this.cid + "opis").clearInvalid();
                            Ext.getCmp(this.cid + "tconnect").setValue2(Const.STEROWNIK_CONNECT_NO);
                            Ext.getCmp(this.cid + "tconnect").clearInvalid();
                            Ext.getCmp(this.cid + "mobileprovider").setValue2(Const.STEROWNIK_MOBILE_PROVIDER_INNY);
                            Ext.getCmp(this.cid + "mobileprovider").clearInvalid();
                            Ext.getCmp(this.cid + "adres").setValue(1);
                            Ext.getCmp(this.cid + "adres").clearInvalid();
                            Ext.getCmp(this.cid + "localization").setValue(null);
                            Ext.getCmp(this.cid + "localization").clearInvalid();
                            Ext.getCmp(this.cid + "version").setValue(null);
                            Ext.getCmp(this.cid + "version").clearInvalid();
                            Ext.getCmp(this.cid + "devid").setValue(null);
                            Ext.getCmp(this.cid + "devid").clearInvalid();
                            Ext.getCmp(this.cid + "tprinttype").setValue2(0);
                            Ext.getCmp(this.cid + "tprinttype").clearInvalid();
                            Ext.getCmp(this.cid + "tmsintervalquery").setValue(1);
                            Ext.getCmp(this.cid + "tmsintervalquery").clearInvalid();
                            Ext.getCmp(this.cid + "tmsintervalqueryerror").setValue(1);
                            Ext.getCmp(this.cid + "tmsintervalqueryerror").clearInvalid();
                            Ext.getCmp(this.cid + "tmsintervalqueryerrorresetmodem").setValue(5);
                            Ext.getCmp(this.cid + "tmsintervalqueryerrorresetmodem").clearInvalid();
                            Ext.getCmp(this.cid + "tconnecteveryevent").setValue2(false);
                            Ext.getCmp(this.cid + "tconnecteveryevent").clearInvalid();
                            Ext.getCmp(this.cid + "tileusezadane").setValue2(false);
                            Ext.getCmp(this.cid + "tileusezadane").clearInvalid();
                            Ext.getCmp(this.cid + "ignoreerrorsensortemp").setValue2(false);
                            Ext.getCmp(this.cid + "ignoreerrorsensortemp").clearInvalid();
                            Ext.getCmp(this.cid + "intervalgetskpstates").setValue(15);
                            Ext.getCmp(this.cid + "intervalgetskpstates").clearInvalid();
                            Ext.getCmp(this.cid + "numerickeyboardrefuelling").setValue(false);
                            Ext.getCmp(this.cid + "numerickeyboardrefuelling").clearInvalid();
                            Ext.getCmp(this.cid + "disableaskforlicznikinhour").setValue(false);
                            Ext.getCmp(this.cid + "disableaskforlicznikinhour").clearInvalid();
                            Ext.getCmp(this.cid + "czast1").setValue(30);
                            Ext.getCmp(this.cid + "czast1").clearInvalid();
                            Ext.getCmp(this.cid + "czast2").setValue(30);
                            Ext.getCmp(this.cid + "czast2").clearInvalid();
                            Ext.getCmp(this.cid + "czast3").setValue(60);
                            Ext.getCmp(this.cid + "czast3").clearInvalid();
                            Ext.getCmp(this.cid + "wsplczynnikkalibracji").setValue(null);
                            Ext.getCmp(this.cid + "wsplczynnikkalibracji").clearInvalid();
                            Ext.getCmp(this.cid + "supercodebutton").hide();
                            Ext.getCmp(this.cid + "harmonogramon").setValue(false);
                            Ext.getCmp(this.cid + "harmonogramon").clearInvalid();
                            Ext.getCmp(this.cid + "harmonogrampin").setValue(null);
                            Ext.getCmp(this.cid + "harmonogrampin").clearInvalid();
                            Ext.getCmp(this.cid + "monday").setValue(null);
                            Ext.getCmp(this.cid + "monday").clearInvalid();
                            Ext.getCmp(this.cid + "tuesday").setValue(null);
                            Ext.getCmp(this.cid + "tuesday").clearInvalid();
                            Ext.getCmp(this.cid + "wednesday").setValue(null);
                            Ext.getCmp(this.cid + "wednesday").clearInvalid();
                            Ext.getCmp(this.cid + "thursday").setValue(null);
                            Ext.getCmp(this.cid + "thursday").clearInvalid();
                            Ext.getCmp(this.cid + "friday").setValue(null);
                            Ext.getCmp(this.cid + "friday").clearInvalid();
                            Ext.getCmp(this.cid + "saturday").setValue(null);
                            Ext.getCmp(this.cid + "saturday").clearInvalid();
                            Ext.getCmp(this.cid + "sunday").setValue(null);
                            Ext.getCmp(this.cid + "sunday").clearInvalid();
                            Ext.getCmp(this.cid + "archivefrom").setValue(null);
                            Ext.getCmp(this.cid + "archivefrom").clearInvalid();
                            Ext.getCmp(this.cid + "archiveon").setValue(false);
                            Ext.getCmp(this.cid + "archiveon").clearInvalid();
                        }
                        if (Ext.getCmp(this.cid + "tconnect").getValue() != Const.STEROWNIK_CONNECT_GSM) {
                            Ext.getCmp(this.cid + "mobileprovider").setHidden(true);
                        }
                    } else {
                        Ext.MessageBox.alert(Lang.error, data.error);
                    }
                });

            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    saveOp: function () {
        var canWrite = false;
        var fp1 = Ext.getCmp(this.cid + "formpanel1");
        var fp2 = Ext.getCmp(this.cid + "formpanel2");
        var fp3 = Ext.getCmp(this.cid + "formpanel3");
        var fp4 = Ext.getCmp(this.cid + "formpanel4");
        if (fp1.form.isValid() && fp2.form.isValid() && fp3.form.isValid() && fp4.form.isValid()) {
            canWrite = true;
        }
        canWrite = canWrite && Ext.getCmp(this.cid + "tmsintervalqueryerrorresetmodem").validate();
        if (canWrite) {
            var nazwaFromForm = Ext.getCmp(this.cid + "nazwa").getValue();
            nazwaDspToSend = nazwaFromForm;
            nazwaToSend = nazwaFromForm;
            var harmonogramon = Ext.getCmp(this.cid + "harmonogramon").getValue();
            var archivefrom = (Ext.getCmp(this.cid + "archiveon").getValue2() == true) ? Ext.getCmp(this.cid + "archivefrom").getValue() : null;
            sendAjaxDataJsonRpcL("rest/sterowniki/saveJointObjectOpt", {
                id: this.obiektid,
                nazwa: nazwaToSend,
                nazwadsp: nazwaDspToSend,
                serialnumber: this.serialnumber,
                opis: Ext.getCmp(this.cid + "opis").getValue(),
                adres: Ext.getCmp(this.cid + "adres").getValue2(),
                localization: Ext.getCmp(this.cid + "localization").getValue(),
                active: Ext.getCmp(this.cid + "active").getValue2(),
                iszaladunek: Ext.getCmp(this.cid + "iszaladunek").getValue2(),
                tankowanieoff: Ext.getCmp(this.cid + "tankowanieoff").getValue2(),
                tconnect: Ext.getCmp(this.cid + "tconnect").getValue(),
                mobileprovider: Ext.getCmp(this.cid + "mobileprovider").getValue(),
                tmsintervalqueryerror: Ext.getCmp(this.cid + "tmsintervalqueryerror").getValue(),
                tmsintervalqueryerrorresetmodem: Ext.getCmp(this.cid + "tmsintervalqueryerrorresetmodem").getValue(),
                tconnecteveryevent: Ext.getCmp(this.cid + "tconnecteveryevent").getValue2(),
                tileusezadane: Ext.getCmp(this.cid + "tileusezadane").getValue2(),
                ignoreerrorsensortemp: Ext.getCmp(this.cid + "ignoreerrorsensortemp").getValue2(),
                tmsintervalquery: Ext.getCmp(this.cid + "tmsintervalquery").getValue(),
                tprinttype: Ext.getCmp(this.cid + "tprinttype").getValue(),
                forceupdate: Ext.getCmp(this.cid + "forceupdate").getValue(),
                intervalgetskpstates: Ext.getCmp(this.cid + "intervalgetskpstates").getValue(),
                numerickeyboardrefuelling: Ext.getCmp(this.cid + "numerickeyboardrefuelling").getValue(),
                disableaskforlicznikinhour: Ext.getCmp(this.cid + "disableaskforlicznikinhour").getValue(),
                optlockversion: this.obiekt == null ? null : this.obiekt.optlockversion,
                idzbiornik: Ext.getCmp(this.cid + "idzbiornik").getValue(),
                czast1: Ext.getCmp(this.cid + "czast1").getValue(),
                czast2: Ext.getCmp(this.cid + "czast2").getValue(),
                czast3: Ext.getCmp(this.cid + "czast3").getValue(),
                wsplczynnikkalibracji: Ext.getCmp(this.cid + "wsplczynnikkalibracji").isDisabled() ? null : Ext.getCmp(this.cid + "wsplczynnikkalibracji").getValue2(),
                harmonogramon: harmonogramon,
                harmonogrampin: harmonogramon ? Ext.getCmp(this.cid + "harmonogrampin").getValue() : null,
                monday: harmonogramon ? Ext.getCmp(this.cid + "monday").getValue() : null,
                tuesday: harmonogramon ? Ext.getCmp(this.cid + "tuesday").getValue() : null,
                wednesday: harmonogramon ? Ext.getCmp(this.cid + "wednesday").getValue() : null,
                thursday: harmonogramon ? Ext.getCmp(this.cid + "thursday").getValue() : null,
                friday: harmonogramon ? Ext.getCmp(this.cid + "friday").getValue() : null,
                saturday: harmonogramon ? Ext.getCmp(this.cid + "saturday").getValue() : null,
                sunday: harmonogramon ? Ext.getCmp(this.cid + "sunday").getValue() : null,
                archivefrom: archivefrom
            }, this, function (data) {
                if (data.status) {
                    data = data.data;
                    this.obiektid = data.id;
                    this.fireEvent("closesuccess", this);
                    this.close();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
                if (this.callbackF)
                    this.callbackF();
            });
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    closeOp: function () {
        this.close();
    }
});
