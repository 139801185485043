Ext.define('WindowInventorizationCreate', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.inwentaryzacja.capitalise(),
    width: 400,
    height: 150,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    scrollable: false,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'panel',
            layout: 'column',
            items: [{
                columnWidth: 1,
                xtype: 'form',
                id: this.cid + 'formpanel',
                bodyStyle: 'padding:10px 10px 10px 10px',
                scrollable: true,
                layout: 'anchor',
                defaults: {
                    anchor: '0',
                    labelWidth: 80
                },
                items: [{
                    id: this.cid + "czas",
                    name: "czas",
                    xtype: 'datefieldexdmod',
                    fieldLabel: Lang.czas,
                    allowBlank: false,
                    maxValue: new Date(/*new Date().getTime() + 86399999*/),
                    blankText: Lang.pole_nie_moze_byc_puste
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'generuj',
            text: Lang.generuj,
            scope: this,
            handler: this.generateOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function () {
        if (!validPrivilegesOperatorComplex("access.view.stany")) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.startIntervalGetStatusOp();
    },
    startIntervalGetStatusOp: function () {
        Ext.MessageBox.show({
            msg: Lang.wykonywanie_operacji,
            progressText: '...',
            width: 200,
            wait: {
                interval: 200
            }
        });
        this.task = {
            run: this.getStatusShowWindow,
            scope: this,
            interval: 10000
        };
        Ext.TaskManager.start(this.task);
    },
    generateOp: function () {
        if (Ext.getCmp(this.cid + "czas").isValid()) {
            sendAjaxDataJsonRpcL("rest/inventorization/createRequest", {
                czas: Ext.getCmp(this.cid + "czas").getValue2()
            }, this, function (data) {
                if (data.status) {
                    var started = data.data;
                    if (started) {
                        this.startIntervalGetStatusOp();
                        this.hide();
                    } else {
                        Ext.MessageBox.alert(Lang.error, data.error);
                    }
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
            });
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    getStatusShowWindow: function () {
        sendAjaxDataJsonRpcL("rest/inventorization/getStatus", null, this, function (data) {
            if (data.status) {
                var statusOp = data.data;
                if (statusOp == "DONE" || statusOp == "ERROR") {
                    Ext.MessageBox.hide();
                    Ext.TaskManager.stop(this.task, true);
                    this.showResult();
                } else if (statusOp == "REQUEST" || statusOp == "WORKING") {
                    // continue
                } else if (statusOp == "NONE") {
                    Ext.TaskManager.stop(this.task, true);
                    Ext.MessageBox.hide();
                    this.show();
                    this.center();
                    this.setKeyMap({
                        'ESC': {
                            scope: this,
                            handler: this.closeOp
                        }
                    });
                }
            }
        });
    },
    showResult: function () {
        var w = Ext.create('WindowInventorizationResult');
        w.showW();
    },
    closeOp: function () {
        Ext.TaskManager.stop(this.task, true);
        this.close();
    }
});
