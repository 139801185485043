ObservableCanalMap = Ext.extend(Ext.util.Observable, {
    constructor: function (config) {
        this.listeners = config.listeners;
        ObservableCanalMap.superclass.constructor.call(this, config);
    }
});
Ext.define('ViewStany', {
    extend: 'Ext.panel.Panel',
    grid: null,
    id: "stany",
    taskContextMenu: null,
    rowContext: null,
    rebuildGrid: false,
    showState: false,
    showBaterie: false,
    statics: {
        accessPropertyViewName: "access.view.stany",
        accessPropertyViewNameLimitOnly: "access.view.stany_limit_only",
        accessPropertyViewNameSKPsPomiary: "access.view.skpspomiary",
        getOpisCKPFlags: function (record) {
            var laststateflagssckpstate = record.get("staterealflagsckpstate");
            var laststateflagststate = record.get("staterealflagststate");
            var laststateflagsskpstate = record.get("staterealflagsskpstate");
            var temperaturamin = record.get("temperaturamin");
            var temperaturamax = record.get("temperaturamax");
            var temperatura = record.get("temperatura");
            var sopis = [];
            if (laststateflagssckpstate != null) {
                /*
                 * 0x00 - brak przecieku
                 * 0x01 - błąd pomiaru
                 * 0x02 - przeciek
                 * 0x03 - brak lub uszkodzone CKP
                 * 0x04 - brak CKP w konfiguracji
                 */
                if (laststateflagssckpstate == "01") {
                    sopis.push(Lang.blad_pomiaru_ckp);
                }
                if (laststateflagssckpstate == "02") {
                    sopis.push(Lang.przeciek);
                }
                if (laststateflagssckpstate == "03") {
                    sopis.push(Lang.brak_ckp);
                }
                if (laststateflagssckpstate == "04") {
                    sopis.push("-");
                }
            }
            if (laststateflagststate != null) {
                /*
                 * 0x00 - pomiar prawidłowy
                 * 0x01 - pomiar nieprawidłowy
                 * 0x02 - pomiar nieobsługiwany
                 * 0x03 - brak lub błąd przetwornika pomiarowego
                 */
                if (laststateflagststate == "01") {
                    sopis.push(Lang.pomiar_temperatury_nieprawidlowy);
                }
                if (laststateflagststate == "03") {
                    sopis.push(Lang.pomiar_temperatury_brak_blad_pomiaru);
                }
                if (temperatura != null && temperaturamin != null && temperaturamax != null) {
                    if (temperatura < temperaturamin) {
                        sopis.push(Lang.temperatura_zbyt_niska);
                    }
                    if (temperatura > temperaturamax) {
                        sopis.push(Lang.temperatura_za_wysoka);
                    }
                }
            }
            if (laststateflagsskpstate != null) {
                /*
                 * 0x00 - pomiar poprawny
                 * 0x01 - SKP100 w trakcie inicjalizacji
                 * 0x02 - przekroczenie górnego progu pomiarowego
                 * 0x03 - przekroczenie dolnego progu pomiarowego
                 * 0x04 - brak lub błąd przetwornika pomiarowego
                 * 0x05 - brak lub błędna kalibracja
                 * 0x06 - w trakcie kalibracji lub wgrywania konfiguracji
                 */
                if (laststateflagsskpstate == "01") {
                    sopis.push(Lang.skp_w_trakcie_inicjalizacji);
                }
                if (laststateflagsskpstate == "02") {
                    sopis.push(Lang.skp_przekroczenie_gornego_progu_pomiarowego);
                }
                if (laststateflagsskpstate == "03") {
                    sopis.push(Lang.skp_przekroczenie_dolnego_progu_pomiarowego);
                }
                if (laststateflagsskpstate == "04") {
                    sopis.push(Lang.skp_brak_blad_pomiaru);
                }
                if (laststateflagsskpstate == "05") {
                    sopis.push(Lang.skp_brak_blad_kalibracji);
                }
                if (laststateflagsskpstate == "06") {
                    sopis.push(Lang.skp_during_kalibracji);
                }
            }
            if (sopis.length == 0) {
                sopis.push(Lang.ok);
            }
            return sopis.join(",<br>");
        },
        rendererCKPFlags: function (value, meta, record, rowIndex, colIndex, store) {
            var staterealflagsckpstate = record.get("staterealflagsckpstate");
            var staterealflagststate = record.get("staterealflagststate");
            var staterealflagsskpstate = record.get("staterealflagsskpstate");
            var temperaturamin = record.get("temperaturamin");
            var temperaturamax = record.get("temperaturamax");
            var temperatura = record.get("temperatura");
            var sstyle = "";
            if (staterealflagsckpstate != null) {
                if (staterealflagsckpstate == "01") {
                    sstyle = "color:red";
                }
                if (staterealflagsckpstate == "02") {
                    sstyle = "color:red";
                }
                if (staterealflagsckpstate == "03") {
                    sstyle = "color:red";
                }
                if (staterealflagsckpstate == "04") {
                    sstyle = "";
                }
            }
            if (staterealflagststate != null) {
                if (staterealflagststate == "01") {
                    sstyle = "color:red";
                }
                if (staterealflagststate == "03") {
                    sstyle = "color:red";
                }
                if (temperatura != null && temperaturamin != null && temperaturamax != null) {
                    if (temperatura < temperaturamin) {
                        sstyle = "color:red";
                    }
                    if (temperatura > temperaturamax) {
                        sstyle = "color:red";
                    }
                }
            }
            if (staterealflagsskpstate != null) {
                if (staterealflagsskpstate == "01") {
                    sstyle = "color:red";
                }
                if (staterealflagsskpstate == "02") {
                    sstyle = "color:red";
                }
                if (staterealflagsskpstate == "03") {
                    sstyle = "color:red";
                }
                if (staterealflagsskpstate == "04") {
                    sstyle = "color:red";
                }
                if (staterealflagsskpstate == "05") {
                    sstyle = "color:red";
                }
                if (staterealflagsskpstate == "06") {
                    sstyle = "color:red";
                }
            }
            //renderWrapQtip
            value = "<span style='" + sstyle + "'>" + value + "</span>";
            return '<div ' + 'data-qtip="' + value + '"' + ' class="x-gridexd-col-wrap">' + value + '</div>';
        },
        rendererZbiornikStateStatus: function (value, meta, record, rowIndex, colIndex, store) {
            var zbiornikStateStatus = record.get("zbiornikStateStatus");
            var addClass = "";
            if (zbiornikStateStatus != null) {
                if (zbiornikStateStatus == Const.ZBIORNIK_COMMUNICATION_STATE_STATUS_ACTIVE) {
                    addClass = "cgreen";
                }
                if (zbiornikStateStatus == Const.ZBIORNIK_COMMUNICATION_STATE_STATUS_SHORT_INACTIVITY) {
                    addClass = "cyellow";
                }
                if (zbiornikStateStatus == Const.ZBIORNIK_COMMUNICATION_STATE_STATUS_LONG_INACTIVITY) {
                    addClass = "cred";
                }
                if (zbiornikStateStatus == Const.ZBIORNIK_COMMUNICATION_STATE_STATUS_INACTIVE) {
                    addClass = "cgray";
                }
            }
            return '<div class="circle ' + addClass + '"> </div>';
        },
        buildStanzbrealForExport: function (value, record) {
            var sstyleopis = ViewStany.getOpisCKPFlags(record);
            if (value == null && sstyleopis == "OK") {
                value = "";
            } else {
                var stanzbiornikarealtms = "";
                if (record.get("stanzbiornikarealtms") != null)
                    stanzbiornikarealtms = Ext.Date.format(record.get("stanzbiornikarealtms"), Date.patterns.ISO8601Long);
                if (value != null) {
                    var temp = record.get("temperatura");
                    if (temp != null) temp = Ext.Number.roundToPrecision(temp, 3).toString();
                    var stanzbiornikarealnsk = record.get("stanzbiornikarealnsk");
                    if (stanzbiornikarealnsk != null) stanzbiornikarealnsk = Ext.Number.roundToPrecision(stanzbiornikarealnsk, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                    value = Ext.Number.roundToPrecision(value, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR) + (stanzbiornikarealnsk != null ? "/" + stanzbiornikarealnsk : "") + " ( " + stanzbiornikarealtms + ", " + (temp != null ? temp + " &#176;C, " : "") + sstyleopis + " )";
                } else {
                    value = sstyleopis + ", " + stanzbiornikarealtms;
                }
            }
            return value;
        }
    },
    cat: "p",
    text: Lang.stan,
    buttonIconCls: "iconStany",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.buildGrid();
        this.items = [{
            region: "north",
            xtype: 'toolbar',
            items: [{
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconPDF",
                text: Lang.pdf.capitalise(),
                scope: this,
                hidden: false,
                handler: this.getDownloadLsPdf,
                hidden: Const.AGGREGATEDCORE
            }, {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconEXCEL",
                text: Lang.excel.capitalise(),
                scope: this,
                hidden: false,
                handler: this.getDownloadLsExcel,
                hidden: Const.AGGREGATEDCORE
            }, "-", {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconRefresh",
                text: Lang.odswiez.capitalise(),
                scope: this,
                handler: this.refreshDatastore
            }, {
                xtype: "checkbox",
                id: this.id + "autorefresh",
                boxLabel: Lang.autorefresh,
                listeners: {
                    scope: this,
                    change: this.onautoRefreshDataStoreC
                }
            }, {
                xtype: "checkbox",
                id: this.id + "showbaterie",
                boxLabel: Lang.pokazuj_jako_baterie,
                stateId: "showbaterie-" + this.id + (AppFIRMACTX == null ? "-all" : "-"),
                stateful: true,
                stateEvents: ["change"],
                hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.OPTIMALFLAG || Const.STARTERFLAG || Const.AGGREGATEDCORE,
                getState: function () {
                    return {
                        value: this.getValue()
                    };
                },
                applyState: function (state) {
                    if (state) {
                        this.setValue(state.value);
                    }
                },
                listeners: {
                    scope: this,
                    change: this.onShowBaterieChange
                }
            }, "-", {
                xtype: 'button',
                id: this.id + "inventorization",
                iconAlign: 'left',
                iconCls: 'iconEdit',
                text: Lang.inwentaryzacja.capitalise(),
                scope: this,
                handler: this.inventorization
            }, {
                xtype: "box",
                id: this.id + "kontekstfirmybox",
                cls: "kontekstfirmy-box"
            }, {
                id: this.id + "kontekstfirmy",
                xtype: 'label',
                style: 'display:block;margin-right:5px',
                cls: "kontekstfirmy-text"
            }]
        }, {
            id: this.id + "splitcttrmapka",
            region: "center",
            xtype: 'panel',
            layout: 'border',
            frame: false,
            border: false,
            stateId: "split-" + this.id + (AppFIRMACTX == null ? "-all" : "-"),
            stateful: true,
            items: [{
                id: this.id + "panelgridmgr",
                region: "center",
                collapsible: false,
                collapsed: false,
                frame: false,
                border: false,
                layout: {
                    type: 'vbox',
                    align: 'stretch'
                },
                items: [{
                    flex: 2,
                    xtype: 'panel',
                    frame: false,
                    border: false,
                    layout: 'fit',
                    hidden: validPrivilegesOperatorComplex(ViewStany.accessPropertyViewNameLimitOnly) && isValidOperatorFirmaLimit(),
                    items: [this.grid]
                }]
            }, {
                id: this.id + "splitnorthmapka",
                collapsible: true,
                collapsed: true,
                hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
                stateful: true,
                stateId: "mapka-" + this.id + (AppFIRMACTX == null ? "-all" : "-"),
                titleCollapse: true,
                title: Lang.mapka,
                region: "west",
                xtype: 'panel',
                layout: 'fit',
                width: 300,
                split: true,
                items: []
            }]
        }];
        this.callParent(arguments);
        this.task = new Ext.util.DelayedTask(function () {
            if (this.showState) {
                var autorefresh = Ext.getCmp(this.id + "autorefresh").getValue();
                if (autorefresh) {
                    this.task.delay(30000);
                    this.refreshDatastore();
                }
            }
        }, this);
    },
    showFirst: function (eventMain) {
        eventMain.on("firmaChangeContext", this.changeContext, this);
        if (!Const.BASICFLAG && !Const.STANDARDFLAG && !Const.STARTERFLAG) {
            sendAjaxDataJsonRpcL("rest/skp/getList", {}, this, function (data) {
                if (data.status) {
                    if (data.data.length > 0) {
                        var zapelnienierealCol = Ext.Array.findBy(this.grid.getColumns(), function (item, index) {
                            return item.initialConfig.header == Lang.zapelnienie_rzecz;
                        });
                        if (zapelnienierealCol)
                            zapelnienierealCol.setHidden(false);
                        var stanzbiornikarealCol = Ext.Array.findBy(this.grid.getColumns(), function (item, index) {
                            return item.initialConfig.dataIndex == 'stanzbiornikareal';
                        });
                        if (stanzbiornikarealCol)
                            stanzbiornikarealCol.setHidden(false);
                    }
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
            });
        }
    },
    remove: function () {
        this.callParent(arguments);
    },
    show: function () {
        var autorefresh = Ext.state.Manager.get(this.id + "-checkbox-autorefresh");
        if (autorefresh != null) {
            Ext.getCmp(this.id + "autorefresh").setValue(autorefresh.checked);
        }
        this.showState = true;
        this.callParent(arguments);
        this.changeContextRebuild(null, null);
        this.refreshDatastore();
        this.autoRefreshDataStore();
    },
    hide: function () {
        this.showState = false;
        this.callParent(arguments);
        this.autoRefreshDataStore();
    },
    onDestroy: function () {
        this.taskContextMenu.destroy();
        this.callParent(arguments);
    },
    changeContextRebuild: function (eAppFIRMACTX, eAppFIRMACTXold) {
        var kontekstfirmy = Ext.getCmp(this.id + "kontekstfirmy");
        var kontekstfirmybox = Ext.getCmp(this.id + "kontekstfirmybox");
        if (AppFIRMACTX != null && !Const.AGGREGATEDCORE) {
            kontekstfirmybox.update("<div class='appSetColourRedSplit'></div>");
            kontekstfirmy.setText(Lang.pracujesz_w_kontekscie + AppFIRMACTX.nazwa);
        } else {
            kontekstfirmybox.update("");
            kontekstfirmy.setText("");
        }
        if (this.rebuildGrid) {
            this.rebuildGrid = false;
            var onwer = this.grid.ownerCt;
            Ext.suspendLayouts();
            onwer.remove(this.grid);
            this.grid.destroy();
            this.taskContextMenu.destroy();
            this.buildGrid();
            onwer.add(this.grid);
            Ext.resumeLayouts(true);
        }
    },
    changeContext: function (eAppFIRMACTX, eAppFIRMACTXold) {
        this.rebuildGrid = true;
        if (this.showState) {
            this.changeContextRebuild(null, null);
            this.refreshDatastore();
        }
    },
    buildGrid: function () {
        var store = Ext.create('Ext.data.Store', {
            sorters: [{
                property: 'nazwa',
                direction: 'ASC'
            }],
            fields: [{
                name: 'id',
                mapping: 'id'
            }, {
                name: 'nazwa',
                mapping: 'nazwa'
            }, {
                name: 'active',
                mapping: 'active'
            }, {
                name: 'stanzbiornika',
                type: 'float',
                mapping: 'stanzbiornika'
            }, {
                name: 'stanzbiornikareal',
                type: 'float',
                allowNull: true,
                mapping: 'stanzbiornikareal'
            }, {
                name: 'stanzbiornikareal2',
                type: 'float',
                allowNull: true,
                calculate: function (data) {
                    return data.stanzbiornikareal;
                }
            }, {
                name: 'zapelnieniezbiornikareal',
                type: 'float',
                calculate: function (data) {
                    return data.stanzbiornikareal / data.pojemnosc;
                }
            }, {
                name: 'stanzbiornikareal3',
                type: 'float',
                allowNull: true,
                calculate: function (data) {
                    return data.stanzbiornikareal;
                }
            }, {
                name: 'stanzbiornikarealtms',
                mapping: 'stanzbiornikarealtms',
                type: 'date'
            }, {
                name: 'stanzbiornika2',
                type: 'float',
                calculate: function (data) {
                    return data.stanzbiornika;
                }
            }, {
                name: 'zapelnieniezbiornika',
                type: 'float',
                calculate: function (data) {
                    return data.stanzbiornika / data.pojemnosc;
                }
            }, {
                name: 'pojemnosc',
                type: 'float',
                mapping: 'pojemnosc'
            }, {
                name: 'stanalarmowy',
                type: 'float',
                mapping: 'stanalarmowy'
            }, {
                name: 'stanblokady',
                type: 'float',
                mapping: 'stanblokady'
            }, {
                name: 'plyn',
                mapping: 'plyn'
            }, {
                name: 'staterealflags',
                mapping: 'staterealflags'
            }, {
                name: 'staterealflagsckpstate',
                mapping: 'staterealflagsckpstate'
            }, {
                name: 'staterealflagststate',
                mapping: 'staterealflagststate'
            }, {
                name: 'staterealflagsskpstate',
                mapping: 'staterealflagsskpstate'
            }, {
                name: "zbiornikstatecontroltype",
                mapping: "zbiornikstatecontroltype"
            }, {
                name: 'opis',
                mapping: 'opis'
            }, {
                name: 'firmasAsString',
                mapping: 'firmasAsString'
            }, {
                name: 'zbiornikStateStatus',
                mapping: 'zbiornikStateStatus'
            }],
            groupField: 'plyn',
            proxy: {
                type: 'memory',
                reader: {
                    type: 'json',
                    rootProperty: ""
                }
            }
        });
        var cols = [{
            header: Lang.nazwa,
            flex: 2,
            sortable: true,
            dataIndex: 'nazwa',
            filter: {
                type: 'string'
            },
            sorter: Ext.bind(customSorterForGrid, this, ["nazwa"], true),
            filterable: true,
            renderer: function (value, meta, record, rowIndex, colIndex, store) {
                return ViewStany.rendererCKPFlags(value, meta, record, rowIndex, colIndex, store);
            }
        }, {
            header: Lang.plyn,
            flex: 2,
            sortable: true,
            dataIndex: 'plyn',
            filter: {
                type: 'string'
            },
            sorter: Ext.bind(customSorterForGrid, this, ["plyn"], true),
            filterable: true
        }, {
            header: Lang.opis,
            flex: 2,
            sortable: true,
            dataIndex: 'opis',
            filter: {
                type: 'string'
            },
            sorter: Ext.bind(customSorterForGrid, this, ["opis"], true),
            filterable: true,
            hidden: true
        }, {
            header: Lang.firma,
            flex: 2,
            sortable: true,
            dataIndex: 'firmasAsString',
            filter: {
                type: 'string'
            },
            hidden: !Const.AGGREGATEDCORE
        }, {
            groupable: false,
            filterable: false,
            header: Lang.zapelnienie,
            dataIndex: 'zapelnieniezbiornika',
            flex: 1,
            align: 'center',
            xtype: 'widgetcolumn',
            widget: {
                xtype: 'progressexdstany'
            }
        }, {
            groupable: false,
            filterable: false,
            header: Lang.zapelnienie_rzecz,
            dataIndex: 'zapelnieniezbiornikareal',
            flex: 1,
            align: 'center',
            xtype: 'widgetcolumn',
            widget: {
                xtype: 'progressexdstany'
            },
            hidden: true
        }, {
            header: Lang.stanzbiornika,
            flex: 1,
            sortable: true,
            dataIndex: 'stanzbiornika',
            renderer: function (value, meta, record, rowIndex, colIndex, store) {
                value = Ext.Number.roundToPrecision(value, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                return ViewStany.rendererCKPFlags(value, meta, record, rowIndex, colIndex, store);
            },
            filter: 'numeric',
            filterable: true,
            hidden: Const.SKPFLAG
        }, {
            header: Lang.stanzbiornikareal,
            flex: 2,
            sortable: true,
            dataIndex: 'stanzbiornikareal',
            renderer: function (value, meta, record, rowIndex, colIndex, store) {
                var sstyleopis = ViewStany.getOpisCKPFlags(record);
                if (value == null && sstyleopis == "OK")
                    return null;
                var stanzbiornikarealtms = "";
                if (record.get("stanzbiornikarealtms") != null)
                    stanzbiornikarealtms = Ext.Date.format(record.get("stanzbiornikarealtms"), Date.patterns.ISO8601Long);
                if (value != null) {
                    var temp = record.get("temperatura");
                    if (temp != null) temp = Ext.Number.roundToPrecision(temp, 3).toString();
                    var stanzbiornikarealnsk = record.get("stanzbiornikarealnsk");
                    if (stanzbiornikarealnsk != null) stanzbiornikarealnsk = Ext.Number.roundToPrecision(stanzbiornikarealnsk, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                    value = Ext.Number.roundToPrecision(value, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR) + (stanzbiornikarealnsk != null ? "/" + stanzbiornikarealnsk : "") + " ( " + stanzbiornikarealtms + ", " + (temp != null ? temp + " &#176;C, " : "") + sstyleopis + " )";
                } else {
                    value = sstyleopis + ", " + stanzbiornikarealtms;
                }
                return ViewStany.rendererCKPFlags(value, meta, record, rowIndex, colIndex, store);
            },
            filter: 'numeric',
            filterable: true,
            resizable: true,
            hidden: true
        }, {
            header: Lang.pojemnosc,
            flex: 1,
            sortable: true,
            dataIndex: 'pojemnosc',
            renderer: function (value, meta, record, rowIndex, colIndex, store) {
                value = Ext.Number.roundToPrecision(value, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                return ViewStany.rendererCKPFlags(value, meta, record, rowIndex, colIndex, store);
            },
            filter: 'numeric',
            filterable: true
        }, {
            header: Lang.stanalarmowy,
            flex: 1,
            sortable: true,
            dataIndex: 'stanalarmowy',
            renderer: function (value, meta, record, rowIndex, colIndex, store) {
                value = Ext.Number.roundToPrecision(value, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                return ViewStany.rendererCKPFlags(value, meta, record, rowIndex, colIndex, store);
            },
            filter: 'numeric',
            filterable: true
        }, {
            header: Lang.stanblokady,
            flex: 1,
            sortable: true,
            dataIndex: 'stanblokady',
            renderer: function (value, meta, record, rowIndex, colIndex, store) {
                value = Ext.Number.roundToPrecision(value, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                return ViewStany.rendererCKPFlags(value, meta, record, rowIndex, colIndex, store);
            },
            filter: {
                type: 'numeric'
            },
            filterable: true
        }, {
            header2: Lang.active,
            menuText: Lang.active,
            header: Ext.DomHelper.markup({
                tag: "div",
                cls: "iconDivCol iconActive",
                cn: [{
                    tag: "span",
                    html: "&nbsp;"
                }, {
                    tag: "div",
                    html: Lang.active
                }]
            }),
            flex: 1,
            sortable: true,
            dataIndex: 'active',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            },
            filterable: true
        }, {
            header: Lang.status_stanu_zbiornika,
            flex: 1,
            sortable: true,
            dataIndex: 'zbiornikStateStatus',
            renderer: function (value, meta, record, rowIndex, colIndex, store) {
                return ViewStany.rendererZbiornikStateStatus(value, meta, record, rowIndex, colIndex, store);
            },
            hidden: false
        }];
        if (Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG ||
            !validPrivilegesOperatorComplex(ViewStany.accessPropertyViewNameSKPsPomiary)) {
            removeObjectFromArrayById(cols, 'zapelnieniezbiornikareal');
            removeObjectFromArrayById(cols, "stanzbiornikareal");
        }
        if (!Const.AGGREGATEDCORE) {
            removeObjectFromArrayById(cols, "firmasAsString");
        }
        this.grid = new Ext.ux.grid.GridPanelExd({
            store: store,
            features: [{
                ftype: 'grouping'
            }],
            plugins: {
                gridfilters: true
            },
            columns: cols,
            frame: false,
            border: false,
            stateId: "grid-" + this.id + (AppFIRMACTX == null ? "-all" : "-"),
            stateful: true,
            stripeRows: true,
            enableHdMenu: true,
            selModel: {
                selType: 'rowmodel',
                mode: 'single',
                allowDeselect: false
            }
        });
        this.grid.addListener("rowclick", function () {
            this.rowclickgrid();
        }, this);
        this.grid.addListener("rowdblclick", function () {
            this.rowDbClickGrid();
        }, this);
        this.grid.store.addListener("load", function () {
            updateCzasRefreshSerwer();
        });
        this.taskContextMenu = new Ext.menu.Menu();
        this.taskContextMenu.add([{
            iconAlign: 'left',
            iconCls: "iconPDF",
            text: Lang.pdf.capitalise(),
            scope: this,
            handler: this.getDownloadLsPdf
        }, {
            iconAlign: 'left',
            iconCls: "iconEXCEL",
            text: Lang.excel.capitalise(),
            scope: this,
            handler: this.getDownloadLsExcel
        }, "-", {
            iconAlign: 'left',
            iconCls: "iconRefresh",
            text: Lang.odswiez.capitalise(),
            scope: this,
            handler: this.refreshDatastore
        }]);
        this.grid.addListener("rowcontextmenu", function (grid, record, tr, rowIndex, e) {
            this.rowContext = grid.getStore().getAt(rowIndex);
            this.taskContextMenu.showAt(e.getXY());
            e.preventDefault();
        }, this);
        this.taskContextMenu.addListener("hide", function () {
            this.rowContext = null;
        }, this);
        this.grid.addListener("containercontextmenu", function (grid, e) {
            this.rowContext = null;
            this.taskContextMenu.showAt(e.getXY());
            e.preventDefault();
        }, this);
    },
    getDownloadLsPdf: function () {
        postFormAsync("rest/pdfexcelexport/downloadExport", {
            format: "pdf",
            name: Lang.stan,
            defd: Ext.encode(this.grid.getDefGridDataCSF({
                colsEx: ["stanzbiornika2"],
                colsIn: [],
                useRenderer: false,
                fnd: function (v, c, metaData, record, rowIndex, colIndex, store) {
                    if (c == "active") {
                        if (v)
                            v = Lang.tak;
                        else
                            v = Lang.nie;
                    }
                    if (c == "zapelnieniezbiornika" || c == "zapelnieniezbiornikareal") {
                        if (v != null && v != '') {
                            v = Math.round(v * 100) + '%';
                        } else if (v == 0) v += '%'
                    }
                    if (c == "stanzbiornikareal") {
                        v = ViewStany.buildStanzbrealForExport(v, record);
                    }
                    if (c == "zbiornikStateStatus") {
                        v = ZBIORNIK_COMMUNICATION_STATE_STATUS.find(type => type[0] == v)[1]
                    }
                    return v;
                }
            }))
        }, null);
    },
    getDownloadLsExcel: function () {
        postFormAsync("rest/pdfexcelexport/downloadExport", {
            format: "excel",
            name: Lang.stan,
            defd: Ext.encode(this.grid.getDefGridDataCSF({
                colsEx: ["stanzbiornika2"],
                colsIn: [],
                useRenderer: false,
                fnd: function (v, c, metaData, record, rowIndex, colIndex, store) {
                    if (c == "active") {
                        if (v)
                            v = Lang.tak;
                        else
                            v = Lang.nie;
                    }
                    if (c == "zapelnieniezbiornika" || c == "zapelnieniezbiornikareal") {
                        if (v != null && v != '') {
                            v = Math.round(v * 100) + '%';
                        } else if (v == 0) v += '%'
                    }
                    if (c == "stanzbiornikareal") {
                        v = ViewStany.buildStanzbrealForExport(v, record);
                    }
                    if (c == "zbiornikStateStatus") {
                        v = ZBIORNIK_COMMUNICATION_STATE_STATUS.find(type => type[0] == v)[1]
                    }
                    return v;
                }
            }))
        }, null);
    },
    onautoRefreshDataStoreC: function () {
        this.autoRefreshDataStore();
        Ext.state.Manager.set(this.id + "-checkbox-autorefresh", {
            checked: Ext.getCmp(this.id + "autorefresh").getValue()
        });
    },
    onShowBaterieChange: function () {
        var showBaterieCheckbox = Ext.getCmp(this.id + "showbaterie");
        this.showBaterie = showBaterieCheckbox.getValue();
        this.refreshDatastore();
    },
    autoRefreshDataStore: function () {
        if (this.showState) {
            var autorefresh = Ext.getCmp(this.id + "autorefresh").getValue();
            if (autorefresh) {
                this.task.delay(30000);
            }
        }
    },
    refreshDatastore: function () {
        updateCzasRefreshSerwer();
        if (this.grid && this.grid.rendered)
            this.grid.mask();
        sendAjaxDataJsonRpcL("rest/zbiorniki/getListForStany", {
            showbaterie: this.showBaterie
        }, this, function (data) {
            if (this.grid && this.grid.rendered)
                this.grid.unmask();
            if (data.status) {
                this.grid.store.loadData(data.data);
                this.refreshDatastoreMapka();
                this.refreshPanelFirmaInfo();
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    inventorization: function () {
        var w = Ext.create('WindowInventorizationCreate');
        w.showW();
    },
    refreshPanelFirmaInfo: function () {
        if (validPrivilegesOperatorComplex(ViewStany.accessPropertyViewNameLimitOnly) && isValidOperatorFirmaLimit()) {
            var plcc = Ext.getCmp(this.id + "panelgridmgr");
            var limitPanelStanyCp = Ext.getCmp(this.id + "panelfirmastatus2");
            if (limitPanelStanyCp == null) {
                plcc.insert(0, {
                    height: '100%',
                    xtype: 'panel',
                    border: false,
                    frame: false,
                    layout: {
                        type: 'vbox',
                        align: 'stretch'
                    },
                    items: [Ext.create('LimitPanelStanyCp', {
                        height: '100%',
                        id: this.id + "panelfirmastatus2",
                        title: Lang.podsumowanie_limitow_firmy,
                        frame: false,
                        border: false
                    })]
                });
                limitPanelStanyCp = Ext.getCmp(this.id + "panelfirmastatus2");
            }
            limitPanelStanyCp.updateData();
        }
    },
    getSelectedRecord: function () {
        if (!this.grid.getSelectionModel().hasSelection()) {
            return null;
        } else {
            if (this.grid.getSelectionModel().getSelected() != null) {
                return this.grid.getSelectionModel().getSelected();
            }
        }
        return null;
    },
    selectRecord: function (r) {
        this.grid.getSelectionModel().select([r]);
    },
    clearSelecton: function () {
        this.grid.getSelectionModel().clearSelections();
    },
    mapkaInited: false,
    currentObject: null,
    zbiornikidatas: null,
    zbiornikidatasOld: null,
    observerEventsCanal: new ObservableCanalMap({}),
    refreshDatastoreMapka: function () {
        sendAjaxDataJsonRpcL("rest/systemkonf/readParamsLessSecure", null, this, function (data) {
            if (data.status) {
                data = data.data;
                var zbMapType = data.zb_map_type;
                if (Const.CLOUDFLAG) {
                    zbMapType = Const.MAP_TYPE_USE_HMAP;
                }
                if (zbMapType == Const.MAP_TYPE_USE_IMAGE) {
                    this.refreshDatastoreMapkaGraficzna();
                    this.observerEventsCanal.on("rowClickUnSelectOld", this.graficznaRowUnSelectOld, this);
                    this.observerEventsCanal.on("rowClickSelectNew", this.graficznaRowSelectNew, this);
                    this.observerEventsCanal.on("rowClickClearSelect", this.graficznaRowClearSelect, this);
                }
                if (zbMapType == Const.MAP_TYPE_USE_HMAP) {
                    this.refreshDatastoreMapkaHMAP();
                    this.observerEventsCanal.on("rowClickUnSelectOld", this.hmapRowUnSelectOld, this);
                    this.observerEventsCanal.on("rowClickSelectNew", this.hmapRowSelectNew, this);
                    this.observerEventsCanal.on("rowClickClearSelect", this.hmapRowClearSelect, this);
                    this.observerEventsCanal.on("rowDblClick", this.hmapRowDblSelect, this);
                    this.observerEventsCanal.on("rowDblClickPre", this.hmapRowDblSelectPre, this);
                }
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    rowclickgrid: function () {
        var selectedRow = this.getSelectedRecord();
        if (selectedRow == null) {
            this.observerEventsCanal.fireEvent("rowClickClearSelect");
            this.currentObject = null;
        } else {
            this.observerEventsCanal.fireEvent("rowClickUnSelectOld");
            var selectedRecord = selectedRow.getAt(0);
            if (selectedRecord.currentObject) {
                this.currentObject = selectedRow.getAt(0).currentObject;
            } else {
                this.currentObject = selectedRow.getAt(0).data;
            }
            this.observerEventsCanal.fireEvent("rowClickSelectNew");
        }
    },
    rowDbClickGrid: function () {
        this.observerEventsCanal.fireEvent("rowDblClickPre");
        var selectedRow = this.getSelectedRecord();
        if (selectedRow == null) {
            this.currentObject = null;
        } else {
            var selectedRecord = selectedRow.getAt(0);
            if (selectedRecord.currentObject) {
                this.currentObject = selectedRow.getAt(0).currentObject;
            } else {
                this.currentObject = selectedRow.getAt(0).data;
            }
        }
        this.observerEventsCanal.fireEvent("rowDblClick");
    },
    visalElemModCls: function (cobject) {
        record = cobject.currentRow;
        if (record.get("stanzbiornikareal") && record.get("stanzbiornikareal") != record.get("stanzbiornika")) {
            if (record.get("stanzbiornikareal") < record.get("stanblokady"))
                return "low";
            if (record.get("stanzbiornikareal") < record.get("stanalarmowy"))
                return "medium";
            return "high";
        } else {
            if (record.get("stanzbiornika") < record.get("stanblokady"))
                return "low";
            if (record.get("stanzbiornika") < record.get("stanalarmowy"))
                return "medium";
            return "high";
        }
    },
    currentMarker: null,
    geocoder: null,
    heremap: null,
    getIconZbiornikState: function (mode, cobject) {
        var icon = null;
        if (mode == "u") {
            var statem = this.visalElemModCls(cobject);
            if (statem == "low") {
                icon = this.mapUnselectedLowIcon;
            } else if (statem == "medium") {
                icon = this.mapUnselectedMediumIcon;
            } else if (statem == "high") {
                icon = this.mapUnselectedHighIcon;
            }
        } else if (mode == "s") {
            var statem = this.visalElemModCls(cobject);
            if (statem == "low") {
                icon = this.mapSelectedLowIcon;
            } else if (statem == "medium") {
                icon = this.mapSelectedMediumIcon;
            } else if (statem == "high") {
                icon = this.mapSelectedHighIcon;
            }
        }
        return icon;
    },
    hmapRowDblSelectPre: function () {
        if (this.currentObject != null && this.currentObject.marker != null)
            this.currentObject.marker.setIcon(this.getIconZbiornikState("u", this.currentObject));
    },
    hmapRowDblSelect: function () {
        if (this.currentObject != null)
            this.currentMarker = this.currentObject.marker;
        if (this.currentObject != null && this.currentObject.marker != null)
            this.currentObject.marker.setIcon(this.getIconZbiornikState("s", this.currentObject));
        if (this.currentObject != null && this.currentObject.marker != null)
            this.heremap.setCenter(this.currentObject.marker.getPosition());
    },
    hmapRowUnSelectOld: function () {
        if (this.currentObject != null && this.currentObject.marker != null)
            this.currentObject.marker.setIcon(this.getIconZbiornikState("u", this.currentObject));
        this.currentMarker = null;
    },
    hmapRowSelectNew: function () {
        this.currentMarker = this.currentObject.marker;
        if (this.currentObject != null && this.currentObject.marker != null)
            this.currentObject.marker.setIcon(this.getIconZbiornikState("s", this.currentObject));
    },
    hmapRowClearSelect: function () {
        if (this.currentObject != null && this.currentObject.marker != null)
            this.currentObject.marker.setIcon(this.getIconZbiornikState("u", this.currentObject));
        this.currentMarker = null;
    },
    markecrSelectClick: function (event) {
        if (this.currentObject != null && this.currentObject.marker != null)
            this.currentObject.marker.setIcon(this.getIconZbiornikState("u", this.currentObject));
        var bubble = new H.ui.InfoBubble(event.target.getPosition(), {
            content: event.target.getData().title
        });
        this.heremapUi.addBubble(bubble);
        this.currentRow = event.target.getData().row;
        this.currentObject = event.target.getData().object;
        this.selectRecord(this.currentRow);
        if (this.currentObject != null && this.currentObject.marker != null)
            this.currentObject.marker.setIcon(this.getIconZbiornikState("s", this.currentObject));
    },
    resolverAddrToPoint: function (addr, functionCallBackOk, scope) {
        var fresolvercallback = function (result, functionCallBackOk, scope) {
            if (result && result.Response.View.length > 0) {
                var location = result.Response.View[0].Result[0];
                functionCallBackOk.call(scope, {
                    lat: location.Location.DisplayPosition.Latitude,
                    lng: location.Location.DisplayPosition.Longitude
                });
            } else {
                Ext.MessageBox.alert(Lang.error);
            }
        };
        this.geocoder.geocode({
            'searchText': addr,
            maxresults: 1
        }, Ext.Function.bind(fresolvercallback, null, [functionCallBackOk, scope], true), function (e) {
            Ext.MessageBox.show(Lang.error, e);
        });
    },
    resolverAddrToPoint2: function (addr, functionCallBackOk, scope) {
        var fresolvercallback = function (result, functionCallBackOk, scope) {
            if (result && result.Response.View.length > 0) {
                var location = result.Response.View[0].Result[0];
                functionCallBackOk.call(scope, {
                    lat: location.Location.DisplayPosition.Latitude,
                    lng: location.Location.DisplayPosition.Longitude
                });
            } else {
                Ext.MessageBox.alert(Lang.error);
            }
        };
        this.geocoder.geocode({
            'searchText': addr,
            maxresults: 1
        }, Ext.Function.bind(fresolvercallback, null, [functionCallBackOk, scope], true), function (e) {
            Ext.MessageBox.show(Lang.error, e);
        });
    },
    resolverPointToAddr: function (point, functionCallBackOk, scope) {
        var fresolvercallback = function (result, functionCallBackOk, scope) {
            if (result && result.Response.View.length > 0) {
                var location = result.Response.View[0].Result[0];
                functionCallBackOk.call(scope, location.Location.Address.Label);
            } else {
                Ext.MessageBox.alert(Lang.error);
            }
        };
        this.geocoder.reverseGeocode({
            prox: point.lat + ',' + point.lng + ',100',
            mode: 'retrieveAddresses',
            maxresults: 1
        }, Ext.Function.bind(fresolvercallback, this, [functionCallBackOk, scope], true), function (e) {
            Ext.MessageBox.show(Lang.error, e);
        });
    },
    scallbackKeepUserHereMAP: function () {
        if (this.heremap != null) {
            var mp = this.heremap.getCenter();
            var lat = mp.lat;
            var lng = mp.lng;
            var zoom = this.heremap.getZoom();
            var poscenterss = Ext.state.Manager.get("stanygmappos");
            if (poscenterss != null) {
                var latlngCenterInit = {
                    lat: poscenterss.lat,
                    lng: poscenterss.lng
                };
                var zoominit = poscenterss.zoom;
                if (lat != poscenterss.lat || lng != poscenterss.lng || zoom != poscenterss.zoom) {
                    Ext.state.Manager.set("stanygmappos", {
                        lat: lat,
                        lng: lng,
                        zoom: zoom
                    });
                }
            } else {
                Ext.state.Manager.set("stanygmappos", {
                    lat: lat,
                    lng: lng,
                    zoom: zoom
                });
            }
        }
    },
    searchSzukajHereMap: function () {
        var fszukajvalue = Ext.getCmp(this.id + "fszukaj").getValue();
        this.resolverAddrToPoint2(fszukajvalue, function (point) {
            this.heremap.setCenter(point);
            Ext.Function.defer(function () {
                this.scallbackKeepUserHereMAP();
            }, 500, this);
        }, this);
    },
    refreshDatastoreMapkaHMAP: function () {
        sendAjaxDataJsonRpcL("rest/zbiornikigmap/getZbiornikListForGMapState", null, this, function (data) {
            if (data.status) {
                try {
                    this.zbiornikidatasOld = this.zbiornikidatas == null ? [] : this.zbiornikidatas;
                    this.zbiornikidatas = data.data;
                    for (var i = 0; i < this.zbiornikidatas.length; i++) {
                        this.zbiornikidatas[i]["object"] = this.zbiornikidatas[i];
                    }
                    var splitcttrmapka = Ext.getCmp(this.id + "splitcttrmapka");
                    var splitnorthmapka = Ext.getCmp(this.id + "splitnorthmapka");
                    if (!this.mapkaInited && !Ext.getCmp(this.id + "form1")) {
                        splitnorthmapka.removeAll();
                        splitnorthmapka.add({
                            xtype: 'box',
                            id: this.id + "boxImage",
                            autoEl: {
                                tag: 'div',
                                cls: "mapka-canvas",
                                cn: [{
                                    tag: "span",
                                    cls: "mapkav-canvas-txt",
                                    html: Lang.brak_mapki
                                }]
                            }
                        });
                    }
                    loadHereMapLoader(Ext.Function.bind(function () {
                        try {
                            if (!this.mapkaInited && !Ext.getCmp(this.id + "form1")) {
                                splitnorthmapka.removeAll();
                                splitnorthmapka.add({
                                    xtype: 'panel',
                                    layout: 'border',
                                    frame: false,
                                    border: false,
                                    items: [{
                                        region: 'north',
                                        id: this.id + "form1",
                                        xtype: "form",
                                        border: true,
                                        frame: true,
                                        defaults: {
                                            anchor: "0"
                                        },
                                        items: [{
                                            xtype: 'toolbar',
                                            layout: 'hbox',
                                            style: "padding:5px",
                                            fieldLabel: Lang.szukaj,
                                            isFormField: false,
                                            border: false,
                                            frame: false,
                                            items: [{
                                                flex: 1,
                                                id: this.id + "fszukaj",
                                                name: "fszukaj",
                                                xtype: 'textfield',
                                                isFormField: true,
                                                allowBlank: true,
                                                blankText: Lang.pole_nie_moze_byc_puste
                                            }, {
                                                xtype: 'button',
                                                width: 80,
                                                id: this.id + "bszukaj",
                                                isFormField: false,
                                                text: ">>",
                                                scope: this,
                                                handler: this.searchSzukajHereMap
                                            }]
                                        }]
                                    }, {
                                        region: 'center',
                                        id: this.id + 'panelcenter',
                                        xtype: 'panel',
                                        layout: 'fit',
                                        border: false,
                                        frame: false,
                                        items: [{
                                            xtype: 'box',
                                            id: this.id + "boxImage",
                                            autoEl: {
                                                tag: 'div',
                                                cls: "position:relative"
                                            }
                                        }]
                                    }]
                                });
                                Ext.getCmp(this.id + "fszukaj").setValue(Const.STARTMAPLOCALIZATION);
                            }
                            var canvasBoxel = Ext.getCmp(this.id + "boxImage");
                            var canvasBoxelRendered = Ext.Function.bind(function () {
                                this.geocoder = AppMapPlatform.getGeocodingService();
                                var callserInitHereMap = function (point, zoominit) {
                                    if (!this.mapkaInited) {
                                        var myOptions = {
                                            zoom: zoominit > 0 ? zoominit : 8,
                                            center: point
                                        };
                                        var defaultLayers = AppMapPlatform.createDefaultLayers();
                                        this.heremap = new H.Map(canvasBoxel.el.dom, defaultLayers.normal.map, myOptions);
                                        var mapEvents = new H.mapevents.MapEvents(this.heremap);
                                        var behavior = new H.mapevents.Behavior(mapEvents);
                                        this.heremapUi = H.ui.UI.createDefault(this.heremap, defaultLayers);
                                        this.heremapUi.getControl('mapsettings').setDisabled(true);
                                        this.heremapUi.getControl('mapsettings').setVisibility(false);
                                        this.heremapUi.getControl('zoom').setAlignment('top-left');
                                        this.heremapUi.getControl('scalebar').setVisibility(false);
                                        this.heremap.addEventListener('mapviewchangeend', function () {
                                            this.scallbackKeepUserHereMAP();
                                        }, true, this);
                                        this.createMapIcons();
                                        this.heremap.addEventListener('pointermove', function (event) {
                                            if (event.target instanceof H.map.Marker) {
                                                this.heremap.getViewPort().element.style.cursor = 'pointer';
                                            } else {
                                                this.heremap.getViewPort().element.style.cursor = 'auto';
                                            }
                                        }, false, this);
                                        canvasBoxel.on("resize", function () {
                                            this.heremap.getViewPort().resize();
                                        }, this);
                                    } else {
                                        this.heremap.removeObjects(this.heremap.getObjects());
                                    }
                                    var rsss = {};
                                    var str = this.grid.store;
                                    for (var i = 0; i < str.getCount(); i++) {
                                        var r = str.getAt(i);
                                        var id = r.get("id");
                                        rsss[id] = r;
                                    }
                                    for (var i = 0; i < this.zbiornikidatasOld.length; i++) {
                                        var currentObject = this.zbiornikidatasOld[i];
                                    }
                                    for (var i = 0; i < this.zbiornikidatas.length; i++) {
                                        var currentObject = this.zbiornikidatas[i];
                                        currentObject.currentRow = rsss[currentObject.id];
                                        if (rsss[currentObject.id]) {
                                            rsss[currentObject.id].currentObject = currentObject;
                                            var dx = currentObject.x;
                                            var dy = currentObject.y;
                                            if (dx > 0 && dy > 0) {
                                                currentObject.marker = this.createMarker({
                                                    lat: dx,
                                                    lng: dy
                                                }, currentObject.nazwa, currentObject.currentRow, currentObject);
                                                this.heremap.addObject(currentObject.marker);
                                                currentObject.marker.addEventListener('tap', this.markecrSelectClick, false, this);
                                            }
                                        }
                                    }
                                    if (!this.mapkaInited) {
                                        this.scallbackKeepUserHereMAP();
                                    }
                                    this.mapkaInited = true;
                                };
                                var poscenterss = Ext.state.Manager.get("stanygmappos");
                                Ext.Function.defer(function () {
                                    if (poscenterss != null && poscenterss.lat != 0 && poscenterss.lng != 0) {
                                        var latlngCenterInit = {
                                            lat: poscenterss.lat,
                                            lng: poscenterss.lng
                                        };
                                        var zoominit = poscenterss.zoom;
                                        callserInitHereMap.call(this, latlngCenterInit, zoominit);
                                        this.resolverPointToAddr(latlngCenterInit, Ext.Function.bind(function (addr) {
                                            Ext.getCmp(this.id + "fszukaj").setValue(addr);
                                        }, this), null);
                                    } else {
                                        var fszukajvalue = Ext.getCmp(this.id + "fszukaj").getValue();
                                        this.resolverAddrToPoint(fszukajvalue, Ext.Function.bind(function (point) {
                                            callserInitHereMap.call(this, point, 8);
                                        }, this), null);
                                    }
                                }, 500, this);
                            }, this);
                            if (canvasBoxel.rendered) {
                                canvasBoxelRendered();
                            } else {
                                canvasBoxel.on("afterrender", canvasBoxelRendered, this);
                            }
                        } catch (exp) {
                            if (console && console.exception)
                                console.exception(exp);
                        }
                    }, this), null);
                } catch (exp) {
                    if (console && console.exception)
                        console.exception(exp);
                }
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    createMarker: function (latLng, title, row, object) {
        var marker = new H.map.Marker(latLng, {
            icon: this.getIconZbiornikState("u", object)
        });
        marker.setData({
            title: title,
            row: row,
            object: object
        });
        return marker;
    },
    createMapIcons: function () {
        this.mapUnselectedLowIcon = new H.map.Icon('<svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" height="29.801325" width="16.709005" id="svg12" version="1.1" viewBox="0 0 16.709005 29.801325" enable-background="new 0 0 56.068 100" style="cursor: pointer"> <metadata id="metadata18"> <rdf:RDF> <cc:Work rdf:about=""> <dc:format>image/svg+xml</dc:format> <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" /> <dc:title></dc:title> </cc:Work> </rdf:RDF> </metadata> <defs id="defs16" /> <path style="fill: #A93737; stroke-width: 0.29801324" id="path10" d="M 8.354503,0 C 3.740662,0 0,3.740662 0,8.354503 c 0,4.613841 8.354503,21.446821 8.354503,21.446821 0,0 8.354503,-16.832682 8.354503,-21.446821 C 16.709006,3.740364 12.968642,0 8.354503,0 Z m 0,12.062682 c -2.047649,0 -3.707881,-1.66053 -3.707881,-3.707881 0,-2.047351 1.660232,-3.707881 3.707881,-3.707881 2.047649,0 3.707881,1.659934 3.707881,3.707881 0,2.047947 -1.659934,3.707881 -3.707881,3.707881 z" /> </svg>');
        this.mapUnselectedMediumIcon = new H.map.Icon('<svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" height="29.801325" width="16.709005" id="svg12" version="1.1" viewBox="0 0 16.709005 29.801325" enable-background="new 0 0 56.068 100" style="cursor: pointer"> <metadata id="metadata18"> <rdf:RDF> <cc:Work rdf:about=""> <dc:format>image/svg+xml</dc:format> <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" /> <dc:title></dc:title> </cc:Work> </rdf:RDF> </metadata> <defs id="defs16" /> <path style="fill: #936726; stroke-width: 0.29801324" id="path10" d="M 8.354503,0 C 3.740662,0 0,3.740662 0,8.354503 c 0,4.613841 8.354503,21.446821 8.354503,21.446821 0,0 8.354503,-16.832682 8.354503,-21.446821 C 16.709006,3.740364 12.968642,0 8.354503,0 Z m 0,12.062682 c -2.047649,0 -3.707881,-1.66053 -3.707881,-3.707881 0,-2.047351 1.660232,-3.707881 3.707881,-3.707881 2.047649,0 3.707881,1.659934 3.707881,3.707881 0,2.047947 -1.659934,3.707881 -3.707881,3.707881 z" /> </svg>');
        this.mapUnselectedHighIcon = new H.map.Icon('<svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" height="29.801325" width="16.709005" id="svg12" version="1.1" viewBox="0 0 16.709005 29.801325" enable-background="new 0 0 56.068 100" style="cursor: pointer"> <metadata id="metadata18"> <rdf:RDF> <cc:Work rdf:about=""> <dc:format>image/svg+xml</dc:format> <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" /> <dc:title></dc:title> </cc:Work> </rdf:RDF> </metadata> <defs id="defs16" /> <path style="fill: #09884E; stroke-width: 0.29801324" id="path10" d="M 8.354503,0 C 3.740662,0 0,3.740662 0,8.354503 c 0,4.613841 8.354503,21.446821 8.354503,21.446821 0,0 8.354503,-16.832682 8.354503,-21.446821 C 16.709006,3.740364 12.968642,0 8.354503,0 Z m 0,12.062682 c -2.047649,0 -3.707881,-1.66053 -3.707881,-3.707881 0,-2.047351 1.660232,-3.707881 3.707881,-3.707881 2.047649,0 3.707881,1.659934 3.707881,3.707881 0,2.047947 -1.659934,3.707881 -3.707881,3.707881 z" /> </svg>');
        this.mapSelectedLowIcon = new H.map.Icon('<svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" height="29.801325" width="16.709005" id="svg12" version="1.1" viewBox="0 0 16.709005 29.801325" enable-background="new 0 0 56.068 100" style="cursor: pointer"> <metadata id="metadata18"> <rdf:RDF> <cc:Work rdf:about=""> <dc:format>image/svg+xml</dc:format> <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" /> <dc:title></dc:title> </cc:Work> </rdf:RDF> </metadata> <defs id="defs16" /> <path style="fill: #C13F3F; stroke-width: 0.29801324" id="path10" d="M 8.354503,0 C 3.740662,0 0,3.740662 0,8.354503 c 0,4.613841 8.354503,21.446821 8.354503,21.446821 0,0 8.354503,-16.832682 8.354503,-21.446821 C 16.709006,3.740364 12.968642,0 8.354503,0 Z m 0,12.062682 c -2.047649,0 -3.707881,-1.66053 -3.707881,-3.707881 0,-2.047351 1.660232,-3.707881 3.707881,-3.707881 2.047649,0 3.707881,1.659934 3.707881,3.707881 0,2.047947 -1.659934,3.707881 -3.707881,3.707881 z" /> </svg>');
        this.mapSelectedMediumIcon = new H.map.Icon('<svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" height="29.801325" width="16.709005" id="svg12" version="1.1" viewBox="0 0 16.709005 29.801325" enable-background="new 0 0 56.068 100" style="cursor: pointer"> <metadata id="metadata18"> <rdf:RDF> <cc:Work rdf:about=""> <dc:format>image/svg+xml</dc:format> <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" /> <dc:title></dc:title> </cc:Work> </rdf:RDF> </metadata> <defs id="defs16" /> <path style="fill: #AA762C; stroke-width: 0.29801324" id="path10" d="M 8.354503,0 C 3.740662,0 0,3.740662 0,8.354503 c 0,4.613841 8.354503,21.446821 8.354503,21.446821 0,0 8.354503,-16.832682 8.354503,-21.446821 C 16.709006,3.740364 12.968642,0 8.354503,0 Z m 0,12.062682 c -2.047649,0 -3.707881,-1.66053 -3.707881,-3.707881 0,-2.047351 1.660232,-3.707881 3.707881,-3.707881 2.047649,0 3.707881,1.659934 3.707881,3.707881 0,2.047947 -1.659934,3.707881 -3.707881,3.707881 z" /> </svg>');
        this.mapSelectedHighIcon = new H.map.Icon('<svg xmlns:dc="http://purl.org/dc/elements/1.1/" xmlns:cc="http://creativecommons.org/ns#" xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#" xmlns:svg="http://www.w3.org/2000/svg" xmlns="http://www.w3.org/2000/svg" height="29.801325" width="16.709005" id="svg12" version="1.1" viewBox="0 0 16.709005 29.801325" enable-background="new 0 0 56.068 100" style="cursor: pointer"> <metadata id="metadata18"> <rdf:RDF> <cc:Work rdf:about=""> <dc:format>image/svg+xml</dc:format> <dc:type rdf:resource="http://purl.org/dc/dcmitype/StillImage" /> <dc:title></dc:title> </cc:Work> </rdf:RDF> </metadata> <defs id="defs16" /> <path style="fill: #0BA05A; stroke-width: 0.29801324" id="path10" d="M 8.354503,0 C 3.740662,0 0,3.740662 0,8.354503 c 0,4.613841 8.354503,21.446821 8.354503,21.446821 0,0 8.354503,-16.832682 8.354503,-21.446821 C 16.709006,3.740364 12.968642,0 8.354503,0 Z m 0,12.062682 c -2.047649,0 -3.707881,-1.66053 -3.707881,-3.707881 0,-2.047351 1.660232,-3.707881 3.707881,-3.707881 2.047649,0 3.707881,1.659934 3.707881,3.707881 0,2.047947 -1.659934,3.707881 -3.707881,3.707881 z" /> </svg>');
    },
    currentElo: null,
    canvasBoxelImageElClick: null,
    canvasBoxelImage: null,
    graficznaRowUnSelectOld: function () {
        if (this.currentElo != null && this.currentElo.el != null) {
            this.currentElo.el.removeCls("mapkav-item-selected");
        }
        this.currentElo = null;
    },
    graficznaRowSelectNew: function () {
        this.currentElo = this.currentObject.elo;
        if (this.currentElo != null && this.currentElo.el != null) {
            this.currentElo.el.addCls("mapkav-item-selected");
        }
    },
    graficznaRowClearSelect: function () {
        if (this.currentElo != null && this.currentElo.el != null) {
            this.currentElo.el.removeCls("mapkav-item-selected");
        }
        this.currentElo = null;
    },
    refreshDatastoreMapkaGraficzna: function () {
        sendAjaxDataJsonRpcL("rest/zbiornikigraph/getZbiornikListForGraphMap", {
            data: [null]
        }, this, function (data) {
            if (data.status) {
                try {
                    var obiekt = data.data;
                    this.zbiornikidatasOld = this.zbiornikidatas == null ? [] : this.zbiornikidatas;
                    this.zbiornikidatas = obiekt.zbiornikidatas;
                    var cwidth = obiekt.width;
                    var cheight = obiekt.height;
                    for (var i = 0; i < this.zbiornikidatas.length; i++) {
                        this.zbiornikidatas[i]["object"] = this.zbiornikidatas[i];
                        this.zbiornikidatas[i]["elo"] = {
                            el: null
                        };
                    }
                    var splitcttrmapka = Ext.getCmp(this.id + "splitcttrmapka");
                    var splitnorthmapka = Ext.getCmp(this.id + "splitnorthmapka");
                    try {
                        if (!this.mapkaInited) {
                            if (Ext.getCmp(this.id + "boxImage")) {
                                Ext.getCmp(this.id + "boxImage").destroy();
                            }
                            splitnorthmapka.add({
                                xtype: 'box',
                                id: this.id + "boxImage",
                                autoEl: {
                                    tag: 'div',
                                    cls: "mapka-canvas"
                                }
                            });
                        }
                        var canvasBoxel = Ext.getCmp(this.id + "boxImage");

                        function canvasBoxelRendered() {
                            var canvasBoxelCanvas = canvasBoxel.el;
                            if (this.canvasBoxelImage != null)
                                this.canvasBoxelImage.remove();
                            if (cwidth > 0 && cheight > 0) {
                                this.canvasBoxelImage = canvasBoxelCanvas.insertFirst({
                                    tag: "div",
                                    style: "background-image: url(rest/zbiornikigraph/getJpg);",
                                    cls: "mapkav-canvas-image"
                                });
                                this.canvasBoxelImage.applyStyles({
                                    width: cwidth + "px",
                                    height: cheight + "px"
                                });
                            }
                            var rsss = {};
                            var str = this.grid.store;
                            for (var i = 0; i < str.getCount(); i++) {
                                var r = str.getAt(i);
                                var id = r.get("id");
                                rsss[id] = r;
                            }
                            for (var i = 0; i < this.zbiornikidatas.length; i++) {
                                this.currentObject = this.zbiornikidatas[i];
                                this.currentElo = this.currentObject.elo;
                                if (rsss[this.currentObject.id] == null) {
                                    break;
                                }
                                this.currentObject.currentRow = rsss[this.currentObject.id];
                                rsss[this.currentObject.id].currentObject = this.currentObject;
                                var dx = this.currentObject.x;
                                var dy = this.currentObject.y;
                                if (dx > cwidth)
                                    this.currentObject.x = dx = 0;
                                if (dy > cheight)
                                    this.currentObject.y = dy = 0;
                                if (dx > 0 && dy > 0) {
                                    this.createElementVisualForObject(this.currentObject, this.currentElo);
                                    this.moveElementVisual(this.currentElo, dx, dy);
                                }
                            }
                            if (!this.mapkaInited) {
                                if (cwidth > 0 && cheight > 0) {
                                    if (splitnorthmapka.getWidth() == 300)
                                        splitnorthmapka.setWidth(cwidth + 5);
                                } else {
                                    canvasBoxelCanvas.insertFirst({
                                        tag: "span",
                                        cls: "mapkav-canvas-txt",
                                        html: Lang.brak_mapki
                                    });
                                }
                            }
                            this.mapkaInited = true;
                        }

                        if (canvasBoxel.rendered) {
                            canvasBoxelRendered.call(this);
                        } else {
                            canvasBoxel.on("afterrender", canvasBoxelRendered, this);
                        }
                    } catch (exp) {
                        if (console && console.exception)
                            console.exception(exp);
                    }
                } catch (exp) {
                    if (console && console.exception)
                        console.exception(exp);
                }
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    createElementVisualForObject: function (cobject, celo) {
        var mcls = "mapkav-elem-zbiornik-" + this.visalElemModCls(cobject);
        var oo = {
            tag: "div",
            cls: "mapkav-elem-item " + mcls,
            cn: [{
                tag: 'div',
                cls: "mapkav-elem-item-ct",
                cn: [{
                    tag: 'div',
                    cls: "mapkav-elem-item-pointer"
                }, {
                    tag: 'div',
                    cls: "mapkav-elem-item-node",
                    cn: [{
                        tag: "span",
                        html: cobject.nazwa
                    }]
                }]
            }]
        };
        var el = this.canvasBoxelImage.createChild(oo, null, false);
        var elc = el.child(".mapkav-elem-item-ct");
        var elp = elc.child(".mapkav-elem-item-pointer");
        var eln = elc.child(".mapkav-elem-item-node");
        elp.alignTo(el, "tl-tl", [4, 8]);
        eln.alignTo(elp, "l-r", [2, 0]);
        var offsetNToEl = eln.getOffsetsTo(el);
        var onx = offsetNToEl[0];
        var ony = offsetNToEl[1];
        el.setWidth(eln.getWidth() + onx + 4);
        el.setHeight(eln.getHeight() + ony + 4);
        elc.setWidth(eln.getWidth() + onx + 4);
        elc.setHeight(eln.getHeight() + ony + 4);
        celo.el = el;
        celo.elp = elp;
        celo.eln = eln;
        var opts = {
            currentObject: cobject,
            currentElo: celo
        };
        el.on("click", this.canvasBoxelImageElClick, this, opts);
        el.hover(function (e, t, o) {
            if (o.currentElo.el != null) {
                o.currentElo.el.addCls("mapkav-item-hover");
            }
        }, function (e, t, o) {
            if (o.currentElo.el != null) {
                o.currentElo.el.removeCls("mapkav-item-hover");
            }
        }, null, opts);
    },
    moveElementVisual: function (currentElo, dx, dy) {
        var el = this.currentElo.el;
        var elp = this.currentElo.elp;
        var eln = this.currentElo.eln;
        var offsetPToEl = elp.getOffsetsTo(el);
        var ox = offsetPToEl[0];
        var oy = offsetPToEl[1];
        var ix = elp.getWidth() / 2;
        var iy = elp.getHeight() / 2;
        var px = dx - ox - ix;
        var py = dy - oy - iy;
        el.setLeft(px);
        el.setTop(py);
    },
    canvasBoxelImageElClick: function (e, t, o) {
        if (this.currentElo != null && this.currentElo.el != null) {
            this.currentElo.el.removeCls("mapkav-item-selected");
        }
        this.currentObject = o.currentObject;
        this.currentElo = o.currentElo;
        if (this.currentElo.el != null) {
            this.currentElo.el.addCls("mapkav-item-selected");
        }
        this.selectRecord(this.currentObject.currentRow);
    }
});