Ext.define('ViewPlyny', {
    extend: 'Ext.panel.Panel',
    grid: null,
    windowObject: null,
    id: "plyny",
    taskContextMenu: null,
    rowContext: null,
    statics: {
        accessPropertyName: "access.manage.plyny",
        accessPropertyViewName: "access.view.plyny"
    },
    cat: "k",
    text: Lang.plyny,
    buttonIconCls: "iconPlyn",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.buildGrid();
        this.items = [{
            region: "north",
            xtype: 'toolbar',
            items: [{
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconNew",
                text: Lang.nowy.capitalise(),
                scope: this,
                handler: this.dodajObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewPlyny.accessPropertyName + "/add")
            }, {
                id: this.id + "bedit",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconEdit",
                text: Lang.edytuj.capitalise(),
                scope: this,
                handler: this.edytujObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewPlyny.accessPropertyName + "/update")
            }, {
                id: this.id + "bdelete",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconDelete",
                text: Lang.usun.capitalise(),
                scope: this,
                handler: this.usunObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewPlyny.accessPropertyName + "/delete")
            }, "-", {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconPDF",
                text: Lang.pdf.capitalise(),
                scope: this,
                handler: this.getDownloadLsPdf
            }, {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconEXCEL",
                text: Lang.excel.capitalise(),
                scope: this,
                handler: this.getDownloadLsExcel
            }, "-", {
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconRefresh",
                text: Lang.odswiez.capitalise(),
                scope: this,
                handler: this.refreshDatastore
            }]
        }, {
            region: "center",
            xtype: 'panel',
            frame: false,
            border: false,
            layout: 'fit',
            items: [this.grid]
        }];
        this.callParent(arguments);
    },
    showFirst: function (eventMain) {
        eventMain.on("firmaChangeContext", this.changeContext, this);
    },
    remove: function () {
        this.callParent(arguments);
    },
    show: function () {
        this.callParent(arguments);
        this.showState = true;
        this.refreshDatastore();
    },
    hide: function () {
        this.showState = false;
        this.callParent(arguments);
    },
    onDestroy: function () {
        this.taskContextMenu.destroy();
        this.callParent(arguments);
    },
    changeContext: function (eAppFIRMACTX, eAppFIRMACTXold) {
        if (this.showState) {
            this.refreshDatastore();
        }
    },
    buildGrid: function () {
        var cols = [{
            header: "ID",
            width: 145,
            sortable: true,
            dataIndex: 'id',
            filter: {
                type: 'numeric'
            },
            filterable: true,
            hidden: true
        }, {
            header: Lang.nazwa,
            flex: 2,
            sortable: true,
            dataIndex: 'nazwa',
            filter: {
                type: 'string'
            },
            sorter: Ext.bind(customSorterForGrid, this, ["nazwa"], true),
            filterable: true
        }, {
            header: Lang.eiid,
            flex: 2,
            sortable: true,
            dataIndex: 'eiid',
            filter: {
                type: 'string'
            },
            sorter: Ext.bind(customSorterForGrid, this, ["eiid"], true),
            filterable: true
        }, {
            header: Lang.paliwo,
            flex: 1,
            sortable: true,
            dataIndex: 'fuel',
            renderer: renderBool2,
            filter: {
                type: 'boolean'
            },
            filterable: true,
            hidden: Const.OPTIMALFLAG || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
        }, {
            header: Lang.zakres_gestosci,
            width: 145,
            sortable: false,
            dataIndex: 'gestoscminmax',
            hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG
        }];
        if (Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG) {
            removeObjectFromArrayById(cols, "eiid");
            removeObjectFromArrayById(cols, "fuel");
            removeObjectFromArrayById(cols, "gestoscminmax");
        } else if (Const.OPTIMALFLAG) {
            removeObjectFromArrayById(cols, "fuel");
        }
        this.grid = Ext.create('Ext.ux.grid.GridPanelExd', {
            plugins: {
                gridfilters: true
            },
            store: Ext.create('Ext.data.Store', {
                autoLoad: false,
                fields: [{
                    name: 'id',
                    mapping: 'id'
                }, {
                    name: 'nazwa',
                    mapping: 'nazwa'
                }, {
                    name: "eiid",
                    mapping: "eiid"
                }, {
                    name: "fuel",
                    mapping: "fuel"
                }, {
                    name: "gestoscminmax",
                    mapping: "gestoscminmax"
                }],
                proxy: {
                    type: 'memory',
                    reader: {
                        type: 'array'
                    }
                }
            }),
            columns: cols,
            frame: false,
            border: false,
            stateId: "grid-plyny-" + id,
            stateful: true,
            stripeRows: true,
            enableHdMenu: true,
            selModel: {
                selType: 'rowmodel',
                mode: 'single',
                allowDeselect: false
            }
        });
        this.grid.addListener("rowdblclick", function () {
            if (validPrivilegesOperatorComplex(ViewPlyny.accessPropertyName + "/update"))
                this.edytujObiekt();
        }, this);
        this.grid.addListener("render", function () {
            this.manageSelect(this.grid.getSelectionModel());
        }, this);
        this.grid.addListener("select", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.grid.addListener("selectionchange", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.taskContextMenu = new Ext.menu.Menu();
        this.taskContextMenu.add([{
            iconAlign: 'left',
            iconCls: "iconNew",
            text: Lang.nowy.capitalise(),
            scope: this,
            handler: this.dodajObiekt,
            disabled: !validPrivilegesOperatorComplex(ViewPlyny.accessPropertyName + "/add")
        }, {
            id: this.id + "bedit2",
            iconAlign: 'left',
            iconCls: "iconEdit",
            text: Lang.edytuj.capitalise(),
            scope: this,
            handler: this.edytujObiekt,
            disabled: !validPrivilegesOperatorComplex(ViewPlyny.accessPropertyName + "/update")
        }, {
            id: this.id + "bdelete2",
            iconAlign: 'left',
            iconCls: "iconDelete",
            text: Lang.usun.capitalise(),
            scope: this,
            handler: this.usunObiekt,
            disabled: !validPrivilegesOperatorComplex(ViewPlyny.accessPropertyName + "/delete")
        }, "-", {
            iconAlign: 'left',
            iconCls: "iconPDF",
            text: Lang.pdf.capitalise(),
            scope: this,
            handler: this.getDownloadLsPdf
        }, {
            iconAlign: 'left',
            iconCls: "iconEXCEL",
            text: Lang.excel.capitalise(),
            scope: this,
            handler: this.getDownloadLsExcel
        }, "-", {
            iconAlign: 'left',
            iconCls: "iconRefresh",
            text: Lang.odswiez.capitalise(),
            scope: this,
            handler: this.refreshDatastore
        }]);
        this.grid.addListener("rowcontextmenu", function (grid, record, tr, rowIndex, e) {
            this.rowContext = this.grid.getStore().getAt(rowIndex);
            this.manageSelect();
            this.taskContextMenu.showAt(e.getXY());
            e.preventDefault();
        }, this);
        this.taskContextMenu.addListener("hide", function () {
            this.rowContext = null;
        }, this);
        this.grid.addListener("containercontextmenu", function (grid, e) {
            this.rowContext = null;
            this.manageSelect();
            this.taskContextMenu.showAt(e.getXY());
            e.preventDefault();
        }, this);
    },
    manageSelect: function () {
        var bedit = Ext.getCmp(this.id + "bedit");
        var bdelete = Ext.getCmp(this.id + "bdelete");
        var bedit2 = Ext.getCmp(this.id + "bedit2");
        var bdelete2 = Ext.getCmp(this.id + "bdelete2");
        if (!this.grid.getSelectionModel().hasSelection() || this.grid.getSelectionModel().getSelection() == null || this.grid.getSelectionModel().getSelection().length == 0) {
            bdelete.setDisabled(true);
            bedit.setDisabled(true);
        } else {
            var row = this.grid.getSelectionModel().getSelection()[0];
            bdelete.setDisabled(!validPrivilegesOperatorComplex(ViewPlyny.accessPropertyName + "/delete"));
            bedit.setDisabled(!validPrivilegesOperatorComplex(ViewPlyny.accessPropertyName + "/update"));
        }
        if (this.rowContext == null) {
            bdelete2.setDisabled(true);
            bedit2.setDisabled(true);
        } else {
            bdelete2.setDisabled(!validPrivilegesOperatorComplex(ViewPlyny.accessPropertyName + "/delete"));
            bedit2.setDisabled(!validPrivilegesOperatorComplex(ViewPlyny.accessPropertyName + "/update"));
        }
    },
    getDownloadLsPdf: function () {
        postFormAsync("rest/pdfexcelexport/downloadExport", {
            format: "pdf",
            name: Lang.plyny,
            defd: Ext.encode(this.grid.getDefGridDataCSF({
                colsEx: [],
                colsIn: [],
                useRenderer: true,
                fnd: function (v, c, metaData, record, rowIndex, colIndex, store) {
                    if (c == "fuel") {
                        if (v == 'true')
                            v = Lang.tak;
                        else
                            v = Lang.nie;
                    }
                    return v;
                }
            }))
        }, null);
    },
    getDownloadLsExcel: function () {
        postFormAsync("rest/pdfexcelexport/downloadExport", {
            format: "excel",
            name: Lang.plyny,
            defd: Ext.encode(this.grid.getDefGridDataCSF({
                colsEx: [],
                colsIn: [],
                useRenderer: true,
                fnd: function (v, c, metaData, record, rowIndex, colIndex, store) {
                    if (c == "fuel") {
                        if (v == 'true')
                            v = Lang.tak;
                        else
                            v = Lang.nie;
                    }
                    return v;
                }
            }))
        }, null);
    },
    refreshDatastore: function () {
        updateCzasRefreshSerwer();
        if (this.grid && this.grid.rendered)
            this.grid.mask();
        sendAjaxDataJsonRpcL("rest/plyny/getList", null, this, function (data) {
            if (this.grid && this.grid.rendered)
                this.grid.unmask();
            if (data.status) {
                this.grid.store.loadData(data.data);
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    dodajObiekt: function () {
        windowObject = Ext.create('WindowPlyn', {
            callbackF: Ext.Function.bind(this.refreshDatastore, this)
        });
        windowObject.showW(0);
    },
    edytujObiekt: function () {
        var id = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        windowObject = Ext.create('WindowPlyn', {
            callbackF: Ext.Function.bind(this.refreshDatastore, this)
        });
        windowObject.showW(id);
    },
    usunObiekt: function () {
        var id = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        Ext.MessageBox.show({
            title: Lang.usuwanie,
            message: Lang.czy_napewno_usunac_obiekt,
            buttons: Ext.MessageBox.YESNO,
            scope: this,
            fn: function (btn) {
                if (btn == "yes") {
                    sendAjaxDataJsonRpcL("rest/plyny/deleteObject", {
                        id: id
                    }, this, function (data) {
                        if (data.status) {
                        } else {
                            Ext.MessageBox.alert(Lang.error, data.error);
                        }
                        this.refreshDatastore();
                    });
                }
            }
        });
    }
});
