Ext.define('WindowInventorizationResult', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.inwentaryzacja.capitalise(),
    width: 800,
    minHeight: 200,
    closable: false,
    collapsible: false,
    resizable: false,
    scrollable: true,
    inventorizationGrid: null,
    layout: 'fit',
    initComponent: function () {
        this.buildGrid();
        this.cid = Ext.id();
        this.items = [{
            xtype: 'panel',
            layout: 'column',
            items: [{
                columnWidth: 1,
                xtype: 'form',
                id: this.cid + 'formpanel',
                bodyStyle: 'padding:10px 10px 10px 10px',
                layout: 'anchor',
                defaults: {
                    anchor: '0',
                    labelWidth: 100
                },
                items: [{
                    id: this.cid + "czas",
                    name: "czas",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.czas,
                    allowBlank: false,
                    editable: false
                }, {
                    xtype: "container",
                    scrollable: true,
                    maxHeight: 500,
                    layout: {
                        type: "vbox",
                        align: "stretch"
                    },
                    flex: 1,
                    items: [this.inventorizationGrid]
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            iconAlign: 'left',
            iconCls: "iconPDF",
            text: Lang.pdf.capitalise(),
            scope: this,
            handler: this.getDownloadLsPdf
        }, {
            iconAlign: 'left',
            iconCls: "iconEXCEL",
            text: Lang.excel.capitalise(),
            scope: this,
            handler: this.getDownloadLsExcel
        }, {
            id: this.cid + 'cancel',
            text: Lang.zamknij,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function () {
        if (!validPrivilegesOperatorComplex("access.view.stany")) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
        }
        performAjaxLPromise('rest/inventorization/getResult')
            .then(data => {
                let raws = data.raws;
                Ext.getCmp(this.cid + "czas").setValue(data.czas)
                this.inventorizationGrid.store.loadData(raws);
                this.show();
                this.center();
                this.setKeyMap({
                    'ESC': {
                        scope: this,
                        handler: this.closeOp
                    }
                });
            })
            .catch(error => {
                handleCommonCallBackAjaxRPCError(error);
            })
    },
    buildGrid: function () {
        this.inventorizationGrid = new Ext.ux.grid.GridPanelExd({
            itemId: 'invgrid',
            minHeight: 200,
            store: Ext.create('Ext.data.Store', {
                autoLoad: false,
                remoteSort: false,
                remoteFilter: false,
                model: Ext.define(null, {
                    extend: 'Ext.data.Model',
                    fields: ["zbiornik", "realstate", "arithstate"]
                }),
                proxy: {
                    type: 'memory',
                    reader: {
                        type: 'json'
                    }
                },
                sorters: [{
                    property: "expireDate",
                    direction: "ASC"
                }]
            }),
            columns: [{
                header: Lang.zbiornik,
                width: 145,
                sortable: true,
                dataIndex: 'zbiornik',
                filter: {
                    type: 'string'
                }
            }, {
                header: Lang.rzeczywisty_stan_zbiornika,
                flex: 1,
                sortable: true,
                dataIndex: 'realstate',
                filter: {
                    type: 'numeric'
                },
                renderer: function (value, meta, record, rowIndex, colIndex, store) {
                    value = Ext.Number.roundToPrecision(value, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                    return value;
                },
                hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || !validPrivilegesOperatorComplex("access.view.skpspomiary")
            }, {
                header: Lang.arytmetyczny_stan_zbiornika,
                flex: 1,
                sortable: true,
                dataIndex: 'arithstate',
                filter: {
                    type: 'numeric'
                },
                renderer: function (value, meta, record, rowIndex, colIndex, store) {
                    value = Ext.Number.roundToPrecision(value, 3).toString().replace(".", Ext.DECIMAL_SEPARATOR);
                    return value;
                }
            }, {
                header: Lang.addInfo,
                flex: 2,
                sortable: true,
                dataIndex: 'addinfo',
                filter: {
                    type: 'string'
                },
                renderer: function (value) {
                    if (value == "outdated_data") {
                        return Lang.uwaga_mozliwe_dane_nieaktualne;
                    } else {
                        return "-"
                    }
                }
            }]
        });
    },
    getDownloadLsPdf: function () {
        postFormAsync("rest/pdfexcelexport/downloadExport", {
            format: "pdf",
            name: Lang.inwentaryzacja + " (" + Ext.getCmp(this.cid + "czas").getValue() + ")",
            defd: Ext.encode(this.inventorizationGrid.getDefGridDataCSF({
                colsEx: [],
                colsIn: [],
                useRenderer: true
            }))
        }, null);
    },
    getDownloadLsExcel: function () {
        postFormAsync("rest/pdfexcelexport/downloadExport", {
            format: "excel",
            name: Lang.inwentaryzacja + " (" + Ext.getCmp(this.cid + "czas").getValue() + ")",
            defd: Ext.encode(this.inventorizationGrid.getDefGridDataCSF({
                colsEx: [],
                colsIn: [],
                useRenderer: true
            }))
        }, null);
    },
    closeOp: function () {
        this.close();
    }
});
