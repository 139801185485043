Ext.define('WindowMassEditSetProperties', {
    extend: 'Ext.ux.window.WindowExd',
    title: "",
    width: 650,
    closable: false,
    collapsible: false,
    scrollable: true,
    modal: true,
    resizable: false,
    layout: 'fit',
    typ: Const.MASS_EDIT_TYPE_POJAZD,//MASS_EDIT_TYPE_ODBIORCA
    initComponent: function (config) {
        this.cid = Ext.id();
        this.title = this.typ == Const.MASS_EDIT_TYPE_POJAZD ? Lang.ustawienia_pojazdu : Lang.ustawienia_odbiorcy;
        let ogolne = this.buildFormPanel1();
        let zaawansowane = this.buildFormPanel2();
        let limity = this.buildFormPanel3();
        let normyzuz = this.buildFormPanel4();
        let ustind = this.buildFormPanel5();
        this.items = [{
            xtype: 'tabpanel',
            autoWidth: true,
            height: 555,
            border: false,
            deferredRender: false,
            activeTab: 0,
            scope: this,
            items: [ogolne, zaawansowane, limity, normyzuz, ustind]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.dalej,
            scope: this,
            handler: this.saveOp
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function () {
        if (!validPrivilegesOperatorComplex("access.manage.pojazdy")) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.defaultMaxMileageDiff = null;
        this.down("#opis").setValue(null);
        this.down("#rokprodukcji").setValue(null);
        this.down("#identeuro").setValue(null);
        this.limitydatas = [];
        this.down('#limitpanel').setLimitList(this.limitydatas);
        this.down("#ident_tryb").setValue(Const.IDENT_PODWOJNA);
        var individualOpts = this.down("#individualopts");
        individualOpts.fireEvent("change", individualOpts, false);
        this.down("#grupa").getStore().load({
            scope: this,
            callback: function (records, operation, success) {
                if (this.down("#grupa")) {
                    this.down("#grupa").getStore().insert(0, [{
                        nazwa: Lang.brak_grupy,
                        id: 0
                    }]);
                }
            }
        });
        this.down("#plyn").getStore().insert(0, [{
            nazwa: Lang.brak_plynu,
            id: null
        }]);
        sendAjaxDataJsonRpcL("rest/plyny/getShortList", null, this, function (data) {
            if (data) {
                this.show();
                this.center();
                this.normals = [];
                this.plynls = data.data;
                for (var i = 0; i < this.plynls.length; i++) {
                    var pl = this.plynls[i];
                    this.down("#plyn").getStore().insert(i + 1, [{
                        nazwa: pl.nazwa,
                        id: pl.id
                    }]);
                    var iddl = Ext.id();
                    let normaPlyn = {
                        anchor: "0",
                        xtype: 'panel',
                        disabled: true,
                        frame: false,
                        border: false,
                        layout: {
                            type: WindowExdMobileDEVICEV ? 'vbox' : 'hbox',
                            align: 'stretch'
                        },
                        itemId: iddl + "_norma_panel",
                        bodyStyle: 'padding:0px 0px 5px 0px',
                        items: [{
                            flex: 1,
                            margin: "0 5 0 0",
                            itemId: iddl + "plyn",
                            name: "plyn",
                            frame: false,
                            border: false,
                            xtype: 'displayexd'
                        }, {
                            flex: 1,
                            frame: false,
                            border: false,
                            margin: "0 5 0 0",
                            itemId: iddl + "wartosc",
                            name: "wartosc",
                            xtype: 'numberfieldexd',
                            fieldLabel: Lang.wartosc,
                            allowBlank: true,
                            decimalPrecision: 2,
                            blankText: Lang.pole_nie_moze_byc_puste,
                            minValue: 0,
                            maxValue: 9999999
                        }]
                    }
                    this.down("#normypanel").add(this.prepareContainerWithCheckbox(normaPlyn, iddl + "_norma_panel"));
                    var plyn = this.down("#" + iddl + "plyn");
                    var wartosc = this.down("#" + iddl + "wartosc");
                    plyn.setValue(pl.nazwa);
                    var nn = null;
                    for (var k = 0; k < this.normals.length; k++) {
                        if (this.normals[k].idplyn == pl.id) {
                            nn = this.normals[k];
                            nn.elcwartosc = wartosc;
                            wartosc.setValue(nn.wartosczuzycie1001h);
                        }
                    }
                    if (nn == null) {
                        nn = {
                            wartosczuzycie1001h: null,
                            idplyn: pl.id,
                            elcwartosc: wartosc,
                            idelem: iddl
                        };
                        this.normals.push(nn);
                    }
                }
            } else {
                Ext.MessageBox.alert(Lang.error, firmadata.error);
            }
        });
    },
    saveOp: function () {
        if (!this.down('#formpanel1').form.isValid() || !this.down('#formpanel2').form.isValid() || !this.down('#formpanel3').form.isValid() || !this.down('#formpanel4').form.isValid() || !this.down('#formpanel5').form.isValid()) {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
            return;
        }
        for (var k = 0; k < this.normals.length; k++) {
            var nn = this.normals[k];
            nn.wartosczuzycie1001h = nn.elcwartosc.getValue2();
            delete nn.elcwartosc;
        }
        let formFields = {}
        if (this.isCheckboxEnabled("active")) {
            formFields.active = this.down("#active").getValue2();
        }
        if (this.isCheckboxEnabled("rokprodukcji")) {
            formFields.rokprodukcji = this.down("#rokprodukcji").getValue2();
        }
        if (this.isCheckboxEnabled("identeuro")) {
            formFields.identeuro = this.down("#identeuro").getValue2();
        }
        if (this.isCheckboxEnabled("opis")) {
            formFields.opis = this.down("#opis").getValue();
        }
        if (this.isCheckboxEnabled("grupa")) {
            formFields.grupa = this.down("#grupa").getValue2NL();
        }
        if (this.isCheckboxEnabled("plyn")) {
            formFields.plyn = this.down("#plyn").getValue2NL();
        }
        if (this.isCheckboxEnabled("ismotogodzinynotkilometry")) {
            formFields.ismotogodzinynotkilometry = this.down("#ismotogodzinynotkilometry").getValue2();
        }
        if (this.isCheckboxEnabled("askforvolumetank")) {
            formFields.askforvolumetank = this.down("#askforvolumetank").getValue2();
        }
        if (this.isCheckboxEnabled("canviewtankowanie")) {
            formFields.canviewtankowanie = this.down("#canviewtankowanie").getValue2();
        }
        if (this.isCheckboxEnabled("cansetaddpole")) {
            formFields.cansetaddpole = this.down("#cansetaddpole").getValue2();
        }
        if (this.isCheckboxEnabled("fueltanklimit")) {
            formFields.fueltanklimit = this.down("#fueltanklimit").getValue2();
        }
        if (this.isCheckboxEnabled("controlfueltanklimit")) {
            formFields.controlfueltanklimit = this.down("#controlfueltanklimit").getValue2();
        }
        if (this.isCheckboxEnabled("isdesczaladunek")) {
            formFields.isdesczaladunek = this.down("#isdesczaladunek").getValue2();
        }
        if (this.isCheckboxEnabled("limitpanel")) {
            formFields.limitydatas = this.down('#limitpanel').getLimitList()
        }
        let normalsTransfer = [];
        this.normals.forEach(norm => {
            if (this.isCheckboxEnabled(norm.idelem + "_norma_panel")) {
                normalsTransfer.push(norm);
            }
        })
        if (normalsTransfer.length) {
            formFields.normals = normalsTransfer;
        }
        if (this.isCheckboxEnabled("ustawieniaind")) {
            if (!this.down("#maxmileagediff").disabled) {
                var maxMileageDiff = 0;
                if (this.down("#individualopts").getValue2()) {
                    maxMileageDiff = this.down("#maxmileagediff").getValue();
                } else if (this.defaultMaxMileageDiff) {
                    if (this.down("#ismotogodzinynotkilometry").getValue2()) {
                        maxMileageDiff = this.defaultMaxMileageDiff.rg;
                    } else {
                        maxMileageDiff = this.defaultMaxMileageDiff.km;
                    }
                }
                formFields.maxmileagediff = maxMileageDiff;
            }
            let ident_tryb = this.down("#ident_tryb");
            if (!ident_tryb.disabled) {
                formFields.ident_tryb = ident_tryb.getValue();
            }
            let ident_pojqq = this.down("#ident_pojqq");
            if (!ident_pojqq.disabled) {
                formFields.ident_pojqq = ident_pojqq.getValue2();
            }
            let mustsetlicznikstate = this.down("#mustsetlicznikstate");
            if (!mustsetlicznikstate.disabled) {
                formFields.mustsetlicznikstate = mustsetlicznikstate.getValue2();
            }
            let mustsetlicznikstatecontrol = this.down("#mustsetlicznikstatecontrol");
            if (!mustsetlicznikstatecontrol.disabled) {
                formFields.mustsetlicznikstatecontrol = mustsetlicznikstatecontrol.getValue2();
            }
            let individualopts = this.down("#individualopts");
            if (!individualopts.disabled) {
                formFields.individualopts = individualopts.getValue2();
            }
        }
        let windowChooseObjs = Ext.create('WindowMassEditChooseObjects', {
            typ: this.typ,
            formFields: formFields
        });
        this.hide();
        windowChooseObjs.showW(id);
        windowChooseObjs.on('closeSuccess', this.closeOp, this);
    },
    buildFormPanel1: function () {
        let formpanel1 = {
            itemId: "formpanel1",
            title: Lang.ogolne.capitalise(),
            xtype: 'form',
            frame: true,
            border: false,
            autoWidth: true,
            scrollable: true,
            autoHeight: true,
            bodyStyle: 'padding:10px 10px 10px 10px',
            defaults: {
                anchor: "0",
            },
            items: [{
                anchor: "0",
                layout: 'column',
                frame: false,
                border: false,
                items: [{
                    columnWidth: 1,
                    layout: 'anchor',
                    frame: false,
                    border: false,
                    defaults: {
                        anchor: "0",
                        labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                    },
                    items: []
                }]
            }]
        };
        let rokprodukcji = {
            itemId: "rokprodukcji",
            name: "rokprodukcji",
            xtype: 'textfieldexd',
            fieldLabel: Lang.rok_produkcji,
            hidden: Const.BASICFLAG || Const.OPTIMALFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || this.typ == Const.MASS_EDIT_TYPE_ODBIORCA,
            allowBlank: true,
            blankText: Lang.pole_nie_moze_byc_puste
        }
        let identeuro = {
            itemId: "identeuro",
            name: "identeuro",
            xtype: 'textfieldexd',
            fieldLabel: Lang.ident_euro,
            allowBlank: true,
            blankText: Lang.pole_nie_moze_byc_puste,
            hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || this.typ == Const.MASS_EDIT_TYPE_ODBIORCA
        }

        let ismotogodzinynotkilometry = {
            xtype: 'radiogroupexd',
            itemId: "ismotogodzinynotkilometry",
            name: "ismotogodzinynotkilometry",
            baseCls: 'x-radio-column',
            fieldLabel: Lang.typ_pracy,
            columns: 2,
            vertical: false,
            hidden: Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || this.typ == Const.MASS_EDIT_TYPE_ODBIORCA,
            items: [{
                boxLabel: Lang.motogodziny.capitalise(),
                inputValue: true
            }, {
                boxLabel: Lang.kilometry.capitalise(),
                inputValue: false,
                value: true
            }],
            listeners: {
                change: function (radiogroup, newValue) {
                    if (this.defaultMaxMileageDiff != null) {
                        if (newValue)
                            this.down("#maxmileagediff").setValue(this.defaultMaxMileageDiff.rg);
                        else
                            this.down("#maxmileagediff").setValue(this.defaultMaxMileageDiff.km);
                    }
                },
                scope: this
            }
        };
        let active = {
            xtype: 'radiogroupexd',
            itemId: "active",
            name: "active",
            baseCls: 'x-radio-column',
            fieldLabel: Lang.active,
            columns: 2,
            vertical: false,
            items: [{
                boxLabel: Lang.tak.capitalise(),
                inputValue: true,
                value: true
            }, {
                boxLabel: Lang.nie.capitalise(),
                inputValue: false
            }]
        };
        let grupa = {
            itemId: "grupa",
            name: "grupa",
            fieldLabel: Lang.grupa,
            allowBlank: true,
            xtype: 'comboexd',
            emptyText: Lang.brak_grupy,
            store: Ext.create('Ext.data.Store', {
                fields: [{
                    name: 'id',
                    allowNull: true
                }, 'nazwa'],
                proxy: {
                    type: 'ajax',
                    url: 'rest/grupy/getList',
                    actionMethods: {
                        read: 'POST'
                    },
                    extraParams: {
                        type: this.typ
                    },
                    reader: {
                        type: 'json',
                        rootProperty: 'data'
                    }
                },
                autoLoad: false
            }),
            displayField: 'nazwa',
            valueField: 'id',
            hidden: Const.STARTERFLAG
        };
        let plyn = {
            itemId: "plyn",
            name: "plyn",
            fieldLabel: Lang.plyn,
            allowBlank: true,
            xtype: 'comboexd',
            emptyText: Lang.brak_plynu,
            store: Ext.create('Ext.data.Store', {
                fields: [{
                    name: 'id',
                    allowNull: true
                }, 'nazwa'],
                proxy: {
                    type: 'ajax',
                    url: 'rest/plyny/getList',
                    actionMethods: {
                        read: 'POST'
                    },
                    reader: {
                        type: 'json',
                        rootProperty: 'data'
                    }
                },
                autoLoad: false
            }),
            displayField: 'nazwa',
            valueField: 'id',
            hidden: !Const.ENABLEGPS || Const.STARTERFLAG || Const.BASICFLAG || Const.STANDARDFLAG
        };
        let opis = {
            anchor: "0",
            fieldLabel: Lang.opis,
            isFormField: true,
            itemId: "opis",
            name: "opis",
            xtype: 'textarea'
        };
        formpanel1.items[0].items[0].items.push(this.prepareContainerWithCheckbox(rokprodukcji, "rokprodukcji"),
            this.prepareContainerWithCheckbox(identeuro, "identeuro"),
            this.prepareContainerWithCheckbox(grupa, "grupa"),
            this.prepareContainerWithCheckbox(plyn, "plyn"),
            this.prepareContainerWithCheckbox(opis, "opis"),
            this.prepareContainerWithCheckbox(active, "active"),
            this.prepareContainerWithCheckbox(ismotogodzinynotkilometry, "ismotogodzinynotkilometry"));
        return formpanel1;
    },
    buildFormPanel2: function () {
        let formpanel2 = {
            itemId: "formpanel2",
            title: Lang.zaawansowane,
            xtype: 'form',
            frame: true,
            border: false,
            autoWidth: true,
            scrollable: true,
            autoHeight: true,
            hidden: (Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG) && this.typ == Const.MASS_EDIT_TYPE_POJAZD,
            bodyStyle: 'padding:10px 10px 10px 10px',
            defaults: {
                anchor: "0",
            },
            items: [{
                anchor: "0",
                layout: 'column',
                frame: false,
                border: false,
                items: [{
                    columnWidth: 1,
                    layout: 'anchor',
                    frame: false,
                    border: false,
                    defaults: {
                        anchor: "0",
                        labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                    },
                    items: []
                }]
            }]
        }
        let fueltanklimit = {
            itemId: "fueltanklimit",
            name: "fueltanklimit",
            xtype: 'numberfieldexd',
            fieldLabel: Lang.pojemnosc_baku_paliwa,
            decimalPrecision: 2,
            minValue: 0,
            hidden: Const.BASICFLAG || Const.OPTIMALFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || this.typ == Const.MASS_EDIT_TYPE_ODBIORCA
        }
        let controlfueltanklimit = {
            xtype: 'radiogroupexd',
            itemId: "controlfueltanklimit",
            name: "controlfueltanklimit",
            baseCls: 'x-radio-column',
            fieldLabel: Lang.limit_pojemnosci_baku,
            columns: 2,
            vertical: false,
            hidden: Const.BASICFLAG || Const.OPTIMALFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || this.typ == Const.MASS_EDIT_TYPE_ODBIORCA,
            items: [{
                boxLabel: Lang.tak.capitalise(),
                inputValue: true
            }, {
                boxLabel: Lang.nie.capitalise(),
                inputValue: false,
                value: true
            }]
        }
        let canviewtankowanie = {
            xtype: 'radiogroupexd',
            itemId: "canviewtankowanie",
            name: "canviewtankowanie",
            baseCls: 'x-radio-column',
            fieldLabel: Lang.podglad_tankowania,
            columns: 2,
            vertical: false,
            items: [{
                boxLabel: Lang.tak.capitalise(),
                inputValue: true,
                value: true
            }, {
                boxLabel: Lang.nie.capitalise(),
                inputValue: false
            }],
            hidden: (Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG) && this.typ == Const.MASS_EDIT_TYPE_POJAZD
        }
        let cansetaddpole = {
            xtype: 'radiogroupexd',
            itemId: "cansetaddpole",
            name: "cansetaddpole",
            fieldLabel: Lang.pole_dodatkowe_przy_tankowaniu,
            columns: 2,
            vertical: false,
            hidden: Const.BASICFLAG || Const.OPTIMALFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
            items: [{
                boxLabel: Lang.tak.capitalise(),
                inputValue: true
            }, {
                boxLabel: Lang.nie.capitalise(),
                inputValue: false,
                value: true
            }]
        }
        let askforvolumetank = {
            xtype: 'radiogroupexd',
            itemId: "askforvolumetank",
            name: "askforvolumetank",
            baseCls: 'x-radio-column',
            fieldLabel: Lang.pytanie_o_ilosc_dozatankowania,
            columns: 2,
            vertical: false,
            hidden: Const.BASICFLAG || Const.OPTIMALFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
            items: [{
                boxLabel: Lang.tak,
                inputValue: true
            }, {
                boxLabel: Lang.nie,
                inputValue: false,
                value: true
            }]
        }
        let isdesczaladunek = {
            xtype: 'radiogroupexd',
            itemId: "isdesczaladunek",
            name: "isdesczaladunek",
            baseCls: 'x-radio-column',
            fieldLabel: Lang.zaladunkowy,
            columns: 2,
            vertical: false,
            hidden: Const.BASICFLAG || Const.OPTIMALFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || this.typ == Const.MASS_EDIT_TYPE_POJAZD,
            items: [{
                boxLabel: Lang.tak.capitalise(),
                inputValue: true
            }, {
                boxLabel: Lang.nie.capitalise(),
                inputValue: false,
                value: true
            }]
        }
        formpanel2.items[0].items[0].items.push(this.prepareContainerWithCheckbox(fueltanklimit, "fueltanklimit"),
            this.prepareContainerWithCheckbox(controlfueltanklimit, "controlfueltanklimit"),
            this.prepareContainerWithCheckbox(canviewtankowanie, "canviewtankowanie"),
            this.prepareContainerWithCheckbox(cansetaddpole, "cansetaddpole"),
            this.prepareContainerWithCheckbox(askforvolumetank, "askforvolumetank"),
            this.prepareContainerWithCheckbox(isdesczaladunek, "isdesczaladunek"))
        return formpanel2;
    },
    buildFormPanel3: function () {
        let formpanel3 = {
            itemId: "formpanel3",
            xtype: 'form',
            title: Lang.limity.capitalise(),
            frame: true,
            border: false,
            autoWidth: true,
            scrollable: true,
            autoHeight: true,
            hidden: Const.SKPFLAG || Const.BASICFLAG || Const.STARTERFLAG,
            defaults: {
                anchor: "0",
            },
            items: [{
                itemId: 'limitnotice',
                xtype: 'displayfield',
                value: Lang.limity_wylacznie_online,
                fieldStyle: 'color: red; text-align: center;',
                hidden: !Const.STANDARDFLAG,
                disabled: true,
                height: 40
            }]
        }
        let limitpanel = {
            hidden: (Const.SKPFLAG || (Const.BASICFLAG && Const.CUSTOMMODFLAG != 'swimer' && Const.CUSTOMMODFLAG != 'external_sibuso') || Const.STARTERFLAG) && this.typ == Const.MASS_EDIT_TYPE_POJAZD,
            disabled: true,
            xtype: 'limitpanelcp',
            frame: false,
            border: false,
            itemId: "limitpanel",
            height: WindowExdMobileDEVICEV ? '100%' : 397 - (Const.STANDARDFLAG ? 50 : 0),
            parentObj: this,
            header: false
        }
        formpanel3.items.push(this.prepareContainerWithCheckbox(limitpanel, "limitpanel", Lang.nadaj_nowe_limity, Lang.warning_masowa_edycja_limitow));
        return formpanel3;
    },
    buildFormPanel4: function () {
        let formpanel4 = {
            itemId: "formpanel4",
            title: Lang.normy_zuzycia.capitalise(),
            xtype: 'form',
            frame: true,
            border: false,
            autoWidth: true,
            scrollable: true,
            autoHeight: true,
            bodyStyle: 'padding:10px 10px 10px 10px',
            defaults: {
                anchor: "0",
            },
            hidden: (Const.BASICFLAG && Const.CUSTOMMODFLAG != 'swimer' && Const.CUSTOMMODFLAG != 'external_sibuso') || Const.STARTERFLAG || this.typ == Const.MASS_EDIT_TYPE_ODBIORCA,
            items: [{
                anchor: "0",
                xtype: 'panel',
                itemId: "normypanel",
                layout: 'form',
                disabled: false
            }]
        }
        return formpanel4;
    },
    buildFormPanel5: function () {
        let formpanel5 = {
            itemId: "formpanel5",
            title: Lang.ustawienia_indywidualne,
            xtype: 'form',
            frame: true,
            border: false,
            autoWidth: true,
            scrollable: true,
            autoHeight: true,
            hidden: Const.OPTIMALFLAG || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG,
            bodyStyle: 'padding:10px 10px 10px 10px',
            defaults: {
                anchor: "0",
            },
            items: [{
                anchor: "0",
                layout: 'column',
                frame: false,
                border: false,
                items: [{
                    columnWidth: 1,
                    layout: 'anchor',
                    frame: false,
                    border: false,
                    defaults: {
                        anchor: "0",
                        labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                    },
                    items: []
                }]
            }]
        }
        let individualopts = {
            xtype: 'radiogroupexd',
            itemId: "individualopts",
            name: "individualopts",
            width: 500,
            labelWidth: 250,
            fieldLabel: Lang.ustawienia_indywidualne,
            columns: 2,
            vertical: false,
            items: [{
                boxLabel: Lang.tak,
                inputValue: true,
            }, {
                boxLabel: Lang.nie,
                inputValue: false,
                value: true
            }],
            listeners: {
                change: function (radiogroup, newValue) {
                    this.down("#ident_tryb").setDisabled(!newValue);
                    this.down("#ident_pojqq").setDisabled(!newValue);
                    if (this.typ == Const.MASS_EDIT_TYPE_POJAZD) {
                        var mustsetlicznikstate = this.down("#mustsetlicznikstate");
                        var mustsetlicznikstatecontrol = this.down("#mustsetlicznikstatecontrol");
                        mustsetlicznikstate.setDisabled(!newValue);
                        if (!newValue) {
                            mustsetlicznikstate.setValue(false);
                            mustsetlicznikstate.fireEvent("change", mustsetlicznikstate, mustsetlicznikstate.getValue2());
                            mustsetlicznikstatecontrol.setValue(false);
                            mustsetlicznikstatecontrol.fireEvent("change", mustsetlicznikstatecontrol, mustsetlicznikstatecontrol.getValue2());
                            mustsetlicznikstatecontrol.setDisabled(!newValue);
                        }
                        this.down("#controlmileagediff").setDisabled(!newValue || !mustsetlicznikstatecontrol.getValue2());
                        this.down("#maxmileagediff").setDisabled(!this.down("#controlmileagediff").getValue2() || !newValue);
                    }
                },
                scope: this
            },
            hidden: Const.SKPFLAG
        }
        let ident_tryb = {
            itemId: 'ident_tryb',
            xtype: 'comboexd',
            width: 500,
            labelWidth: 250,
            store: [[Const.IDENT_PODWOJNA, Lang.podwojna], [Const.IDENT_POJEDYNCZA, Lang.pojedyncza]],
            triggerAction: 'all',
            resizable: false,
            mode: "local",
            editable: false,
            fieldLabel: Lang.tryb_identyfikacji,
            hidden: Const.SKPFLAG
        }
        let ident_pojqq = {
            xtype: 'radiogroupexd',
            itemId: "ident_pojqq",
            name: "ident_pojqq",
            width: 500,
            labelWidth: 250,
            fieldLabel: Lang.pytanie_o_nazwe,
            columns: 2,
            vertical: false,
            items: [{
                boxLabel: Lang.tak,
                inputValue: true
            }, {
                boxLabel: Lang.nie,
                inputValue: false,
                value: true
            }],
            hidden: Const.SKPFLAG
        }
        let mustsetlicznikstate = {
            xtype: 'radiogroupexd',
            itemId: "mustsetlicznikstate",
            name: "mustsetlicznikstate",
            width: 500,
            labelWidth: 250,
            fieldLabel: Lang.wymagalnosc_stanu_licznika,
            columns: 2,
            columnWidth: '30%',
            hidden: this.typ == Const.MASS_EDIT_TYPE_ODBIORCA || Const.SKPFLAG,
            vertical: false,
            items: [{
                boxLabel: Lang.tak,
                inputValue: true
            }, {
                boxLabel: Lang.nie,
                inputValue: false,
                value: true
            }],
            listeners: {
                scope: this,
                change: function (radiogroup, newValue) {
                    var mustsetlicznikstatecontrol = this.down("#mustsetlicznikstatecontrol");
                    if (!newValue) {
                        mustsetlicznikstatecontrol.setValue(false);
                        mustsetlicznikstatecontrol.fireEvent("change", mustsetlicznikstatecontrol, mustsetlicznikstatecontrol.getValue2());
                    }
                    mustsetlicznikstatecontrol.setDisabled(!newValue);
                }
            }
        }
        let mustsetlicznikstatecontrol = {
            xtype: 'radiogroupexd',
            itemId: "mustsetlicznikstatecontrol",
            name: "mustsetlicznikstatecontrol",
            width: 500,
            labelWidth: 250,
            fieldLabel: Lang.kontrola_stanu_licznika,
            columns: 2,
            vertical: false,
            hidden: this.typ == Const.MASS_EDIT_TYPE_ODBIORCA || Const.SKPFLAG,
            items: [{
                boxLabel: Lang.tak,
                inputValue: true
            }, {
                boxLabel: Lang.nie,
                inputValue: false,
                value: true
            }],
            listeners: {
                scope: this,
                change: function (radiogroup, newValue) {
                    var controlmileagediff = this.down("#controlmileagediff");
                    if (!newValue) {
                        controlmileagediff.setValue(false);
                        controlmileagediff.fireEvent("change", controlmileagediff, controlmileagediff.getValue2());
                    }
                    controlmileagediff.setDisabled(!newValue);
                }
            }
        }
        let controlmileagediff = {
            xtype: 'radiogroupexd',
            itemId: "controlmileagediff",
            name: "controlmileagediff",
            width: 500,
            labelWidth: 250,
            fieldLabel: Lang.kontrola_roznicy_przebiegu,
            columns: 2,
            vertical: false,
            hidden: Const.BASICFLAG || Const.OPTIMALFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || this.typ == Const.MASS_EDIT_TYPE_ODBIORCA,
            items: [{
                boxLabel: Lang.tak.capitalise(),
                inputValue: true
            }, {
                boxLabel: Lang.nie.capitalise(),
                inputValue: false,
                value: true
            }],
            listeners: {
                change: function (radiogroup, newValue) {
                    var maxmileagediff = this.down("#maxmileagediff");
                    if (!newValue) {
                        maxmileagediff.setValue(null);
                    }
                    maxmileagediff.setDisabled(!newValue);
                },
                scope: this
            }
        }
        let maxmileagediff = {
            itemId: "maxmileagediff",
            name: "maxmileagediff",
            xtype: 'numberfieldexd',
            width: 500,
            labelWidth: 250,
            fieldLabel: Lang.maksymalna_roznica_przebiegu,
            allowBlank: this.typ == Const.MASS_EDIT_TYPE_ODBIORCA,
            decimalPrecision: 2,
            minValue: 0,
            cls: "x-item-disabled-sec",
            hidden: Const.BASICFLAG || Const.OPTIMALFLAG || Const.STANDARDFLAG || Const.STARTERFLAG || this.typ == Const.MASS_EDIT_TYPE_ODBIORCA
        }
        let ustawieniaind = {
            itemId: "ustawieniaind",
            xtype: "container",
            width: '100%',
            items: []
        }
        ustawieniaind.items.push(individualopts, ident_tryb, ident_pojqq, mustsetlicznikstate, mustsetlicznikstatecontrol, controlmileagediff, maxmileagediff);
        formpanel5.items[0].items[0].items.push(this.prepareContainerWithCheckbox(ustawieniaind, "ustawieniaind", Lang.zmiana_ustawien_indywidualnych));
        return formpanel5;
    },
    prepareContainerWithCheckbox: function (elem, id, chblabel, warning) {
        elem.width = '90%';
        elem.labelWidth = 250;
        elem.disabled = true;
        elem.disabledCls = 'customDis'
        let container = {
            xtype: 'container',
            width: '100%',
            style: "margin-top: 20px; margin-down: 20px; margin-left: 5%;",
            layout: {
                type: chblabel ? 'vbox' : 'hbox',
                align: 'left',
                pack: 'left'
            },
            items: [{
                xtype: "checkboxexd",
                itemId: ("chb_" + id),
                boxLabel: chblabel,
                value: false,
                hideLabel: true,
                margin: '0px 10px 0px 0px',
                hidden: elem.hidden,
                listeners: {
                    scope: this,
                    change: function (component, newValue) {
                        let el = this.down('#' + id);
                        el.setDisabled(!newValue);
                        if (warning) {
                            this.down('#wrng_' + id).setHidden(!newValue);
                        }
                    }
                }
            }]
        };
        if (warning) {
            let wrng = {
                itemId: ('wrng_' + id),
                width: '80%',
                xtype: 'displayfield',
                value: warning,
                fieldStyle: 'color: red; text-align: left;',
                hidden: true
            }
            container.items.push(wrng);
        }
        container.items.push(elem);
        return container;
    },
    isCheckboxEnabled: function (id) {
        let chd = this.down('#chb_' + id);
        if (chd.hidden) {
            return false;
        }
        return chd.getValue();
    },
    closeOp: function () {
        this.fireEvent('closeSuccess');
        this.close();
    }
});
