Ext.define('WindowSkpulite', {
    extend: 'Ext.ux.window.WindowExd',
    accessPropertyManageName: "access.manage.skps",
    accessPropertyViewName: "access.view.skps",
    title: Lang.skpulite.capitalise(),
    width: 630,
    height: 700,
    closable: false,
    collapsible: false,
    modal: true,
    resizable: false,
    scrollable: true,
    layout: 'fit',
    initComponent: function () {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'tabpanel',
            autoWidth: true,
            height: 300,
            border: false,
            activeTab: 0,
            items: [{
                title: Lang.ogolne.capitalise(),
                xtype: 'form',
                id: this.cid + 'formpanel1',
                bodyStyle: 'padding:10px 10px 10px 10px',
                frame: true,
                autoWidth: true,
                scrollable: 'y',
                defaults: {
                    anchor: "0"
                },
                items: [{
                    anchor: "0",
                    layout: 'column',
                    border: false,
                    items: [{
                        columnWidth: 1,
                        border: false,
                        layout: 'anchor',
                        labelWidth: 210,
                        defaults: {
                            anchor: "-10",
                            labelWidth: 210,
                            labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                        },
                        items: [{
                            id: this.cid + "nazwa",
                            name: "nazwa",
                            xtype: 'textfieldexd',
                            fieldLabel: Lang.nazwa,
                            allowBlank: false,
                            maxLength: 16,
                            blankText: Lang.pole_nie_moze_byc_puste
                        }, {
                            id: this.cid + "serialnumber",
                            name: "serialnumber",
                            xtype: 'textfieldexd',
                            fieldLabel: Lang.numer_seryjny,
                            allowBlank: false,
                            maxLength: 16,
                            blankText: Lang.pole_nie_moze_byc_puste,
                            disabled: isValidOperatorFirmaLimit()
                        }, {
                            id: this.cid + "idzbiornik",
                            xtype: 'comboexd',
                            fieldLabel: Lang.zbiornik,
                            valueField: 'id',
                            displayField: 'nazwa',
                            store: new Ext.data.JsonStore({
                                id: "id",
                                rootProperty: '',
                                sorters: [{
                                    property: "nazwa",
                                    direction: "ASC"
                                }],
                                fields: [{
                                    name: 'id',
                                    mapping: 'id'
                                }, {
                                    name: 'nazwa',
                                    mapping: 'nazwa'
                                }]
                            }),
                            triggerAction: 'all',
                            resizable: false,
                            editable: false,
                            mode: "local",
                            allowBlank: false,
                            blankText: Lang.pole_nie_moze_byc_puste,
                            disabled: isValidOperatorFirmaLimit()
                        }, {
                            xtype: 'radiogroupexd',
                            id: this.cid + "active",
                            name: "active",
                            baseCls: 'x-radio-column',
                            fieldLabel: Lang.active,
                            vertical: false,
                            items: [{
                                boxLabel: Lang.tak.capitalise(),
                                inputValue: true,
                                value: true
                            }, {
                                boxLabel: Lang.nie.capitalise(),
                                inputValue: false
                            }]
                        }, {
                            xtype: 'radiogroupexd',
                            id: this.cid + "intervalgetstates",
                            name: "intervalgetstates",
                            baseCls: 'x-radio-column',
                            fieldLabel: Lang.zbieranie_pomiarow,
                            vertical: false,
                            items: [{
                                boxLabel: Lang.wlaczone.capitalise(),
                                inputValue: true,
                                value: true
                            }, {
                                boxLabel: Lang.wylaczone.capitalise(),
                                inputValue: false
                            }]
                        }, {
                            id: this.cid + "tmsintervalquery",
                            name: "tmsintervalquery",
                            xtype: 'numberfieldexd',
                            fieldLabel: Lang.okres_polaczenia + "[min]",
                            allowBlank: false,
                            allowDecimal: false,
                            blankText: Lang.pole_nie_moze_byc_puste,
                            minValue: 1,
                            maxValue: 99999
                        }, {
                            id: this.cid + "intervalgetskpstates",
                            name: "intervalgetskpstates",
                            xtype: 'numberfieldexd',
                            fieldLabel: Lang.okres_zbierania_pomiarow_skp + "[min]",
                            allowBlank: false,
                            allowDecimal: false,
                            blankText: Lang.pole_nie_moze_byc_puste,
                            minValue: 1,
                            maxValue: 99999
                        }, {
                            xtype: 'container',
                            layout: {
                                type: 'hbox',
                                align: WindowExdMobileDEVICEV ? 'bottom' : 'left',
                                pack: WindowExdMobileDEVICEV ? 'bottom' : 'left'
                            },
                            padding: '0 0 10 0',
                            defaults: {
                                labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left',
                                anchor: "-10",
                                labelWidth: 210
                            },
                            items: [{
                                id: this.cid + "version",
                                name: "version",
                                xtype: 'textfieldexd',
                                flex: 4,
                                fieldLabel: Lang.wersja,
                                disabled: false,
                                editable: false
                            }, {
                                id: this.cid + 'changeFlashVersion',
                                xtype: 'button',
                                margin: '0 0 0 20',
                                text: Lang.zmien,
                                flex: 1,
                                listeners: {
                                    scope: this,
                                    click: () => this.openFlashWindow()
                                },
                                disabled: !validPrivilegesOperatorComplex(this.accessPropertyManageName)
                            }, {
                                id: this.cid + 'cancelFlashChanging',
                                xtype: 'button',
                                margin: '0 0 0 20',
                                text: Lang.anuluj,
                                flex: 1,
                                listeners: {
                                    scope: this,
                                    click: () => this.cancelFlashChanging()
                                },
                                hidden: true
                            }]
                        }, {
                            fieldLabel: Lang.opis,
                            isFormField: true,
                            id: this.cid + "opis",
                            name: "opis",
                            xtype: 'textarea'
                        }]
                    }]
                }]
            }, {
                title: Lang.kalibracja.capitalise(),
                xtype: 'form',
                id: this.cid + 'formpanel2',
                bodyStyle: 'padding:10px 10px 10px 10px',
                frame: true,
                scrollable: true,
                autoWidth: true,
                defaults: {
                    anchor: "0",
                    labelWidth: 210,
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                items: [{
                    id: this.cid + "skpulitecfgintervaldownprzeciek",
                    name: "skpulitecfgintervaldownprzeciek",
                    xtype: 'numberfieldexd',
                    fieldLabel: Lang.czas_zwarcia_wejscia_przecieku,
                    allowBlank: false,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    minValue: 1,
                    maxValue: 9999999
                }, {
                    id: this.cid + 'typzbiornika',
                    xtype: 'comboexd',
                    store: new Ext.data.ArrayStore({
                        data: [[Const.SKP_GEOMETRY_WALEC_STOJACY, Lang.walec_stojacy], [Const.SKP_GEOMETRY_PROSTOPADLOSCIAN, Lang.prostopadloscian]],
                        fields: ["value", "text"]
                    }),
                    displayField: "text",
                    valueField: "value",
                    triggerAction: 'all',
                    resizable: false,
                    mode: "local",
                    editable: false,
                    fieldLabel: Lang.typ_zbiornika
                }, {
                    id: this.cid + "zakresprzetwornik",
                    name: "zakresprzetwornik",
                    xtype: 'numberfieldexd',
                    fieldLabel: Lang.zakres_przetwornik + "[m]",
                    allowBlank: false,
                    decimalPrecision: 3,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    minValue: 0.001,
                    maxValue: 9999999
                }, {
                    id: this.cid + "wysokosczbiornik",
                    name: "wysokosczbiornik",
                    xtype: 'numberfieldexd',
                    fieldLabel: Lang.wysokosc_zbiornik + "[m]",
                    allowBlank: false,
                    decimalPrecision: 3,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    minValue: 0.001,
                    maxValue: 9999999
                }, {
                    id: this.cid + "polepodstawyzbiornik",
                    name: "polepodstawyzbiornik",
                    xtype: 'numberfieldexd',
                    fieldLabel: Lang.pole_podstawy_zbiornik + "[m^2]",
                    allowBlank: false,
                    decimalPrecision: 3,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    minValue: 0.001,
                    maxValue: 9999999
                }, {
                    id: this.cid + "przesuniecieczujnik",
                    name: "przesuniecieczujnik",
                    xtype: 'numberfieldexd',
                    fieldLabel: Lang.przesuniecie_czujnik + "[m]",
                    allowBlank: false,
                    decimalPrecision: 3,
                    blankText: Lang.pole_nie_moze_byc_puste,
                    minValue: -9999999,
                    maxValue: 9999999
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'save',
            text: Lang.zapisz,
            scope: this,
            handler: this.saveOp,
            disabled: Const.AGGREGATEDCORE
        }, {
            id: this.cid + 'cancel',
            text: Lang.anuluj,
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid, serialnumber) {
        if (!validPrivilegesOperatorComplex(this.accessPropertyManageName)) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.serialnumber = serialnumber || false;
        this.obiektid = obiektid;
        this.obiekt = null;
        sendAjaxDataJsonRpcL("rest/skpulite/getObjectForEdit", {
            id: this.obiektid
        }, this, function (data) {
            if (data.status) {
                this.obiekt = data.data;
                sendAjaxDataJsonRpcL("rest/zbiorniki/getShortList", {
                    idinclude: this.obiekt != null ? this.obiekt.idplyn : 0
                }, this, function (data) {
                    if (data.status) {
                        var listFnData = data.data;
                        this.show();
                        this.center();
                        this.setKeyMap({
                            'ESC': {
                                scope: this,
                                handler: this.closeOp
                            }
                        });
                        Ext.getCmp(this.cid + "idzbiornik").store.loadData(listFnData);
                        if (this.obiekt != null) {
                            Ext.getCmp(this.cid + "nazwa").setValue(this.obiekt.nazwa);
                            Ext.getCmp(this.cid + "active").setValue2(this.obiekt.active);
                            Ext.getCmp(this.cid + "intervalgetstates").setValue2(this.obiekt.intervalgetstates);
                            Ext.getCmp(this.cid + "idzbiornik").setValue2(this.obiekt.idzbiornik);
                            Ext.getCmp(this.cid + "serialnumber").setValue(this.obiekt.serialnumber);
                            Ext.getCmp(this.cid + "serialnumber").setDisabled(true);
                            Ext.getCmp(this.cid + "typzbiornika").setValue2(this.obiekt.typzbiornika);
                            Ext.getCmp(this.cid + "wysokosczbiornik").setValue(this.obiekt.wysokosczbiornik);
                            Ext.getCmp(this.cid + "wysokosczbiornik").clearInvalid();
                            Ext.getCmp(this.cid + "zakresprzetwornik").setValue(this.obiekt.zakresprzetwornik);
                            Ext.getCmp(this.cid + "zakresprzetwornik").clearInvalid();
                            Ext.getCmp(this.cid + "polepodstawyzbiornik").setValue(this.obiekt.polepodstawyzbiornik);
                            Ext.getCmp(this.cid + "polepodstawyzbiornik").clearInvalid();
                            Ext.getCmp(this.cid + "przesuniecieczujnik").setValue(this.obiekt.przesuniecieczujnik);
                            Ext.getCmp(this.cid + "przesuniecieczujnik").clearInvalid();
                            Ext.getCmp(this.cid + "opis").setValue(this.obiekt.opis);
                            Ext.getCmp(this.cid + "opis").clearInvalid();
                            Ext.getCmp(this.cid + "skpulitecfgintervaldownprzeciek").setValue(this.obiekt.skpulitecfgintervaldownprzeciek);
                            Ext.getCmp(this.cid + "skpulitecfgintervaldownprzeciek").clearInvalid();
                            Ext.getCmp(this.cid + "tmsintervalquery").setValue(this.obiekt.tmsintervalquery);
                            Ext.getCmp(this.cid + "tmsintervalquery").clearInvalid();
                            Ext.getCmp(this.cid + "intervalgetskpstates").setValue(this.obiekt.intervalgetskpstates);
                            Ext.getCmp(this.cid + "intervalgetskpstates").clearInvalid();
                            Ext.getCmp(this.cid + "version").setValue(this.obiekt.version);
                            if (this.obiekt.version == null) {
                                Ext.getCmp(this.cid + "changeFlashVersion").setDisabled(true);
                            } else {
                                if (this.obiekt.skpuliteflashchanging) {
                                    this.disableEditBtnAndChangeVersionStatus();
                                }
                            }
                        } else {
                            Ext.getCmp(this.cid + "idzbiornik").store.loadData(listFnData);
                            Ext.getCmp(this.cid + "active").setValue2(true);
                            Ext.getCmp(this.cid + "active").clearInvalid();
                            Ext.getCmp(this.cid + "nazwa").setValue(null);
                            Ext.getCmp(this.cid + "nazwa").clearInvalid();
                            Ext.getCmp(this.cid + "idzbiornik").setValue2(null);
                            Ext.getCmp(this.cid + "idzbiornik").clearInvalid();
                            Ext.getCmp(this.cid + "serialnumber").setValue(serialnumber);
                            Ext.getCmp(this.cid + "serialnumber").setDisabled(true);
                            Ext.getCmp(this.cid + "intervalgetstates").setValue2(true);
                            Ext.getCmp(this.cid + "intervalgetstates").clearInvalid();
                            Ext.getCmp(this.cid + "typzbiornika").setValue2(Const.SKP_GEOMETRY_WALEC_STOJACY);
                            Ext.getCmp(this.cid + "wysokosczbiornik").setValue(1);
                            Ext.getCmp(this.cid + "wysokosczbiornik").clearInvalid();
                            Ext.getCmp(this.cid + "zakresprzetwornik").setValue(10);
                            Ext.getCmp(this.cid + "zakresprzetwornik").clearInvalid();
                            Ext.getCmp(this.cid + "polepodstawyzbiornik").setValue(1);
                            Ext.getCmp(this.cid + "polepodstawyzbiornik").clearInvalid();
                            Ext.getCmp(this.cid + "przesuniecieczujnik").setValue(0.01);
                            Ext.getCmp(this.cid + "przesuniecieczujnik").clearInvalid();
                            Ext.getCmp(this.cid + "opis").setValue(null);
                            Ext.getCmp(this.cid + "opis").clearInvalid();
                            Ext.getCmp(this.cid + "skpulitecfgintervaldownprzeciek").setValue(1);
                            Ext.getCmp(this.cid + "tmsintervalquery").setValue(1);
                            Ext.getCmp(this.cid + "intervalgetskpstates").setValue(1);
                            Ext.getCmp(this.cid + "changeFlashVersion").setDisabled(true);
                        }
                    } else {
                        Ext.MessageBox.alert(Lang.error, data.error);
                    }
                });
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    openFlashWindow: function () {
        var editExtraConfigurationWindow = Ext.create('WindowSkpuliteFlashChoose', {
            callbackF: Ext.Function.bind(this.disableEditBtnAndChangeVersionStatus, this)
        });
        editExtraConfigurationWindow.showW(this.obiektid, this.obiekt.version, this.obiekt.devid);
    },
    cancelFlashChanging: function () {
        Ext.MessageBox.show({
            title: Lang.operacja,
            message: Lang.czy_napewno_anulowac_operacje,
            buttons: Ext.MessageBox.YESNO,
            fn: function (btn) {
                if (btn == "yes") {
                    sendAjaxDataJsonRpcL("rest/skpulite/cancelFlashVerison", {
                        id: this.obiektid
                    }, this, function (data) {
                        if (data.status) {
                            Ext.getCmp(this.cid + "version").setValue(this.obiekt.version);
                            Ext.getCmp(this.cid + 'changeFlashVersion').setHidden(false);
                            Ext.getCmp(this.cid + 'cancelFlashChanging').setHidden(true);
                            Ext.MessageBox.alert(Lang.operacja, Lang.operacja_zakonczona_poprawnie);
                        } else {
                            Ext.MessageBox.alert(Lang.error, data.error);
                        }
                    });
                }
            },
            scope: this
        });
    },
    disableEditBtnAndChangeVersionStatus: function () {
        Ext.getCmp(this.cid + "version").setValue(Lang.w_trakcie_zmiany);
        Ext.getCmp(this.cid + 'changeFlashVersion').setHidden(true);
        Ext.getCmp(this.cid + 'cancelFlashChanging').setHidden(false);
    },
    saveOp: function () {
        var canWrite = false;
        var fp1 = Ext.getCmp(this.cid + "formpanel1");
        var fp2 = Ext.getCmp(this.cid + "formpanel2");
        var formValid = true;
        formValid = formValid && fp1.isValid();
        formValid = formValid && fp2.isValid();
        if (formValid) {
            canWrite = true;
        }
        if (canWrite) {
            sendAjaxDataJsonRpcL("rest/skpulite/saveObject", {
                id: this.obiektid,
                nazwa: Ext.getCmp(this.cid + "nazwa").getValue(),
                active: Ext.getCmp(this.cid + "active").getValue2(),
                intervalgetstates: Ext.getCmp(this.cid + "intervalgetstates").getValue2(),
                idzbiornik: Ext.getCmp(this.cid + "idzbiornik").getValue(),
                serialnumber: Ext.getCmp(this.cid + "serialnumber").getValue(),
                typzbiornika: Ext.getCmp(this.cid + "typzbiornika").getValue2(),
                zakresprzetwornik: Ext.getCmp(this.cid + "zakresprzetwornik").getValue(),
                wysokosczbiornik: Ext.getCmp(this.cid + "wysokosczbiornik").getValue(),
                polepodstawyzbiornik: Ext.getCmp(this.cid + "polepodstawyzbiornik").getValue(),
                przesuniecieczujnik: Ext.getCmp(this.cid + "przesuniecieczujnik").getValue(),
                opis: Ext.getCmp(this.cid + "opis").getValue(),
                skpulitecfgintervaldownprzeciek: Ext.getCmp(this.cid + "skpulitecfgintervaldownprzeciek").getValue(),
                tmsintervalquery: Ext.getCmp(this.cid + "tmsintervalquery").getValue(),
                intervalgetskpstates: Ext.getCmp(this.cid + "intervalgetskpstates").getValue(),
                optlockversion: this.obiekt == null ? null : this.obiekt.optlockversion
            }, this, function (data) {
                if (data.status) {
                    data = data.data;
                    this.obiektid = data.id;
                    this.fireEvent("closesuccess", this);
                    this.close();
                } else {
                    Ext.MessageBox.alert(Lang.error, data.error);
                }
                if (this.callbackF)
                    this.callbackF();
            });
        } else {
            Ext.MessageBox.alert(Lang.error, Lang.dane_niepoprawne);
        }
    },
    closeOp: function () {
        this.close();
    }
});