Ext.define('ViewPojazdyMobile', {
    extend: 'Ext.panel.Panel',
    grid: null,
    gridpage: null,
    windowObject: null,
    id: "pojazdy",
    taskContextMenu: null,
    rowContext: null,
    rebuildGrid: false,
    showState: false,
    statics: {
        accessPropertyName: "access.manage.pojazdy",
        accessPropertyViewName: "access.view.pojazdy"
    },
    cat: "k",
    text: Lang.pojazdy,
    buttonIconCls: "iconPojazd",
    layout: 'border',
    frame: false,
    border: false,
    initComponent: function () {
        this.buttonId = "main-view-button-" + this.id;
        this.buildGrid();
        this.items = [{
            itemId: 'kontekstfirmy-container',
            xtype: 'container',
            region: 'north',
            hidden: !(AppFIRMACTX != null),
            style: 'background-color: white',
            items: [{
                id: this.id + "kontekstfirmy",
                xtype: 'label',
                cls: "kontekstfirmy-text kontekstfirmy-text-high kontekstfirmy-text-mobile"
            }]
        }, {
            region: "north",
            xtype: 'toolbar',
            scrollable: true,
            items: [{
                id: this.id + "bnew",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconNew",
                text: Lang.nowy.capitalise(),
                scope: this,
                handler: this.dodajObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewPojazdy.accessPropertyName + "/update"),
                cls: 'x-btn-mobile'
            }, {
                id: this.id + "bedit",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconEdit",
                text: Lang.edytuj.capitalise(),
                scope: this,
                handler: this.edytujObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewPojazdy.accessPropertyName + "/update"),
                cls: 'x-btn-mobile'
            }, {
                id: this.id + "bdelete",
                xtype: 'button',
                iconAlign: 'left',
                iconCls: "iconDelete",
                text: Lang.usun.capitalise(),
                scope: this,
                handler: this.usunObiekt,
                disabled: !validPrivilegesOperatorComplex(ViewPojazdy.accessPropertyName + "/delete"),
                cls: 'x-btn-mobile'
            }, {
                xtype: 'button',
                id: this.id + "grupybtn",
                iconAlign: 'left',
                iconCls: 'iconGroup',
                text: Lang.grupy,
                scope: this,
                handler: this.showGrupy,
                hidden: !validPrivilegesOperatorComplex(WindowGroups.accessPropertyName) || Const.STARTERFLAG,
                cls: 'x-btn-mobile'
            }]
        }, {
            region: "center",
            xtype: 'panel',
            frame: false,
            border: false,
            layout: 'fit',
            items: [this.grid]
        }];
        this.callParent(arguments);
    },
    showFirst: function (eventMain) {
        eventMain.on("firmaChangeContext", this.changeContext, this);
    },
    remove: function () {
        this.callParent(arguments);
    },
    show: function () {
        this.showState = true;
        this.callParent(arguments);
        this.changeContextRebuild(null, null);
        if (this.grid && this.grid.rendered)
            this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        this.refreshDatastore();
    },
    hide: function () {
        this.showState = false;
        this.callParent(arguments);
    },
    changeContextRebuild: function (eAppFIRMACTX, eAppFIRMACTXold) {
        var kontekstfirmy = Ext.getCmp(this.id + "kontekstfirmy");
        if (AppFIRMACTX != null) {
            kontekstfirmy.setText(AppFIRMACTX.nazwa);
        } else {
            kontekstfirmy.setText("");
        }
        this.down('#kontekstfirmy-container').setHidden(!(AppFIRMACTX != null));
        Ext.getCmp(this.id + "grupybtn").setDisabled(Const.ENTERPRISEFLAG && AppFIRMACTX == null)
        if (this.rebuildGrid) {
            this.rebuildGrid = false;
            Ext.suspendLayouts();
            var onwer = this.grid.ownerCt;
            onwer.remove(this.grid);
            this.buildGrid();
            onwer.add(this.grid);
            Ext.resumeLayouts(true);
            if (this.grid && this.grid.rendered)
                this.grid.getDockedComponent(1).updatePageSize(getNumberOfVisibleRows(this.grid));
        }
    },
    changeContext: function (eAppFIRMACTX, eAppFIRMACTXold) {
        this.rebuildGrid = true;
        if (this.showState) {
            this.changeContextRebuild(null, null);
            this.refreshDatastore();
        }
        this.manageSelect();
    },
    buildGrid: function () {
        var store = Ext.create('Ext.data.ArrayStore', {
            autoLoad: false,
            remoteSort: true,
            remoteFilter: true,
            pageSize: 50,
            model: Ext.define(null, {
                extend: 'Ext.data.Model',
                idProperty: 'id',
                fields: [{
                    name: 'id',
                    mapping: 'id'
                }, {
                    name: 'nazwa',
                    mapping: 'nazwa'
                }, {
                    name: 'active',
                    mapping: 'active'
                }, {
                    name: 'identyfikatorassigned',
                    mapping: 'identyfikatorassigned'
                }, {
                    name: 'firma',
                    mapping: 'firma'
                }, {
                    name: 'opis',
                    mapping: 'opis'
                }, {
                    name: "individualopts",
                    mapping: "individualopts"
                }, {
                    name: "eiid",
                    mapping: "eiid"
                }, {
                    name: "rokprodukcji",
                    mapping: "rokprodukcji"
                }, {
                    name: "identeuro",
                    mapping: "identeuro"
                }, {
                    name: "fueltanklimit",
                    mapping: "fueltanklimit"
                }, {
                    name: "controlfueltanklimit",
                    mapping: "controlfueltanklimit"
                }, {
                    name: "canviewtankowanie",
                    mapping: "canviewtankowanie"
                }, {
                    name: "maxmileagediff",
                    mapping: "maxmileagediff"
                }, {
                    name: "askforvolumetank",
                    mapping: "askforvolumetank"
                }, {
                    name: "cansetaddpole",
                    mapping: "cansetaddpole"
                }, {
                    name: "ismotogodzinynotkilometry",
                    mapping: "ismotogodzinynotkilometry"
                }, {
                    name: "grupa",
                    mapping: "grupa"
                }, {
                    name: "plyn",
                    mapping: "plyn"
                }, {
                    name: "zbiornikmobilny",
                    mapping: "zbiornikmobilny"
                }, {
                    name: "localization",
                    mapping: "localization"
                }]
            }),
            proxy: {
                type: 'ajax',
                url: 'rest/pojazdy/getList',
                actionMethods: {
                    read: 'POST'
                },
                reader: {
                    type: 'json',
                    rootProperty: 'data',
                    totalProperty: 'total',
                    id: 'id'
                },
                filterParam: "filters",
                listeners: {
                    scope: this,
                    exception: handlGridLoadExceptionJsonRpcL
                }
            },
            listeners: {
                scope: this,
                load: handlGridLoadStoreCheckJsonRpcL
            },
            autoLoad: false
        });
        var cols = [{
            header: "ID",
            width: 145,
            sortable: true,
            dataIndex: 'id',
            filter: {
                type: 'numeric'
            },
            filterable: true,
            hidden: true
        }, {
            header: Lang.nazwa,
            flex: 2,
            sortable: true,
            dataIndex: 'nazwa',
            filter: {
                type: 'string'
            },
            filterable: true
        }, {
            header: Lang.firma,
            flex: 2,
            sortable: true,
            dataIndex: 'firma',
            filter: {
                type: 'string'
            },
            filterable: true,
            hidden: AppFIRMACTX != null
        }, {
            header2: Lang.active,
            menuText: Lang.active,
            header: Ext.DomHelper.markup({
                tag: "div",
                cls: "iconDivCol iconActive",
                cn: [{
                    tag: "span",
                    html: "&nbsp;"
                }, {
                    tag: "div",
                    html: Lang.active
                }]
            }),
            flex: 1,
            sortable: true,
            dataIndex: 'active',
            renderer: renderBool3,
            filter: {
                type: 'boolean'
            },
            filterable: true
        }];
        if (!Const.ENTERPRISEFLAG) {
            removeObjectFromArrayById(cols, "firma");
        }
        if (Const.OPTIMALFLAG || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG) {
            removeObjectFromArrayById(cols, "cansetaddpole");
            removeObjectFromArrayById(cols, "askforvolumetank");
            removeObjectFromArrayById(cols, "individualopts");
            removeObjectFromArrayById(cols, "rokprodukcji");
            removeObjectFromArrayById(cols, "maxmileagediff");
            removeObjectFromArrayById(cols, "fueltanklimit");
            removeObjectFromArrayById(cols, "controlfueltanklimit");
        }
        if (Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG) {
            removeObjectFromArrayById(cols, "eiid");
            removeObjectFromArrayById(cols, "identeuro");
            removeObjectFromArrayById(cols, "ismotogodzinynotkilometry");
            removeObjectFromArrayById(cols, "canviewtankowanie");
            removeObjectFromArrayById(cols, "zbiornikmobilny");
        }
        if (!Const.ENABLEGPS || Const.BASICFLAG || Const.STANDARDFLAG || Const.STARTERFLAG) {
            removeObjectFromArrayById(cols, "plyn");
        }
        if (!Const.ENABLEGIRTEKA) {
            removeObjectFromArrayById(cols, "localization");
        }
        this.grid = new Ext.ux.grid.GridPanelExd({
            plugins: {
                gridfilters: true
            },
            store: store,
            columns: cols,
            forceFit: true,
            scrollable: 'y',
            frame: false,
            border: false,
            stateId: "this.grid-" + this.id + (AppFIRMACTX == null ? "-all" : "-"),
            stateful: true,
            stripeRows: true,
            enableHdMenu: true,
            selModel: {
                selType: 'rowmodel',
                mode: 'multi'
            },
            bbar: {
                xtype: 'pagetoolbarown',
                displayInfo: true,
                pagingStore: store
            }
        });
        this.grid.addListener("rowdblclick", function () {
            if (validPrivilegesOperatorComplex(ViewPojazdy.accessPropertyName + "/update"))
                this.edytujObiekt();
        }, this);
        this.grid.addListener("render", function () {
            this.manageSelect(this.grid.getSelectionModel());
        }, this);
        this.grid.store.addListener("load", function () {
            updateCzasRefreshSerwer();
        });
        this.grid.addListener("select", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
        this.grid.addListener("selectionchange", function (selectionModel, record, index) {
            this.manageSelect(selectionModel);
        }, this);
    },
    manageSelect: function () {
        var bedit = Ext.getCmp(this.id + "bedit");
        var bdelete = Ext.getCmp(this.id + "bdelete");
        if (bedit != null) {
            if (!this.grid.getSelectionModel().hasSelection() || this.grid.getSelectionModel().getSelection() == null || this.grid.getSelectionModel().getSelection().length == 0) {
                bdelete.setDisabled(true);
                bedit.setDisabled(true);
            } else {
                var row = this.grid.getSelectionModel().getSelection()[0];
                bdelete.setDisabled(!validPrivilegesOperatorComplex(ViewPojazdy.accessPropertyName + "/delete"));
                bedit.setDisabled(!validPrivilegesOperatorComplex(ViewPojazdy.accessPropertyName + "/update"));
            }
            var bnew = Ext.getCmp(this.id + "bnew");
            if (Const.ENTERPRISEFLAG) {
                bnew.setDisabled(AppFIRMACTX == null || !validPrivilegesOperatorComplex(ViewPojazdy.accessPropertyName + "/add"));
            } else {
                bnew.setDisabled(!validPrivilegesOperatorComplex(ViewPojazdy.accessPropertyName + "/add"));
            }
        }
    },
    refreshDatastore: function () {
        updateCzasRefreshSerwer();
        this.grid.store.load();
    },
    dodajObiekt: function () {
        windowObject = Ext.create('WindowPojazdy', {
            callbackF: Ext.Function.bind(this.refreshDatastore, this)
        });
        windowObject.showW(0);
    },
    edytujObiekt: function () {
        var id = 0;
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                id = this.rowContext.id;
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length == 1) {
                id = this.grid.getSelectionModel().getSelection()[0].id;
            }
        }
        windowObject = Ext.create('WindowPojazdy', {
            callbackF: Ext.Function.bind(this.refreshDatastore, this)
        });
        windowObject.showW(id);
    },
    showGrupy: function () {
        var window = Ext.create('WindowGroups', {
            type: Const.GROUP_TYPE_POJAZD,
            callbackF: Ext.Function.bind(this.refreshDatastore, this)
        });
        window.showW();
    },
    usunObiekt: function () {
        var ids = [];
        if (!this.grid.getSelectionModel().hasSelection() && this.rowContext == null) {
            Ext.MessageBox.alert(Lang.error, Lang.prosze_wybrac_obiekt);
            return;
        } else {
            if (this.rowContext != null) {
                ids = [this.rowContext.id];
            } else if (this.grid.getSelectionModel().getSelection() != null && this.grid.getSelectionModel().getSelection().length > 0) {
                Ext.each(this.grid.getSelectionModel().getSelection(), function (v) {
                    ids.push(v.id);
                });
            }
        }
        Ext.MessageBox.show({
            title: Lang.usuwanie,
            msg: Lang.czy_napewno_usunac_obiekt,
            buttons: Ext.MessageBox.YESNO,
            scope: this,
            fn: function (btn) {
                if (btn == "yes") {
                    sendAjaxDataJsonRpcL("rest/pojazdy/deleteObject", {
                        ids: ids
                    }, this, function (data) {
                        if (data.status) {
                        } else {
                            Ext.MessageBox.alert(Lang.error, data.error);
                        }
                        this.refreshDatastore();
                    });
                }
            }
        });
    }
});
