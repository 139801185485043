Ext.define('WindowAlarm', {
    extend: 'Ext.ux.window.WindowExd',
    title: Lang.alarm.capitalise(),
    width: 800,
    minHeight: 500,
    closable: false,
    collapsible: false,
    scrollable: true,
    modal: true,
    resizable: false,
    layout: 'fit',
    initComponent: function (config) {
        this.cid = Ext.id();
        this.items = [{
            xtype: 'panel',
            layout: 'fit',
            items: [{
                id: this.cid + "formpanel",
                xtype: 'form',
                frame: true,
                border: false,
                autoWidth: true,
                autoHeight: true,
                scrollable: true,
                bodyStyle: 'padding:10px 10px 10px 10px',
                defaults: {
                    anchor: "0",
                    labelAlign: WindowExdMobileDEVICEV ? 'top' : 'left'
                },
                fileUpload: true,
                items: [{
                    id: this.cid + "typ",
                    name: "typ",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.typ,
                    editable: false
                }, {
                    id: this.cid + "czas",
                    name: "czas",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.czas,
                    editable: false
                }, {
                    id: this.cid + "msg",
                    name: "msg",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.komunikat,
                    editable: false
                }, {
                    id: this.cid + "firma",
                    name: "firma",
                    xtype: 'textfieldexd',
                    fieldLabel: Lang.firma,
                    editable: false
                }, {
                    id: this.cid + "opis",
                    name: "opis",
                    xtype: 'textarea',
                    minHeight: 200,
                    fieldLabel: Lang.opis,
                    editable: false
                }]
            }]
        }];
        this.buttons = [{
            xtype: 'tbfill'
        }, {
            id: this.cid + 'cancel',
            text: Lang.zamknij.capitalise(),
            scope: this,
            handler: this.closeOp
        }];
        this.callParent(arguments);
    },
    showW: function (obiektid) {
        if (!validPrivilegesOperatorComplex(ViewAlarmy.accessPropertyViewName)) {
            Ext.MessageBox.alert(Lang.error, Lang.brak_uprawnien_do_wykonania_operacji);
            return;
        }
        this.obiektid = obiektid;
        this.obiekt = null;
        sendAjaxDataJsonRpcL("rest/alarmy/getObjectAndMarkAsReaded", {
            id: this.obiektid
        }, this, function (data) {
            if (data.status && data.data != null) {
                data = data.data;
                this.obiekt = data;
                this.show();
                this.center();
                this.setKeyMap({
                    'ESC': {
                        scope: this,
                        handler: this.closeOp
                    }
                });
                Ext.getCmp(this.cid + "opis").setValue(this.obiekt.opis);
                Ext.getCmp(this.cid + "firma").setValue(this.obiekt.firma);
                Ext.getCmp(this.cid + "msg").setValue(this.obiekt.msg);
                Ext.getCmp(this.cid + "typ").setValue(this.obiekt.typ);
                Ext.getCmp(this.cid + "czas").setValue(this.obiekt.czas);
            } else {
                Ext.MessageBox.alert(Lang.error, data.error);
            }
        });
    },
    closeOp: function () {
        this.close();
    },
    close: function () {
        this.fireEvent('closeSuccess');
        this.callParent(arguments);
    }
});
