Ext.define('PanelWizardViewColGroupChoose', {
    extend: 'Ext.panel.Panel',
    initComponent: function () {
        this.cid = Ext.id();
        var store = null;
        Ext.apply(this, {
            layout: {
                type: "vbox",
                align: "stretch"
            },
            frame: false,
            title: Lang.rplview,
            border: false,
            autoWidth: false,
            height: 457,
            autoHeight: false,
            items: [{
                xtype: 'grid',
                store: new Ext.data.Store({
                    autoLoad: false,
                    proxy: new Ext.data.MemoryProxy({}),
                    reader: new Ext.data.JsonReader({
                        id: "id",
                        rootProperty: ''
                    }, [{
                        name: 'nazwa',
                        mapping: 'nazwa'
                    }, {
                        name: 'view',
                        mapping: 'view'
                    }, {
                        name: "readonly",
                        mapping: "readonly"
                    }, {
                        name: "allowdrag",
                        mapping: "allowdrag"
                    }])
                }),
                columns: [{
                    header: Lang.nazwa,
                    flex: 3,
                    sortable: true,
                    dataIndex: 'nazwa',
                    editable: false,
                    sortable: false,
                    renderer: function (value, metaData, record, rowIndex, colIndex, store) {
                        if (!record.get("allowdrag"))
                            metaData.css += " x-rlist-view-disable-drag";
                        return value;
                    }
                }, {
                    xtype: 'checkcolumn',
                    header: Lang.widoczny,
                    dataIndex: 'view',
                    flex: 2,
                    overCls: 'x6-grid-checkcolumn-over',
                    listeners: {
                        scope: this,
                        checkchange: function () {
                            var store = Ext.getCmp(this.cid + 'firmas_to').store;
                            store.commitChanges();
                        }
                    }
                }],
                cls: "x-grid-own-edit-prop",
                clicksToEdit: 1,
                frame: true,
                border: true,
                id: this.cid + "firmas_to",
                flex: 1,
                stateId: null,
                stateful: false,
                stripeRows: true,
                scrollable: true,
                enableHdMenu: false,
                enableDragDrop: true,
                ddGroup: this.cid + "groupColSelectedView",
                ddText: '...',
                selModel: {
                    selType: 'rowmodel',
                    mode: 'single'
                },
                listeners: {
                    scope: this
                },
                viewConfig: {
                    plugins: {
                        gridviewdragdrop: {
                            ddGroup: this.cid + "groupColSelectedSort",
                            enableDrop: true,
                            enableDrag: true,
                            dragZone: {
                                onBeforeDrag: function (da, e) {
                                    var node = this.view.findTargetByEvent(e);
                                    if (node) {
                                        var rowIndexr = this.view.getRecord(node);
                                        if (!rowIndexr.get("allowdrag")) {
                                            return false;
                                        }
                                        return true;
                                    }
                                    return false;
                                }
                            },
                            dropZone: {
                                notifyOver: function (dd, e, data) {
                                    var node = this.view.findTargetByEvent(e);
                                    if (node) {
                                        var rowIndexr = this.view.getRecord(node);
                                        if (!rowIndexr.get("allowdrag")) {
                                            return false;
                                        }
                                    }
                                    return this.dropAllowed;
                                },
                                notifyDrop: function (dd, e, data) {
                                    var node = this.view.findTargetByEvent(e);
                                    if (node) {
                                        var rowIndexr = this.view.getRecord(node);
                                        if (!rowIndexr.get("allowdrag")) {
                                            return false;
                                        }
                                    }
                                    var grid = this.view.up("grid");
                                    var sm = grid.getSelectionModel();
                                    var rows = sm.getSelection();
                                    var node = this.view.findTargetByEvent(e);
                                    var cindex = this.view.indexOf(node);
                                    if (sm.hasSelection()) {
                                        for (i = 0; i < rows.length; i++) {
                                            grid.store.remove(grid.store.getById(rows[i].id));
                                            grid.store.insert(cindex, rows[i]);
                                        }
                                        sm.select(rows);
                                    }
                                }
                            }
                        }
                    }
                },
                plugins: {
                    cellediting: {
                        clicksToEdit: 1
                    }
                }
            }, {
                xtype: 'form',
                frame: false,
                border: false,
                autoWidth: false,
                autoHeight: false,
                height: 140,
                bodyStyle: 'padding:10px 10px 10px 10px',
                defaults: {
                    anchor: "0"
                },
                id: this.cid + "formpanel",
                labelWidth: 100,
                items: [{
                    anchor: "0",
                    layout: 'column',
                    frame: false,
                    border: false,
                    items: [{
                        columnWidth: 1.0,
                        layout: 'form',
                        labelWidth: 130,
                        defaults: {
                            anchor: "0"
                        },
                        frame: false,
                        border: false,
                        items: [{
                            id: this.cid + "groupCollapse",
                            xtype: 'comboexd',
                            fieldLabel: Lang.szczegolowosc,
                            valueField: 'id',
                            displayField: 'nazwa',
                            store: new Ext.data.JsonStore({
                                id: "id",
                                rootProperty: '',
                                fields: [{
                                    name: 'id',
                                    mapping: 'id'
                                }, {
                                    name: 'nazwa',
                                    mapping: 'nazwa'
                                }]
                            }),
                            triggerAction: 'all',
                            resizable: false,
                            editable: false,
                            mode: "local",
                            allowBlank: false,
                            blankText: Lang.pole_nie_moze_byc_puste
                        }, {
                            id: this.cid + "czasExpand",
                            xtype: 'comboexd',
                            fieldLabel: Lang.grupowanie_czasu,
                            valueField: 'id',
                            displayField: 'nazwa',
                            store: new Ext.data.JsonStore({
                                id: "id",
                                rootProperty: '',
                                fields: [{
                                    name: 'id',
                                    mapping: 'id'
                                }, {
                                    name: 'nazwa',
                                    mapping: 'nazwa'
                                }]
                            }),
                            triggerAction: 'all',
                            resizable: false,
                            editable: false,
                            mode: "local",
                            allowBlank: false,
                            blankText: Lang.pole_nie_moze_byc_puste
                        }]
                    }]
                }]
            }]
        });
        this.callParent(arguments);
        var grid = Ext.getCmp(this.cid + "firmas_to");
        store = grid.store;
    },
    callAfterShow: function () {
        var grid = Ext.getCmp(this.cid + "firmas_to");
        var langmap = this.datad.mainCtrl.getKoolumnNazwaLangMap();
        var kolumnyyD = [];
        kolumnyyD.push({
            id: "czas",
            nazwa: langmap["czas"],
            view: true,
            allowdrag: true
        });
        if (Const.ENTERPRISEFLAG)
            kolumnyyD.push({
                id: "firma",
                nazwa: langmap["firma"],
                view: true,
                allowdrag: true
            });
        kolumnyyD.push({
            id: "plyn",
            nazwa: langmap["plyn"],
            view: true,
            allowdrag: true
        });
        kolumnyyD.push({
            id: "zbiornik",
            nazwa: langmap["zbiornik"],
            view: true,
            allowdrag: true
        });
        kolumnyyD.push({
            id: "typ_tankowania",
            nazwa: langmap["typ_tankowania"],
            view: true,
            allowdrag: true
        });
        kolumnyyD.push({
            id: "ile",
            nazwa: langmap["ile"],
            view: true,
            allowdrag: true
        });
        kolumnyyD.push({
            id: "ilebezkompensacji",
            nazwa: langmap["ilebezkompensacji"],
            view: true,
            allowdrag: true
        });

        if (this.datad.mainCtrl.repFirma == null) {
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "odbiorca",
                    nazwa: langmap["odbiorca"],
                    view: true,
                    allowdrag: true
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "pojazdTRW",
                    nazwa: langmap["pojazdTRW"],
                    view: true,
                    allowdrag: true
                });
        } else {
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "odbiorca",
                    nazwa: langmap["odbiorca"],
                    view: true,
                    allowdrag: true
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "pojazdTRW",
                    nazwa: langmap["pojazdTRW"],
                    view: true,
                    allowdrag: true
                });
        }
        if (!Const.SKPFLAG)
            kolumnyyD.push({
                id: "licznik",
                nazwa: langmap["licznik"],
                view: true,
                allowdrag: true
            });
        if (!Const.SKPFLAG)
            kolumnyyD.push({
                id: "dystans",
                nazwa: langmap["dystans"],
                view: true,
                allowdrag: true
            });
        if (!Const.SKPFLAG)
            kolumnyyD.push({
                id: "zuzycie100",
                nazwa: langmap["zuzycie100"],
                view: true,
                allowdrag: true
            });
        if (!Const.SKPFLAG)
            kolumnyyD.push({
                id: "normazuzycie100",
                nazwa: langmap["normazuzycie100"],
                view: true,
                allowdrag: true
            });
        if (!Const.SKPFLAG)
            if (!Const.BASICFLAG && !Const.STARTERFLAG)
                if (!Const.STANDARDFLAG)
                    kolumnyyD.push({
                        id: "motogodziny",
                        nazwa: langmap["motogodziny"],
                        view: true,
                        allowdrag: true
                    });
        if (!Const.SKPFLAG)
            if (!Const.BASICFLAG && !Const.STARTERFLAG)
                if (!Const.STANDARDFLAG)
                    kolumnyyD.push({
                        id: "czaspracy",
                        nazwa: langmap["czaspracy"],
                        view: true,
                        allowdrag: true
                    });
        if (!Const.SKPFLAG)
            if (!Const.BASICFLAG && !Const.STARTERFLAG)
                if (!Const.STANDARDFLAG)
                    kolumnyyD.push({
                        id: "zuzycie1h",
                        nazwa: langmap["zuzycie1h"],
                        view: true,
                        allowdrag: true
                    });

        if (!Const.SKPFLAG)
            if (!Const.BASICFLAG && !Const.STARTERFLAG)
                if (!Const.STANDARDFLAG)
                    kolumnyyD.push({
                        id: "normazuzycie1h",
                        nazwa: langmap["normazuzycie1h"],
                        view: true,
                        allowdrag: true
                    });
        if (this.datad.mainCtrl.repFirma == null) {
            if (!Const.SKPFLAG)
                if (!Const.OPTIMALFLAG)
                    if (!Const.BASICFLAG && !Const.STARTERFLAG)
                        if (!Const.STANDARDFLAG)
                            kolumnyyD.push({
                                id: "pojazdTRWRok",
                                nazwa: langmap["pojazdTRWRok"],
                                view: true,
                                allowdrag: true
                            });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "pojazdTRWIE",
                    nazwa: langmap["pojazdTRWIE"],
                    view: true,
                    allowdrag: true
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "odbiorcaEIID",
                    nazwa: langmap["odbiorcaEIID"],
                    view: true,
                    allowdrag: true
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "pojazdTRWEIID",
                    nazwa: langmap["pojazdTRWEIID"],
                    view: true,
                    allowdrag: true
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "odbiorcaOpis",
                    nazwa: langmap["odbiorcaOpis"],
                    view: true,
                    allowdrag: true
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "pojazdTRWOpis",
                    nazwa: langmap["pojazdTRWOpis"],
                    view: true,
                    allowdrag: true
                });
        } else {
            if (!Const.SKPFLAG)
                if (!Const.OPTIMALFLAG)
                    if (!Const.BASICFLAG && !Const.STARTERFLAG)
                        if (!Const.STANDARDFLAG)
                            kolumnyyD.push({
                                id: "pojazdTRWRok",
                                nazwa: langmap["pojazdTRWRok"],
                                view: true,
                                allowdrag: true
                            });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "pojazdTRWIE",
                    nazwa: langmap["pojazdTRWIE"],
                    view: true,
                    allowdrag: true
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "odbiorcaEIID",
                    nazwa: langmap["odbiorcaEIID"],
                    view: true,
                    allowdrag: true
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "pojazdTRWEIID",
                    nazwa: langmap["pojazdTRWEIID"],
                    view: true,
                    allowdrag: true
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "odbiorcaOpis",
                    nazwa: langmap["odbiorcaOpis"],
                    view: true,
                    allowdrag: true
                });
            if (!Const.SKPFLAG)
                kolumnyyD.push({
                    id: "pojazdTRWOpis",
                    nazwa: langmap["pojazdTRWOpis"],
                    view: true,
                    allowdrag: true
                });
        }
        if (!Const.SKPFLAG)
            kolumnyyD.push({
                id: "zdopis",
                nazwa: langmap["zdopis"],
                view: true,
                allowdrag: true
            });
        if (!Const.SKPFLAG)
            kolumnyyD.push({
                id: "sterownik",
                nazwa: langmap["sterownik"],
                view: true,
                allowdrag: true
            });
        if (!Const.SKPFLAG)
            kolumnyyD.push({
                id: "rozszerzeniester",
                nazwa: langmap["rozszerzeniester"],
                view: true,
                allowdrag: true
            });
        kolumnyyD.push({
            id: "operator",
            nazwa: langmap["operator"],
            view: true,
            allowdrag: true
        });
        kolumnyyD.push({
            id: "dostawca",
            nazwa: langmap["dostawca"],
            view: true,
            allowdrag: true
        });

        kolumnyyD.push({
            id: "plynEIID",
            nazwa: langmap["plynEIID"],
            view: true,
            allowdrag: true
        });
        kolumnyyD.push({
            id: "grupa_odbiorca",
            nazwa: langmap["grupa_odbiorca"],
            view: true,
            allowdrag: true
        });
        kolumnyyD.push({
            id: "grupa_pojazd",
            nazwa: langmap["grupa_pojazd"],
            view: true,
            allowdrag: true
        });
        if (Const.COSTCOLUMNS) {
            kolumnyyD.push({
                id: "kosztcalosc",
                nazwa: langmap["kosztcalosc"],
                view: true,
                allowdrag: true
            });
            kolumnyyD.push({
                id: "kosztlitr",
                nazwa: langmap["kosztlitr"],
                view: true,
                allowdrag: true
            });
        }
        if (Const.ENABLEGPS) {
            kolumnyyD.push({
                id: "gpspojazdprzyrostbak",
                nazwa: langmap["przyrost_bak"],
                view: true,
                allowdrag: true
            });
            kolumnyyD.push({
                id: "gpspojazdroznicabak",
                nazwa: langmap["roznica_bak"],
                view: true,
                allowdrag: true
            });
        }
        kolumnyyD.push({
            id: "pomiarPrzyrostZaladunek",
            nazwa: langmap["pomiar_przyrost_zbiornik_zaladunek"],
            view: true,
            allowdrag: true
        });
        kolumnyyD.push({
            id: "rzeczywistyUbytekTankowanie",
            nazwa: langmap["pomiar_ubytek_zbiornik_tank"],
            view: true,
            allowdrag: true
        });
        kolumnyyD.push({
            id: "stanZbiornika",
            nazwa: langmap["stan_zbiornika"],
            view: true,
            allowdrag: true
        });
        kolumnyyD = generateDiffCollection(kolumnyyD, this.datad.mainCtrl.repDef.columnsInReport, "id");
        kolumnyyD = kolumnyyD.containsls;
        if (this.datad.mainCtrl.repDef.columnsInReportGroup.indexOf("czas") >= 0) {
            kolumnyyD.push({
                id: "czas2",
                nazwa: langmap["czas2"],
                view: true,
                allowdrag: true
            });
        }
        Ext.each(kolumnyyD, function (v) {
            var ii = this.datad.mainCtrl.repDef.columnsInReportView.indexOf(v.id);
            v.view = ii >= 0 || this.datad.mainCtrl.repDef.columnsInReportView.length == 0;
        }, this);
        var obssskolumnyyD = generateDiffCollection(kolumnyyD, this.datad.mainCtrl.repDef.columnsInReportGroup, "id");
        var kolumnyyD1 = obssskolumnyyD.restls;
        var kolumnyyD2 = obssskolumnyyD.containsls;
        kolumnyyD2 = generateCollectionOrder(kolumnyyD2, this.datad.mainCtrl.repDef.columnsInReportGroup, "id");
        kolumnyyD1 = generateCollectionOrder(kolumnyyD1, this.datad.mainCtrl.repDef.columnsInReportView, "id");
        Ext.each(kolumnyyD2, function (v) {
            v.allowdrag = false;
            v.readonly = true;
            v.view = true;
        });
        kolumnyyD = kolumnyyD2.concat(kolumnyyD1);
        var grid = Ext.getCmp(this.cid + "firmas_to");
        grid.store.loadData(kolumnyyD);
        var kolumnyyD22 = Ext.decode(Ext.encode(kolumnyyD2));
        kolumnyyD22.push({
            id: "all",
            nazwa: Lang.pelne_rozbicie
        });
        var groupCollapse = Ext.getCmp(this.cid + "groupCollapse");
        groupCollapse.store.loadData(kolumnyyD22);
        if (this.datad.mainCtrl.repDef.groupCollapse == undefined) {
            this.datad.mainCtrl.repDef.groupCollapse = Const.REPORT_LIST_EXPAND_ALL;
        }
        var indexx1 = this.datad.mainCtrl.repDef.groupCollapse;
        if (indexx1 == Const.REPORT_LIST_EXPAND_ALL) {
            groupCollapse.setValue2Default(kolumnyyD22[kolumnyyD22.length - 1].id);
        } else if (indexx1 < kolumnyyD22.length) {
            groupCollapse.setValue2Default(kolumnyyD22[indexx1].id);
        } else {
            groupCollapse.setValue2Default(kolumnyyD22[0].id);
        }
        var czasExpand = Ext.getCmp(this.cid + "czasExpand");
        var kolumnyyD222Czas = [];
        kolumnyyD222Czas.push({
            id: Const.REPORT_LIST_CZAS_EXPAND_DZIENNY,
            nazwa: Lang.dzien
        });
        kolumnyyD222Czas.push({
            id: Const.REPORT_LIST_CZAS_EXPAND_TYGODNIOWY,
            nazwa: Lang.tydzien
        });
        kolumnyyD222Czas.push({
            id: Const.REPORT_LIST_CZAS_EXPAND_MIESIECZNY,
            nazwa: Lang.miesiac
        });
        kolumnyyD222Czas.push({
            id: Const.REPORT_LIST_CZAS_EXPAND_ROCZNY,
            nazwa: Lang.rok
        });
        czasExpand.store.loadData(kolumnyyD222Czas);
        czasExpand.setValue2Default(this.datad.mainCtrl.repDef.czasExpand);
        czasExpand.setDisabled(!Ext.Array.contains(this.datad.mainCtrl.repDef.columnsInReportGroup, "czas"));
    },
    callAfterHide: function () {
        if (this.callAfterShowCalled) {
            var okrestyp = Ext.getCmp(this.cid + "okrestyp");
            var okresod = Ext.getCmp(this.cid + "okresod");
            var okresdo = Ext.getCmp(this.cid + "okresdo");
            this.datad.mainCtrl.repDef.typeCzas = okrestyp.getValue();
            this.datad.mainCtrl.repDef.fromDate = okresod.getValue2();
            this.datad.mainCtrl.repDef.toDate = okresdo.getValue2();
        }
        return null;
    },
    clickSelectCol: function (c, i, n, e) {
        var r = c.getRecord(n);
        var en = Ext.get(n);
        var id = r.get("id");
        var kolumnyView = Ext.getCmp(this.cid + "kolumnyView");
        var kolumnyViewC = Ext.getCmp(this.cid + "kolumnyViewC");
        var kolumnyViewC2 = Ext.getCmp(this.cid + "kolumnyViewC2");
        this.mapPanelId[this.ccurentPanel].callAfterHide();
        kolumnyViewC2.add(this.mapPanelId[this.ccurentPanel]);
        kolumnyViewC2.doLayout();
        this.ccurentPanel = id;
        kolumnyViewC.add(this.mapPanelId[this.ccurentPanel]);
        kolumnyViewC.doLayout();
        kolumnyView.select(i);
        this.mapPanelId[this.ccurentPanel].callAfterShow();
    },
    reParseReportValidate: function () {
    },
    validateShowLess: function () {
        return this.validateShow();
    },
    validateShow: function () {
        var groupCollapse = Ext.getCmp(this.cid + "groupCollapse");
        var czasExpand = Ext.getCmp(this.cid + "czasExpand");
        var grid = Ext.getCmp(this.cid + "firmas_to");
        var colsNext1V = [];
        grid.store.each(function (r) {
            if (r.get("view"))
                colsNext1V.push(r.id);
        });
        var indexx1 = Const.REPORT_LIST_EXPAND_ALL;
        var grpvv = groupCollapse.getValue2Default();
        if (grpvv != "all") {
            var rec = groupCollapse.store.getById(grpvv);
            indexx1 = groupCollapse.store.indexOf(rec);
        }
        this.datad.mainCtrl.repDef.columnsInReportView = colsNext1V;
        this.datad.mainCtrl.repDef.groupCollapse = indexx1;
        this.datad.mainCtrl.repDef.czasExpand = czasExpand.getValue2Default();
        return null;
    },
    setData: function (datad) {
        this.datad = datad;
    }
});
